<template>
    <div>
        <el-row style="overflow-y: hidden;overflow-x: scroll;">
            <el-col>
                <el-button type="primary" @click="search" >{{$t('msg28')}}</el-button>
                <el-button type="success" icon="el-icon-search" @click="getTodaySet();getLog(1);" style="margin-left: 15px;">{{$t('msg152')}}</el-button>
                <el-button type="success" icon="el-icon-search" @click="getYdaySet();getLog(1);" style="margin-left: 15px;">{{$t('msg172')}}</el-button>
                <el-button type="success" icon="el-icon-search" @click="getLWKSet();getLog(1);" style="margin-left: 15px;">{{$t('msg153')}}</el-button>
                <el-button type="success" icon="el-icon-search" @click="getTMonSet();getLog(1);" style="margin-left: 15px;">{{$t('msg173')}}</el-button>                
                <el-button type="success" icon="el-icon-search" @click="getLMonSet();getLog(1);" style="margin-left: 15px;">{{$t('msg154')}}</el-button>                
            </el-col>
            <div class="mainSearch" >
                <table cellspacing="0" cellpadding="0" border="0" class="mainTable" >
                    <tr>
                        <!-- <td class="td-head ">{{$t('msg56')}}</td> -->
                        <td class="td-head td-long-head">{{$t('msg41') /*帳號*/}}</td>
                        <td class="td-head td-long-head">{{$t('Chart.RechargeAmount') /*存款金額*/}}</td>
                        <!-- <td class="td-head td-long-head">{{$t('Chart.BidAmount') /*下單金額*/}}</td> -->
                       <td class="td-head td-long-head">{{$t('Chart.CashAmount') /*提款金額*/}}</td>
                        <!-- <td class="td-head td-long-head">{{$t('Prod.Price') /*任務獎勵*/}}</td> -->

<!--                        <td class="td-head td-long-head">{{$t('Chart.FreeBuy') /*下單金額*/}}</td>
                        <td class="td-head td-long-head">{{$t('Chart.FixedBuy') /*下單金額*/}}</td>
                        
                        <td class="td-head td-long-head">{{$t('Chart.FreeSale') /*出售金額*/}}</td>
                        <td class="td-head td-long-head">{{$t('Chart.FixedSale') /*出售金額*/}}</td>
 -->
                        <!-- <td class="td-head td-long-head">{{$t('Chart.RebateAmount') /*返點金額*/}}</td> -->
                        
<!--                        <td class="td-head td-long-head">{{$t('Chart.FreeBonus') /*自由交易利息*/}}</td>
                        <td class="td-head td-long-head">{{$t('Chart.FixedBonus') /*固定資產利息*/}}</td>
 -->                        
                        <!-- <td class="td-head td-long-head">{{$t('Chart.BrokerAmount') /*活動金額*/}}</td> -->
                        <!-- <td class="td-head td-long-head">{{$t('Chart.InviteAmount') /*邀請獎勵*/}}</td> -->
                        <!-- <td class="td-head td-long-head">{{$t('Chart.LoginBonus') /*登錄獎勵*/}}</td> -->
                        <!-- <td class="td-head td-long-head">{{$t('Chart.SignBonus') /*簽到獎勵*/}}</td> -->
                    </tr>
                    <tr v-for="(item, index) in results" :key="index" :class="{'tr-color':index%2==0}">
                        <!-- <td class="td-content">{{ item.ID }}</td> -->
                        <td class="td-content">{{ item.Username }}</td>
                        <td class="td-content">{{ item.Recharge | numFilter }}</td>
                        <!-- <td class="td-content">{{ item.Bid | numFilter }}</td> -->
                        <td class="td-content">{{ item.Cash | numFilter }}</td>
                        <!-- <td class="td-content">{{ item.Bonus | numFilter }}</td> -->
<!--                        <td class="td-content">{{ item.FreeBuy | numFilter }}</td>
                        <td class="td-content">{{ item.FixedBuy | numFilter }}</td>
                        <td class="td-content">{{ item.FreeSale | numFilter }}</td>
                        <td class="td-content">{{ item.FixedSale | numFilter }}</td> -->
                        <!-- <td class="td-content">{{ item.Rebate | numFilter }}</td> -->
                        <!-- <td class="td-content">{{ item.Bonus | numFilter }}</td> -->
<!--                        <td class="td-content">{{ item.FreeBonus | numFilter }}</td>
                        <td class="td-content">{{ item.FixedBonus | numFilter }}</td> -->
                        <!-- <td class="td-content">{{ item.Broker | numFilter }}</td> -->
                        <!-- <td class="td-content">{{ item.InviteRe | numFilter }}</td> -->
                        <!-- <td class="td-content">{{ item.LoginBonus | numFilter }}</td> -->
                        <!-- <td class="td-content">{{ item.SignBonus | numFilter }}</td> -->
                    </tr>
                </table>
                <div class="no-record" v-if="!results">
                    <img class="no-record-img" src="@/assets/images/norecord.png">
                    <div class="no-record-txt">{{$t('msg17')}}</div>
                </div>
            </div>
        </el-row>
        <el-dialog :visible.sync="filterData.Visible" :destroy-on-close="true" :close-on-click-modal="false" width="600px">
            <el-row>
                <el-col >
                    <el-form :model="filterData"  status-icon>
                    <el-col :span="24">
                        <el-form-item :label="$t('msg66')" prop="Date" class="form-item">
                            <el-date-picker
                                v-model="filterData.Date"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="~"
                                :start-placeholder="$t('msg29')"
                                :end-placeholder="$t('msg29')"
                                >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('msg41')" class="form-item">
                          <el-input v-model="filterData.Username" :placeholder="$t('msg41')" ></el-input>
                        </el-form-item>
                    </el-col>
                  </el-form>
                </el-col>
                <el-col class="dialog-footer">
                    <el-button type="success" icon="el-icon-search" @click="searched">{{$t('msg28')}}</el-button>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6" >
import moment from 'moment'
import { apiReportList } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
export default {
    mixins: [globalMixin],
    data() {
        return {
            loading:false,
            filterData:{
                Visible:false,
                Date:'',
            },
            results:[],
        }
    },
    methods: {
        search()
        {
            this.filterData.Visible=true
        },
        searched()
        {
            this.filterData.Visible=false
            this.getLog();
        },
        getLog()
        {
            let shdate = this.getSEDate(this.filterData.Date)
            apiReportList({
                FromTime: shdate.FromTime,
                ToTime: shdate.ToTime,
                Username:this.filterData.Username,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.results=response.data.data;
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
    },
    computed: {
		...mapGetters({
            config:'config',
        }),
    },
    components: {
    },
    created() {
        let FromTime = moment(new Date()).add('day',-7).format("YYYY-MM-DD")
        let ToTime = moment(new Date()).add('day',0).format("YYYY-MM-DD")
        this.filterData.Date=[FromTime,ToTime]
        this.getLog();
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

</style>