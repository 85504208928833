<template>
    <div class="Dashboard">
        <el-row >
            <el-col class="Dashboard-item">
                <el-date-picker
                    v-model="filterData.Date"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="~"
                    :start-placeholder="$t('msg29')"
                    :end-placeholder="$t('msg29')"
                    >
                </el-date-picker>
                <el-button type="success" icon="el-icon-search" @click="getLog" style="margin-left: 15px;">{{$t('msg28')}}</el-button>
                <el-button type="success" icon="el-icon-search" @click="getTodaySet();getLog();" style="margin-left: 15px;">{{$t('msg152')}}</el-button>
                <el-button type="success" icon="el-icon-search" @click="getYdaySet();getLog(1);" style="margin-left: 15px;">{{$t('msg172')}}</el-button>
                <el-button type="success" icon="el-icon-search" @click="getLWKSet();getLog();" style="margin-left: 15px;">{{$t('msg153')}}</el-button>
                <el-button type="success" icon="el-icon-search" @click="getTMonSet();getLog(1);" style="margin-left: 15px;">{{$t('msg173')}}</el-button>                
                <el-button type="success" icon="el-icon-search" @click="getLMonSet();getLog();" style="margin-left: 15px;">{{$t('msg154')}}</el-button>
            </el-col>
        </el-row>
        <el-row v-if="results.SumData" style="display: inline-flex;width: 100%;justify-content: space-between;">
<!--            <el-col :span="6" class="Dashboard-item">
                <el-card class="box-card Dashboard-total" shadow="hover">
                    <div slot="header" class="clearfix">
                        {{$t('Chart.RegUser')}}
                    </div>
                    <div>
                        {{results.SumData.RegUser}}
                    </div>
                </el-card>
            </el-col> -->
<!--            <el-col :span="8" class="Dashboard-item">
                <el-card class="box-card Dashboard-total" shadow="hover">
                    <div slot="header" class="clearfix">
                        {{$t('Chart.LoginUser')}}
                    </div>
                    <div>
                        {{results.SumData.LoginUser}}
                    </div>
                </el-card>
            </el-col> -->
<!--            <el-col :span="8" class="Dashboard-item">
                <el-card class="box-card Dashboard-total" shadow="hover">
                    <div slot="header" class="clearfix">
                        {{$t('Chart.RechargeUser')}}
                    </div>
                    <div>
                        {{results.SumData.RechargeUser}}
                    </div>
                </el-card>
            </el-col> -->
            <el-col :span="12" class="Dashboard-item">
                <el-card class="box-card Dashboard-in-total" shadow="hover">
                    <div slot="header" class="clearfix">
                        {{$t('Chart.RechargeAmount')}}
                    </div>
                    <div>
                        {{results.SumData.RechargeAmount | numFilter}}
                    </div>
                </el-card>
            </el-col>
            <el-col :span="12" class="Dashboard-item">
                <el-card class="box-card Dashboard-in-total" shadow="hover">
                    <div slot="header" class="clearfix">
                        {{$t('Chart.RechargeUser')}}
                    </div>
                    <div>
                        {{results.SumData.RechargeUser}}
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-row v-if="results.SumData">


           <el-col :span="12" class="Dashboard-item">
                <el-card class="box-card Dashboard-out-total" shadow="hover">
                    <div slot="header" class="clearfix">
                        {{$t('Chart.CashAmount')}}
                    </div>
                    <div>
                        {{results.SumData.CashAmount}}
                    </div>
                </el-card>
            </el-col>
            <el-col :span="12" class="Dashboard-item">
                <el-card class="box-card Dashboard-out-total" shadow="hover">
                    <div slot="header" class="clearfix">
                        {{$t('Chart.CashUser')}}
                    </div>
                    <div>
                        {{results.SumData.CashUser}}
                    </div>
                </el-card>
            </el-col>
<!--            <el-col :span="8" class="Dashboard-item">
                <el-card class="box-card Dashboard-out-total" shadow="hover">
                    <div slot="header" class="clearfix">
                        {{$t('Chart.CashAmount')}}
                    </div>
                    <div>
                        {{results.SumData.CashAmount | numFilter}}
                    </div>
                </el-card>
            </el-col>
            <el-col :span="8" class="Dashboard-item">
                <el-card class="box-card  Dashboard-total" shadow="hover">
                    <div slot="header" class="clearfix">
                        {{$t('msg82')}}
                    </div>
                    <div>
                        {{results.SumData.RechargeAmount - results.SumData.CashAmount | numFilter}}
                    </div>
                </el-card>
            </el-col> -->
        </el-row>
        <el-row v-if="results.SumData" >
<!--            <el-col :span="6" class="Dashboard-item">
                <el-card class="box-card Dashboard-out-total" shadow="hover">
                    <div slot="header" class="clearfix">
                        {{$t('Chart.BonusAmount')}}
                    </div>
                    <div>
                        {{results.SumData.BonusAmount | numFilter}}
                    </div>
                </el-card>
            </el-col>
            
            <el-col :span="6" class="Dashboard-item">
                <el-card class="box-card Dashboard-out-total" shadow="hover">
                    <div slot="header" class="clearfix">
                        {{$t('Chart.RebateAmount')}}
                    </div>
                    <div>
                        {{results.SumData.RebateAmount | numFilter}}
                    </div>
                </el-card>
            </el-col>
            <el-col :span="6" class="Dashboard-item">
                <el-card class="box-card Dashboard-out-total" shadow="hover">
                    <div slot="header" class="clearfix">
                        {{$t('Chart.InviteAmount')}}
                    </div>
                    <div>
                        {{results.SumData.InviteAmount | numFilter}}
                    </div>
                </el-card>
            </el-col> -->
<!--            <el-col :span="6" class="Dashboard-item">
                <el-card class="box-card Dashboard-out-total" shadow="hover">
                    <div slot="header" class="clearfix">
                        {{$t('Chart.BrokerAmount')}}
                    </div>
                    <div>
                        {{results.SumData.BrokerAmount | numFilter}}
                    </div>
                </el-card>
            </el-col>  
            <el-col :span="6" class="Dashboard-item">
                <el-card class="box-card Dashboard-out-total" shadow="hover">
                    <div slot="header" class="clearfix">
                        {{$t('Chart.LoginBonus')}}
                    </div>
                    <div>
                        {{results.SumData.LoginBonus | numFilter}}
                    </div>
                </el-card>
            </el-col>   
            <el-col :span="6" class="Dashboard-item">
                <el-card class="box-card Dashboard-out-total" shadow="hover">
                    <div slot="header" class="clearfix">
                        {{$t('Chart.SignBonus')}}
                    </div>
                    <div>
                        {{results.SumData.SignBonus | numFilter}}
                    </div>
                </el-card>
            </el-col> -->
<!--            <el-col :span="6" class="Dashboard-item">
                <el-card class="box-card Dashboard-total" shadow="hover">
                    <div slot="header" class="clearfix">
                        {{$t('msg83')}}
                    </div>
                    <div>
                        {{results.SumData.FreeBuy + results.SumData.FixedBuy - results.SumData.FreeSale - results.SumData.FixedSale - results.SumData.FreeBonus - results.SumData.FixedBonus - results.SumData.RebateAmount - results.SumData.BrokerAmount - results.SumData.InviteAmount | numFilter}}
                    </div>
                </el-card>
            </el-col> -->
        </el-row>
<!--        <el-row>
            <el-col class="Dashboard-item-text">
                {{$t('msg82')}} =  {{$t('Chart.RechargeAmount')}} - {{$t('Chart.CashAmount')}}
            </el-col>
            <el-col class="Dashboard-item-text">
                {{$t('msg83')}} =  {{$t('Chart.FreeBuy')}} + {{$t('Chart.FixedBuy')}} - {{$t('Chart.FreeSale')}} - {{$t('Chart.FixedSale')}} - {{$t('Chart.FreeBonus')}} - {{$t('Chart.FixedBonus')}} - {{$t('Chart.RebateAmount')}} - {{$t('Chart.BrokerAmount')}} - {{$t('Chart.InviteAmount')}}
            </el-col>
        </el-row> -->

        <el-row >
            <div class="mainSearch" style="padding: 30px 0px;">
               <!-- <ECharts :auto-resize="true" :options="getEchartSet('RegUser','LoginUser')" style="width: 100%;" ></ECharts> -->
                <ECharts :auto-resize="true" :options="getEchartSet('RechargeUser','CashUser')" style="width: 100%;" ></ECharts>
                <ECharts :auto-resize="true" :options="getEchartSet('RechargeAmount','CashAmount')" style="width: 100%;" ></ECharts>
                <!-- <ECharts :auto-resize="true" :options="getEchartSet('BonusAmount','RebateAmount')" style="width: 100%;" ></ECharts> -->
                <!-- <ECharts :auto-resize="true" :options="getEchartSet('SignBonus','InviteAmount')" style="width: 100%;" ></ECharts> -->
                <!-- <ECharts :auto-resize="true" :options="getEchartSet('LoginBonus','BrokerAmount')" style="width: 100%;" ></ECharts> -->
                <!-- <ECharts :auto-resize="true" :options="getEchartSet('BrokerAmount','InviteAmount')" style="width: 100%;" ></ECharts> -->
            </div>
        </el-row>
    </div>
</template>

<script type="text/ecmascript-6" >
import moment from 'moment'
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/markPoint';
import 'echarts/lib/component/markLine';
import { apiDashboard } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
export default {
    mixins: [globalMixin],
    data() {
        return {
            act:"set",
            filterData:{
                Visible:false,
                Date:'',
            },
            results:[],
            yChart:{
                "RegUser":[],
                "RechargeUser":[],
                "CashUser":[],
                "LoginUser":[],
                "BidUser":[],
                "BidAmount":[],
                "BonusAmount":[],
                "RebateAmount":[],
                "RechargeAmount":[],
                "CashAmount":[],
                "BrokerAmount":[],
                "SaleAmount":[],
                
                "FreeBuy":[],
                "FixedBuy":[],
                "FreeSale":[],
                "FixedSale":[],
                "FreeBonus":[],
                "FixedBonus":[],
                "InviteAmount":[],
                "LoginBonus":[],
                "SignBonus":[],
            },
            xChart:[],
        }
    },
    methods: {
        getLog()
        {
            let shdate = {
                "FromTime":"2020-09-01",
                "ToTime":"2020-12-01",
            }
            if(this.filterData.Date)
            {
                shdate = this.getSEDate(this.filterData.Date)
            }
            apiDashboard({
                FromTime: shdate.FromTime,
                ToTime: shdate.ToTime,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.results=response.data.data;
                        this.xChart.splice(0,this.xChart.length);
                        for(var n in this.results.ReportDay)
                        {
                            this.xChart.push(this.getMomentYDate(this.results.ReportDay[n].Date));
                            this.yChart["RegUser"].push(this.results.ReportDay[n].RegUser);
                            this.yChart["RechargeUser"].push(this.results.ReportDay[n].RechargeUser);
                            this.yChart["CashUser"].push(this.results.ReportDay[n].CashUser);
                            this.yChart["LoginUser"].push(this.results.ReportDay[n].LoginUser);
                            this.yChart["BidUser"].push(this.results.ReportDay[n].BidUser);
                            this.yChart["BidAmount"].push(this.results.ReportDay[n].BidAmount);
                            this.yChart["BonusAmount"].push(this.results.ReportDay[n].BonusAmount);
                            this.yChart["RebateAmount"].push(this.results.ReportDay[n].RebateAmount);
                            this.yChart["RechargeAmount"].push(this.results.ReportDay[n].RechargeAmount);
                            this.yChart["CashAmount"].push(this.results.ReportDay[n].CashAmount);
                            this.yChart["BrokerAmount"].push(this.results.ReportDay[n].BrokerAmount);
                            this.yChart["SaleAmount"].push(this.results.ReportDay[n].SaleAmount);
                            this.yChart["FreeBuy"].push(this.results.ReportDay[n].FreeBuy);
                            this.yChart["FixedBuy"].push(this.results.ReportDay[n].FixedBuy);
                            this.yChart["FreeSale"].push(this.results.ReportDay[n].FreeSale);
                            this.yChart["FixedSale"].push(this.results.ReportDay[n].FixedSale);
                            this.yChart["FreeBonus"].push(this.results.ReportDay[n].FreeBonus);
                            this.yChart["FreeBonus"].push(this.results.ReportDay[n].FreeBonus);
                            this.yChart["FixedBonus"].push(this.results.ReportDay[n].FixedBonus);
                            this.yChart["InviteAmount"].push(this.results.ReportDay[n].InviteAmount);                          
                            this.yChart["LoginBonus"].push(this.results.ReportDay[n].LoginBonus);                          
                            this.yChart["SignBonus"].push(this.results.ReportDay[n].SignBonus);                          
                        }
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        getRandomArrayElements(arr, count) 
        {
            var shuffled = arr.slice(0), i = arr.length, min = i - count, temp, index;
            while (i-- > min) {
                index = Math.floor((i + 1) * Math.random());
                temp = shuffled[index];
                shuffled[index] = shuffled[i];
                shuffled[i] = temp;
            }
            return shuffled.slice(min);
        },
        getEchartSet(item1,item2)
        {
            var items = ['#C33531','#64BD3D','#EE9201','#2497c8', '#B74AE5','#0AAF9F','#E89589','#16A085','#00557f','#ba8c5e ','#BA4A00','#616A6B','#4A235A','#3498DB'];
            //console.log( this.getRandomArrayElements(items, 2) );
            let color = this.getRandomArrayElements(items, 2)
            return {
                legend: {
                    data: [this.$t("Chart."+item1),this.$t("Chart."+item2)],
                },
                xAxis: {
                    type: 'category',
                    data: this.xChart,
                },
                yAxis: {
                    type: 'value',
                    max:function (value) {
                        return value.max + 2;
                    },
                },
                tooltip: {
                    trigger: 'axis',
                },
                color:color,
                series: [
                    {
                        name: this.$t("Chart."+item1),
                        data: this.yChart[item1],
                        type: 'bar',
                        markPoint: {
                            data: [
                                {type: 'max', name: '最大值'},
                                {type: 'min', name: '最小值'}
                            ]
                        },
                        markLine: {
                            data: [
                                {type: 'average', name: '平均值'}
                            ]
                        }
                    },
                    {
                        name: this.$t("Chart."+item2),
                        data: this.yChart[item2],
                        type: 'bar',
                        markPoint: {
                            data: [
                                {type: 'max', name: '最大值'},
                                {type: 'min', name: '最小值'}
                            ]
                        },
                        markLine: {
                            data: [
                                {type: 'average', name: '平均值'}
                            ]
                        },
                    },
                ]
            }
        },
        getEchartSet3(item1,item2,item3)
        {
            var items = ['#C33531','#64BD3D','#EE9201','#2497c8', '#B74AE5','#0AAF9F','#E89589','#16A085','#00557f','#ba8c5e ','#BA4A00','#616A6B','#4A235A','#3498DB'];
            //console.log( this.getRandomArrayElements(items, 2) );
            let color = this.getRandomArrayElements(items, 2)
            return {
                legend: {
                    data: [this.$t("Chart."+item1),this.$t("Chart."+item2),this.$t("Chart."+item3)],
                },
                xAxis: {
                    type: 'category',
                    data: this.xChart,
                },
                yAxis: {
                    type: 'value',
                    max:function (value) {
                        return value.max + 2;
                    },
                },
                tooltip: {
                    trigger: 'axis',
                },
                color:color,
                series: [
                    {
                        name: this.$t("Chart."+item1),
                        data: this.yChart[item1],
                        type: 'bar',
                        markPoint: {
                            data: [
                                {type: 'max', name: '最大值'},
                                {type: 'min', name: '最小值'}
                            ]
                        },
                        markLine: {
                            data: [
                                {type: 'average', name: '平均值'}
                            ]
                        }
                    },
                    {
                        name: this.$t("Chart."+item2),
                        data: this.yChart[item2],
                        type: 'bar',
                        markPoint: {
                            data: [
                                {type: 'max', name: '最大值'},
                                {type: 'min', name: '最小值'}
                            ]
                        },
                        markLine: {
                            data: [
                                {type: 'average', name: '平均值'}
                            ]
                        },
                    },
                    {
                        name: this.$t("Chart."+item3),
                        data: this.yChart[item3],
                        type: 'bar',
                        markPoint: {
                            data: [
                                {type: 'max', name: '最大值'},
                                {type: 'min', name: '最小值'}
                            ]
                        },
                        markLine: {
                            data: [
                                {type: 'average', name: '平均值'}
                            ]
                        },
                    },
                    // {
                    //     name: this.$t("Chart."+item4),
                    //     data: this.yChart[item4],
                    //     type: 'bar',
                    //     markPoint: {
                    //         data: [
                    //             {type: 'max', name: '最大值'},
                    //             {type: 'min', name: '最小值'}
                    //         ]
                    //     },
                    //     markLine: {
                    //         data: [
                    //             {type: 'average', name: '平均值'}
                    //         ]
                    //     },
                    // },
                ]
            }
        }        
    },
    computed: {
		...mapGetters({
            config:'config',
        }),
    },
    components: {
        ECharts,
    },
    created()
    {
        let FromTime = moment(new Date()).add('day',-7).format("YYYY-MM-DD")
        let ToTime = moment(new Date()).add('day',0).format("YYYY-MM-DD")
        this.filterData.Date=[FromTime,ToTime]
        this.getLog(1);
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

</style>