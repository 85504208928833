<template>
    <div>
        <el-row >
            <el-col v-if="user.GroupPermission.n1.Modify">
                <el-button type="primary" @click="add" >{{$t('msg7')}}</el-button>
            </el-col>
            <div class="mainSearch" >
                <table cellspacing="0" cellpadding="0" border="0" class="mainTable">
                    <tr>
                        <td class="td-head">{{$t('msg8')}}</td>
                        <td class="td-head">{{$t('msg9')}}</td>
                        <td class="td-head">{{$t('msg10')}}</td>
                        <td class="td-head">{{$t('msg11')}}</td>
                        <td class="td-head">{{$t('msg12')}}</td>
                        <td class="td-head">{{$t('msg13')}}</td>
                        <td class="td-head">{{$t('msg14')}}</td>
                        <td class="td-head">{{$t('msg15')}}</td>
                        
                    </tr>
                    <tr v-for="(item, index) in results" :key="index" :class="{'tr-color':index%2==0}">
                        <td class="td-content">{{ item.Username }}</td>
                        <td class="td-content">
                            <el-tag v-if="item.Enable==1">{{ getEnableStatus(item) }}</el-tag>
                            <el-tag type="danger" v-if="item.Enable==0">{{ getEnableStatus(item) }}</el-tag>
                        </td>
                        <td class="td-content">{{ getGroupStatus(item) }}</td>
                        <td class="td-content">{{ item.LoginIP }}</td>
                        <td class="td-content">{{ item.Memo }}</td>
                        <td class="td-content">{{ getMomentFullDate(item.LoginAt) }}</td>
                        <td class="td-content">{{ getMomentFullDate(item.UpdatedAt) }}</td>
                        <td class="td-content" v-if="item.GroupID == 1">
                            <el-button type="primary" @click="update(item)" v-if="user.GroupPermission.n1.Modify && item.GroupID == user.GroupID">{{$t('msg16')}}</el-button>
                        </td>
                        <td class="td-content" v-else>
                            <el-button type="primary" @click="update(item)" v-if="user.GroupPermission.n1.Modify">{{$t('msg16')}}</el-button>
                        </td>
                    </tr>
                </table>
                <div class="no-record" v-if="!results.length">
                    <img class="no-record-img" src="@/assets/images/norecord.png">
                    <div class="no-record-txt">{{$t('msg17')}}</div>
                </div>
            </div>
        </el-row>
        <el-dialog :visible.sync="dialog.Visible" :destroy-on-close="true" :close-on-click-modal="false">
            <el-row>
                <el-col >
                    <el-row>
                        <el-col>
                          <el-form :model="from"  status-icon>
                            <el-form-item :label="$t('msg8')" prop="Username">
                              <el-input v-model="from.Username" :disabled="act=='set'" ></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('msg18')" prop="Password">
                              <el-input v-model="from.Password" show-password :placeholder="$t('msg19')" ></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('msg10')" prop="GroupID" >
                                <el-select v-model="from.GroupID" :placeholder="$t('msg20')">
                                    <el-option
                                        v-for="item in config.group"
                                        :key="item.value"
                                        :label="$t('GroupType.'+item.label)"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item :label="$t('msg9')" prop="Enable" >
                                <el-radio v-model="from.Enable" :label="1">{{$t('msg1')}}</el-radio>
                                <el-radio v-model="from.Enable" :label="0">{{$t('msg2')}}</el-radio>
                            </el-form-item>
                            <el-form-item :label="$t('msg12')" prop="Memo">
                              <el-input v-model="from.Memo" ></el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col>
                            <div class="dialog-footer">
                                <el-button style="width: 80%;" type="primary" :loading="loading" @click="updated" v-if="act=='set'">{{$t('msg21')}}</el-button>
                                <el-button style="width: 80%;" type="primary" :loading="loading" @click="updated" v-if="act=='add'">{{$t('msg22')}}</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6" >
import { apiAdminList,apiAdminSet,apiAdminAdd } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
export default {
    mixins: [globalMixin],
    data() {
        return {
            act:"set",
            loading:false,
            dialog:{
                Visible:false,
            },
            from:{
                Password:"",
                GroupID:0,
                Username:"",
                Enable:0,
                Memo:"",
            },
            results:[],
        }
    },
    methods: {
        add()
        {
            this.act="add"
            this.from.GroupID=1
            this.from.Username=""
            this.from.Password=""
            this.from.Memo=""
            this.from.Enable=1
            this.dialog.Visible=true
        },
        update(item)
        {
            this.act="set"
            this.from.GroupID=item.GroupID
            this.from.Username=item.Username
            this.from.Enable=item.Enable
            this.from.Password=""
            this.from.Memo=item.Memo
            this.dialog.Visible=true
        },
        updated()
        {
            if(this.from.GroupID==1 && this.user.GroupID >1)
            {
                this.message(this.$t('msg136'));
                return
            }
            this.dialog.Visible=false
            var api = apiAdminSet
            switch(this.act)
            {
                case "set":
                    api = apiAdminSet
                break;
                case "add":
                    api = apiAdminAdd
                break;
            }
            api({
                GroupID: this.from.GroupID,
                Username: this.from.Username,
                Password: this.from.Password,
                Enable: this.from.Enable,
                Memo: this.from.Memo,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.getLog(1)
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        getLog(page)
        {
            apiAdminList({
                page: page,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.results=response.data.data;
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
    },
    computed: {
		...mapGetters({
            config:'config',
            user:'user',
        }),
    },
    components: {
    },
    created() {
        this.getLog(1);
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

</style>