<template>
    <div>
        <el-row >
            <el-col>
                <el-button type="primary" @click="updated" v-if="user.GroupPermission.n11.Modify">{{$t('msg16')}}</el-button>
            </el-col>
            <div class="mainSearch" >
                <table cellspacing="0" cellpadding="0" border="0" class="mainTable" style="width: 800px;">
                    <tr>
                        <td class="td-head">{{$t('msg38')}}</td>
                        <td class="td-head ">#</td>
                        <td class="td-head">{{$t('msg39')}}</td>
                    </tr>
                    <tr v-for="(item, index) in results" :key="index" :class="{'tr-color':index%2==0}">
                        <td class="td-content">{{ item.Title }}</td>
                        <td class="td-content">{{ item.Key }}</td>
                        <td class="td-content">
                            <el-input v-model="results[index].Value" size="small"  ></el-input>
                        </td>
                    </tr>
                </table>
                <div class="no-record" v-if="!results">
                    <img class="no-record-img" src="@/assets/images/norecord.png">
                    <div class="no-record-txt">{{$t('msg17')}}</div>
                </div>
            </div>
            <el-col>
                <el-button type="primary" @click="updated" v-if="user.GroupPermission.n11.Modify">{{$t('msg16')}}</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script type="text/ecmascript-6" >
import { apiParamList,apiParamSet } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
export default {
    mixins: [globalMixin],
    data() {
        return {
            from:{
                ID:0,
                Enable:0,
                Title:"",
                Content:"",
            },
            results:[],
        }
    },
    methods: {
        updated()
        {
            var api = apiParamSet
            let postData = {}
            for(var n in this.results)
            {
                postData[this.results[n].Key] = this.results[n].Value
            }
            api(postData)
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.message(this.$t('msg40'))
                        this.getLog()
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        getLog()
        {
            this.results = []
            apiParamList({
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        let data = {}
                        for(var n in response.data.data)
                        {
                            data = {
                                'Title' : this.$t('Param.'+n),
                                'Key' : n,
                                'Value' : response.data.data[n],
                            }
                            this.results=this.results.concat(data);
                        }
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
    },
    computed: {
		...mapGetters({
            config:'config',
            user:'user',
        }),
    },
    components: {
    },
    created() {
        this.getLog();
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

</style>