<template>
    <div>
        <el-row >
            <el-col>
                <el-button type="primary" @click="search" >{{$t('msg28')}}</el-button>
            </el-col>
<!--            <el-col>
                {{AgentLine}}
            </el-col> -->
<!--            <el-col v-show="AgentLine.length > 0" style="margin-top: 15px;">
                <span @click="resetParentID()" class="click-link">{{$t('msg106')}}</span> : 
                <span v-for="(item, index) in AgentLine" :key="index">
                    <span @click="setParentID(item.ID)" class="click-link">{{item.Username}}</span> ,
                </span>
            </el-col> -->
            <div class="mainSearch" >
                <table cellspacing="0" cellpadding="0" border="0" class="mainTable">
                    <tr>
                        <td class="td-head td-min">#</td>
                        <td class="td-head td-min">{{$t('msg9')}}</td>
                        <td class="td-head">{{$t('msg41')}}</td>
                        <td class="td-head">{{$t('msg120')}}</td>
                        <td class="td-head">{{$t('msg130')}}</td>
                        <td class="td-head">{{$t('msg131')}}</td>
                        <td class="td-head">{{$t('msg132')}}</td>
                        <td class="td-head td-date">{{$t('msg44')}}</td>
                        <td class="td-head td-date">{{$t('msg25')}}</td>
                    </tr>
                    <tr v-for="(item, index) in results" :key="index" :class="{'tr-color':index%2==0}">
                        <td class="td-content">{{ item.ID }}</td>
                        <td class="td-content">
                            <el-tag v-if="item.Enable==1">{{ getEnableStatus(item) }}</el-tag>
                            <el-tag type="danger" v-if="item.Enable==0">{{ getEnableStatus(item) }}</el-tag>
                        </td>
                        <td class="td-content">
                            <span @click="setTeamName(item.Username)" class="click-link" >{{ item.Username }}  </span>
                        </td>
                        <td class="td-content">{{ item.RealName }}</td>
                        <td class="td-content">
                            <span @click="setParentID(item.ID,1)" class="click-link" v-if="item.MemberCount > 0">{{ item.MemberCount }}</span>
                            <span  v-else>{{ item.MemberCount }}</span>
                        </td>
                        <td class="td-content">
                            <span @click="setParentID(item.ID,2)" class="click-link" v-if="item.MemberCount2 > 0">{{ item.MemberCount2 }}</span>
                            <span  v-else>{{ item.MemberCount2 }}</span>
                        </td>
                        <td class="td-content">
                            <span @click="setParentID(item.ID,3)" class="click-link" v-if="item.MemberCount3 > 0">{{ item.MemberCount3 }}</span>
                            <span  v-else>{{ item.MemberCount3 }}</span>
                        </td>
                        <td class="td-content">{{ getMomentFullDate(item.LoginAt) }}</td>
                        <td class="td-content">{{ getMomentFullDate(item.CreatedAt) }}</td>
                    </tr>
                </table>
                <div class="mainPage">
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      :total="page.total"
                      :page-size="page.pageSize"
                      :current-page="page.page"
                      @current-change="getLog"
                      >
                    </el-pagination>
                </div>
                <div class="no-record" v-if="!results">
                    <img class="no-record-img" src="@/assets/images/norecord.png">
                    <div class="no-record-txt">{{$t('msg17')}}</div>
                </div>
            </div>
        </el-row>

        <el-dialog :visible.sync="filterData.Visible" :destroy-on-close="true" :close-on-click-modal="false" width="600px">
            <el-row>
                <el-col >
                  <el-form :model="filterData"  status-icon>
                    <el-form-item :label="$t('msg44')" prop="Date">
                        <el-date-picker
                            v-model="filterData.Date"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="~"
                            :start-placeholder="$t('msg29')"
                            :end-placeholder="$t('msg29')"
                            >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item :label="$t('msg41')" prop="Content">
                      <el-input v-model="filterData.Username" :placeholder="$t('msg41')" ></el-input>
                    </el-form-item>
<!--                    <el-form-item :label="$t('msg43')" prop="Content">
                      <el-input v-model="filterData.LoginIP" :placeholder="$t('msg43')" ></el-input>
                    </el-form-item> -->
                  </el-form>
                </el-col>
                <el-col class="dialog-footer">
                    <el-button type="success" icon="el-icon-search" @click="searched">{{$t('msg28')}}</el-button>
                </el-col>
            </el-row>
        </el-dialog>
        
        <el-dialog title="" :visible.sync="dialog.TeamDetail" width="1300px">
            <el-form :model="Teamfilter"  status-icon :inline="true">
                <el-form-item :label="$t('msg25')" prop="Date">
                    <el-date-picker
                        v-model="Teamfilter.Date"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="~"
                        :start-placeholder="$t('msg29')"
                        :end-placeholder="$t('msg29')"
                        >
                    </el-date-picker>
                </el-form-item>
                <el-form-item :label="$t('msg41')" prop="Content">
                  <el-input v-model="TeamTar" :placeholder="$t('msg41')" ></el-input>
                </el-form-item>
                <el-button type="success" icon="el-icon-search" @click="TeamReport()">{{$t('msg28')}}</el-button>
            </el-form>
            <div class="mainSearch">
                <table cellspacing="0" cellpadding="0" border="0" class="mainTable" >
                    <tr>
                        <td class="td-head ">{{$t('Team.msg1') /*日期*/}}</td>
                        <td class="td-head ">{{$t('Team.msg2') /*註冊人數*/}}</td>
                        <td class="td-head ">{{$t('Team.msg3') /*首充人數*/}}</td>
                        <td class="td-head ">{{$t('Team.msg4') /*首充金額*/}}</td>
                        <td class="td-head ">{{$t('Team.msg5') /*總充值*/}}</td>                        
                        <td class="td-head ">{{$t('Team.msg6') /*總出款*/}}</td>
                        <td class="td-head ">{{$t('Team.msg8') /*總下單金額*/}}</td>
                    </tr>
                    <tr class="tr-total">
                        <td class="td-content">{{$t('Team.msg7') /*總計*/}}</td>
                        <td class="td-content">{{ TeamSum.TeamReg }}</td>
                        <td class="td-content">{{ TeamSum.TeamFrechNum }}</td>
                        <td class="td-content">{{ TeamSum.TeamFrechCoin | numFilter }}</td>
                        <td class="td-content">{{ TeamSum.TeamTrech | numFilter }}</td>
                        <td class="td-content">{{ TeamSum.TeamTcash | numFilter }}</td>
                        <td class="td-content">{{ TeamSum.TeamTbid | numFilter }}</td>
                    </tr>
                    <tr v-for="(item, index) in TeamList" :key="index" :class="{'tr-color':index%2==0}">
                        <td class="td-content">{{ getMomentYDate(item.ActionDate) }}</td>
                        <td class="td-content">{{ item.TeamReg }}</td>
                        <td class="td-content">{{ item.TeamFrechNum }}</td>
                        <td class="td-content">{{ item.TeamFrechCoin | numFilter }}</td>
                        <td class="td-content">{{ item.TeamTrech | numFilter }}</td>
                        <td class="td-content">{{ item.TeamTcash | numFilter }}</td>
                        <td class="td-content">{{ item.TeamTbid | numFilter }}</td>
                    </tr>
                </table>
            </div>
        </el-dialog>
        
        <el-dialog title="" :visible.sync="dialog.AgentDetail" width="1300px">
            <el-form :model="Agentfilter"  status-icon :inline="true">
                <el-form-item :label="$t('msg25')" prop="Date">
                    <el-date-picker
                        v-model="Agentfilter.Date"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="~"
                        :start-placeholder="$t('msg29')"
                        :end-placeholder="$t('msg29')"
                        >
                    </el-date-picker>
                </el-form-item>
                <el-button type="success" icon="el-icon-search" @click="getAgentDetail(1)">{{$t('msg28')}}</el-button>
            </el-form>
            <div class="mainSearch" style="overflow-y: hidden;overflow-x: scroll;">
                <table cellspacing="0" cellpadding="0" border="0" class="mainTable" >
                    <tr>
                        <td class="td-head " style="width: 80px;">{{$t('msg41') /*帳號*/}}</td>
                        <td class="td-head td-long-head">{{$t('Chart.RechargeAmount') /*存款金額*/}}</td>
                        <td class="td-head td-long-head">{{$t('Chart.CashAmount') /*提款金額*/}}</td>
                        <td class="td-head td-long-head">{{$t('Prod.Price') /*任務獎勵*/}}</td>

<!--                        <td class="td-head td-long-head">{{$t('Chart.FreeBuy') /*下單金額*/}}</td>
                        <td class="td-head td-long-head">{{$t('Chart.FixedBuy') /*下單金額*/}}</td>
                        
                        <td class="td-head td-long-head">{{$t('Chart.FreeSale') /*出售金額*/}}</td>
                        <td class="td-head td-long-head">{{$t('Chart.FixedSale') /*出售金額*/}}</td>
 -->
                        <td class="td-head td-long-head">{{$t('Chart.RebateAmount') /*返點金額*/}}</td>
                        
<!--                        <td class="td-head td-long-head">{{$t('Chart.FreeBonus') /*自由交易利息*/}}</td>
                        <td class="td-head td-long-head">{{$t('Chart.FixedBonus') /*固定資產利息*/}}</td>
 -->                        
                        <td class="td-head td-long-head">{{$t('Chart.BrokerAmount') /*活動金額*/}}</td>
                        <td class="td-head td-long-head">{{$t('Chart.InviteAmount') /*邀請獎勵*/}}</td>
                        <td class="td-head td-long-head">{{$t('Chart.LoginBonus') /*登錄獎勵*/}}</td>
                        <td class="td-head td-long-head">{{$t('Chart.SignBonus') /*簽到獎勵*/}}</td>
                    </tr>
                    <tr v-for="(item, index) in DetailList" :key="index" :class="{'tr-color':index%2==0}">
                        <!-- <td class="td-content">{{ item.ID }}</td> -->
                        <td class="td-content">
                            <span @click="setTeamName(item.Username)" class="click-link" >{{ item.Username }}  </span>
                        </td>
                        <td class="td-content">{{ item.Recharge | numFilter }}</td>
                        <td class="td-content">{{ item.Cash | numFilter }}</td>
                        <td class="td-content">{{ item.Bonus | numFilter }}</td>
<!--                        <td class="td-content">{{ item.FreeBuy | numFilter }}</td>
                        <td class="td-content">{{ item.FixedBuy | numFilter }}</td>
                        <td class="td-content">{{ item.FreeSale | numFilter }}</td>
                        <td class="td-content">{{ item.FixedSale | numFilter }}</td> -->
                        <td class="td-content">{{ item.Rebate | numFilter }}</td>
                        <!-- <td class="td-content">{{ item.Bonus | numFilter }}</td> -->
<!--                        <td class="td-content">{{ item.FreeBonus | numFilter }}</td>
                        <td class="td-content">{{ item.FixedBonus | numFilter }}</td> -->
                        <td class="td-content">{{ item.Broker | numFilter }}</td>
                        <td class="td-content">{{ item.InviteRe | numFilter }}</td>
                        <td class="td-content">{{ item.LoginBonus | numFilter }}</td>
                        <td class="td-content">{{ item.SignBonus | numFilter }}</td>
                    </tr>
                </table>
            </div>
        </el-dialog>        
    </div>
</template>

<script type="text/ecmascript-6" >
import { apiUserList,apiAgentReport,apiTeamReport } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
import moment from 'moment'
export default {
    mixins: [globalMixin],
    data() {
        return {
            fileList:[],
            ParentID:0,
            AgentLine:[],
            act:"set",
            loading:false,
            dialog:{
                Visible:false,
                AgentDetail:false,
                TeamDetail:false,
            },
            AgentPage:{
                page:0,
                pageSize:0,
                total:0,
                totalPage:0,
            },
            page:{
                page:0,
                pageSize:0,
                total:0,
                totalPage:0,
            },
            filterData:{
                Visible:false,
                Date:'',
            },
            Agentfilter:{
                Date:'',
            },
            Teamfilter:{
                Date:'',
            },
            rules: {
              Username: [
                { required: true, message: this.$t('msg31'), trigger: 'blur' },
              ],
              Enable: [
                { required: true, message: this.$t('msg32'), trigger: 'blur' },
              ],
              Password: [
                { required: true, message: this.$t('msg37'), trigger: 'blur' },
              ],
            },
            results:[],
            DetailList:[],
            TeamList:[],
            TeamSum:[],
            TeamTar:'',
        }
    },
    methods: {
        setTeamName(name){
            this.TeamTar = name
            this.TeamReport()
        },
        TeamReport(){
            this.dialog.TeamDetail = true
            let shdate = this.getSEDate(this.Teamfilter.Date)
            apiTeamReport({
                FromTime: shdate.FromTime,
                ToTime: shdate.ToTime,
                Username:this.TeamTar,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.TeamList=response.data.data.data;
                        this.TeamSum=response.data.data.SumData;
                        //this.page=response.data.data.page;
                        //console.log(this.DetailList)
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        search()
        {
            this.filterData.Visible=true
        },
        searched()
        {
            this.filterData.Visible=false
            this.getLog(1);
        },
        setParentID(id,line)
        {
            this.Agentfilter.ID = id
            this.Agentfilter.Line = line
            this.getAgentDetail(1);
        },
        getAgentDetail(page)
        {
            this.dialog.AgentDetail = true
            let shdate = this.getSEDate(this.Agentfilter.Date)
            apiAgentReport({
                Page: page,
                FromTime: shdate.FromTime,
                ToTime: shdate.ToTime,
                Line:this.Agentfilter.Line,
                ID:this.Agentfilter.ID,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.DetailList=response.data.data;
                        //this.page=response.data.data.page;
                        //console.log(this.DetailList)
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        getLog(page)
        {
            let shdate = this.getSEDate(this.filterData.Date)
            apiUserList({
                page: page,
                ParentID : this.ParentID==0? "":this.ParentID,
                FromTime: shdate.FromTime,
                ToTime: shdate.ToTime,
                Username:this.filterData.Username,
                LoginIP:this.filterData.LoginIP,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.results=response.data.data.data;
                        this.page=response.data.data.page;
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
    },
    computed: {
		...mapGetters({
            config:'config',
        }),
    },
    components: {
    },
    created() {
        let FromTime = moment(new Date()).add('day',-7).format("YYYY-MM-DD")
        let ToTime = moment(new Date()).add('day',0).format("YYYY-MM-DD")
        this.Agentfilter.Date=[FromTime,ToTime]
        this.Teamfilter.Date=[FromTime,ToTime]
        this.getLog(1);
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

</style>