<template>
    <div>
        <div class="main-body">
            <el-card shadow="never">
<!--                <el-row>
                    <el-col>文創交流平台</el-col>
                </el-row> -->
                <el-divider></el-divider>
                <el-row style="margin-top: 20px;width: 100%;">
                  <el-select v-model="value" placeholder="select Language" style="width: 100%;" @change="setCookie">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-row> 
                <el-row style="margin-top: 20px">
                    <el-form label-position="left" label-width="100px" :model="form" :rules="rules" ref="form" status-icon>
                        <el-form-item :label="$t('msg8') /*帳號*/" prop="username">
                            <el-input v-model="form.username" :placeholder="$t('msg54') /*帳戶名*/" prefix-icon="el-icon-s-custom"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('msg99') /*密碼*/"  prop="pwd">
                            <el-input show-password v-model="form.pwd" :placeholder="$t('msg99') /*密碼*/"  auto-complete="off" prefix-icon="el-icon-unlock"></el-input>
                        </el-form-item>
                        <el-form-item label="">
                            <el-checkbox-group v-model="form.chk" style="text-align: left;">
                              <el-checkbox :label="$t('msg100') /*記住帳號*/"  name="chk"></el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                        <el-row style="margin-top: -20px;">
                            <el-button type="primary" @click="login('form')" style="width: 100%;">{{$t('msg101') /*登錄*/}}</el-button>
                        </el-row>
                    </el-form>
                </el-row>
            </el-card>
        </div>
    </div>
</template>
<script>
import { validator } from '@/utils/validator.js'
import { apiLogin } from "@/api.js";
import { mapGetters } from "vuex";
import { globalMixin } from '@/mixin.js';
export default {
    mixins: [globalMixin],
    data() {
        return {
            options: [{
              value: 'zh-TW',
              label: '繁體中文'
            }, {
              value: 'cn',
              label: '简体中文'
            }, {
              value: 'vi-VN',
              label: 'Tiếng Việt'
            }, {
              value: 'en',
              label: 'English'
            }, ],
            value: '',
            form:{
                username:this.$cookies.get('username')?this.$cookies.get('username'):'',
                pwd:'',
                chk:this.$cookies.get('username')?true:false,
            },
            rules: {
              username: [
                { required: true, message: '請輸入帳號', trigger: 'blur' },
                { min: 2, max: 60, message: '长度在 2 到 60 个字符', trigger: 'blur' }
              ],
              pwd: [
                { required: true, message: '請輸入密碼', trigger: 'blur' },
                { min: 2,  message: '长度必須大於 2 个字符', trigger: 'blur' }
              ],
            },
        }
    },
    methods: {   
		login(formName) {
            this.$refs[formName].validate((valid) => {
              if (valid) {
                    if(!validator.isUsername(this.form.username)){
                        this.message('帐号格式不正确');
                        return;
                    }
                    if(this.form.chk)
                    {
                        this.$cookies.set('username', this.form.username, '30d');
                    }else
                    {
                        this.$cookies.remove('username');
                    }
                    apiLogin({
                        Username:this.form.username,
                        Password:this.form.pwd,
                    })
                    .then(response => {
                        if(!response.data.status)
                        {
                            this.error_handler(response.data);
                        }
                        if(response.data.status)
                        {
                            //console.log(response.data.data)
                            this.$store.dispatch('setUserInfo',response.data.data);
                            this.form.pwd='';
                            this.$parent.login_path=false;
                            this.$router.push("/");
                        }else
                        {
                            this.message(response.data.data.message);
                        }
                        return;
                    }).catch(error => {
                        console.log(error);
                        return;
                    });
                }
            });
        },
    },
    computed: {
		...mapGetters({
            user: "user",
            config: "config",
        }),
    },
    components: {

    },
    created() 
    {
        // var Ip= returnCitySN['cip']
        // console.log(Ip)
        //localStorage.setItem('Ip', Ip)

    },   
}

</script>
<style lang="stylus" rel="stylesheet/stylus" scoped>
.main-body
	padding: 25px 0px;
	max-width 400px;
	margin: auto;
</style>
