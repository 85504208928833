import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new Router({
	//mode: 'history',
	routes: [
		{
            path: '/',
            component: () => import('@/views/layout/Default.vue'),
            children: [
                {
                    path: '',
                    name: 'home',
                    component: () => import('@/views/report/Dashboard.vue'),
                },
                {
                    path: 'login',
                    name: '',
                    component: () => import('@/views/index/login.vue'),
                },
            ]
		},
		{
            path: '/admin',
            component: () => import('@/views/layout/Default.vue'),
            children: [
                {
                    path: 'AdminList',
                    name: '',
                    component: () => import('@/views/admin/List.vue'),
                },
                {
                    path: 'AdminLogList',
                    name: '',
                    component: () => import('@/views/admin/LogList.vue'),
                },
            ]
		},
        {
            path: '/activity',
            component: () => import('@/views/layout/Default.vue'),
            children: [
                {
                    path: 'BulletinList',
                    name: '',
                    component: () => import('@/views/activity/BulletinList.vue'),
                },
                {
                    path: 'MessageList',
                    name: '',
                    component: () => import('@/views/activity/MessageList.vue'),
                },
                {
                    path: 'PromotionList',
                    name: '',
                    component: () => import('@/views/activity/PromotionList.vue'),
                },
                {
                    path: 'NewsList',
                    name: '',
                    component: () => import('@/views/activity/NewsList.vue'),
                },
                {
                    path: 'VideoList',
                    name: '',
                    component: () => import('@/views/activity/VideoList.vue'),
                },
            ]
        },
        {
            path: '/system',
            component: () => import('@/views/layout/Default.vue'),
            children: [
                {
                    path: 'ParamList',
                    name: '',
                    component: () => import('@/views/system/ParamList.vue'),
                },
                {
                    path: 'ProdList',
                    name: '',
                    component: () => import('@/views/system/ProdList.vue'),
                },
                // {
                //     path: 'ProdListFix',
                //     name: '',
                //     component: () => import('@/views/system/ProdListFix.vue'),
                // },                
            ]
        },
        {
            path: '/report',
            component: () => import('@/views/layout/Default.vue'),
            children: [
                {
                    path: 'Dashboard',
                    name: '',
                    component: () => import('@/views/report/Dashboard.vue'),
                },
                {
                    path: 'ReportList',
                    name: '',
                    component: () => import('@/views/report/ReportList.vue'),
                },
                {
                    path: 'AgentReport',
                    name: '',
                    component: () => import('@/views/report/AgentList.vue'),
                },
                // {
                //     path: 'Agent',
                //     name: '',
                //     component: () => import('@/views/report/AgentList.vue'),
                // },
            ]
        },
        {
            path: '/user',
            component: () => import('@/views/layout/Default.vue'),
            children: [
                {
                    path: 'UserList',
                    name: '',
                    component: () => import('@/views/user/UserList.vue'),
                },
                {
                    path: 'UserAddCoin',
                    name: '',
                    component: () => import('@/views/user/UserAddCoin.vue'),
                },
            ]
        },
        {
            path: '/business',
            component: () => import('@/views/layout/Default.vue'),
            children: [
                {
                    path: 'Recharge/RechargeList',
                    name: '',
                    component: () => import('@/views/business/RechargeList.vue'),
                },
                {
                    path: 'Recharge/RechargeManage',
                    name: '',
                    component: () => import('@/views/business/RechargeManage.vue'),
                },
                {
                    path: 'Recharge/ThirdpayManage',
                    name: '',
                    component: () => import('@/views/business/ThirdpayManage.vue'),
                },
                {
                    path: 'Cash/CashList',
                    name: '',
                    component: () => import('@/views/business/CashList.vue'),
                },
                {
                    path: 'Bid/BidList',
                    name: '',
                    component: () => import('@/views/business/BidList.vue'),
                },
                {
                    path: 'Transfer/TransferList',
                    name: '',
                    component: () => import('@/views/business/TransferList.vue'),
                },
            ]
        },
	]
})
// router.beforeEach((to, from, next) => {
// 	var regex_user = /^\/user\//i;
// 	if (to.path.search(regex_user) === -1) {
// 		next();
// 	}else
// 	{
// 		if(router.app.$options.store.getters.user.Username=='')
// 		{
// 			return next({path: "/"});
// 		}
// 		next();
// 	}
// })
export default router;
