<template>
    <div>
        <el-row >
            <el-col>
                <el-button type="primary" @click="search" >{{$t('msg28')}}</el-button>
            </el-col>
<!--            <el-col style="margin-top: 15px;margin-bottom: 15px;">
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" style="margin-right: 15px;">全选</el-checkbox>
                <el-button type="primary" @click="batUpdate('success')" >{{$t('msg164')}}</el-button>
                <el-button type="primary" @click="batUpdate('close')" >{{$t('msg165')}}</el-button>
            </el-col> -->
            <div class="mainSearch" >
                <table cellspacing="0" cellpadding="0" border="0" class="mainTable">
                    <tr>
                        <td class="td-head "></td>
                        <td class="td-head td-min">{{$t('msg9')}}</td>
                        <td class="td-head ">{{$t('msg41')}}</td>
                        <!-- <td class="td-head " >{{$t('msg70')}}</td> -->
                        <td class="td-head " >{{$t('msg167') /*廠商*/}}</td>
                        <td class="td-head " >{{$t('msg168') /*牌桌*/}}</td>
                        <td class="td-head " >{{$t('msg169') /*局數*/}}</td>
                        <td class="td-head " >{{$t('msg171') /*次數*/}}</td>
                        <td class="td-head " >{{$t('msg170') /*預測*/}}</td>
                        <td class="td-head td-date">{{$t('msg25')}}</td>
                        <!-- <td class="td-head td-date">{{$t('msg74')}}</td> -->
                        <td class="td-head">{{$t('msg15')}}</td>
                    </tr>
                    <tr v-for="(item, index) in results" :key="index" :class="{'tr-color':index%2==0}">
                        <td class="td-content" style="font-size: .8rem;">
                        {{ item.OrderNo }}
<!--                            <el-checkbox-group v-model="checkedBides" @change="handleCheckedCitiesChange" v-if="item.Status==1">
                                <el-checkbox  :label="item.ID" > #{{item.ID}}</el-checkbox>
                            </el-checkbox-group>
                            <span v-if="item.Status!=1"> #{{item.ID}}</span> -->
                        </td>
                        <td class="td-content">
                            <el-tag v-if="item.Status==0">{{ getBidStatus(item) }}</el-tag>
                            <el-tag type="danger" v-if="item.Status==1">{{ getBidStatus(item) }}</el-tag>
                        </td>
                        <td class="td-content">{{ item.Username }}</td>
                        <!-- <td class="td-content">{{ item.Coin | numFilter }}</td> -->
                        <td class="td-content">{{ item.Vendor }}</td>
                        <td class="td-content">{{ item.Tables }}</td>
                        <td class="td-content">{{ item.Numbers }}</td>
                        <td class="td-content">{{ item.Calc }}</td>
                        <td class="td-content">{{ item.Res }}</td>
<!--                        <td class="td-content">
                            <el-tag type="danger" v-if="item.Winer==1">{{ getWinerStatus(item) }}</el-tag>
                        </td> -->
                        <td class="td-content">{{ getMomentFullDate(item.CreatedAt) }}</td>
                        <!-- <td class="td-content">{{ getMomentFullDate(item.EndTime) }}</td> -->
                        <td class="td-content">
<!--                            <el-button type="primary" @click="update(item,'success')" v-if="item.Status==1" size="mini" >{{$t('BidStatus.2')}}</el-button>
                            <el-button type="primary" @click="update(item,'close')" v-if="item.Status==1" size="mini" >{{$t('BidStatus.3')}}</el-button> -->
                        </td>
                    </tr>
                </table>
                <div class="no-record" v-if="!results">
                    <img class="no-record-img" src="@/assets/images/norecord.png">
                    <div class="no-record-txt">{{$t('msg17')}}</div>
                </div>
                <el-row>
                    <el-col :span="24">
                        <i class="fas fa-clock time-icon"></i> {{getMomentYDate(filterData.Date[0])}} ~ {{getMomentYDate(filterData.Date[1])}} | {{$t('msg90')}} : {{sumTotal | numFilter}}
                    </el-col>
                </el-row >
                <div class="mainPage">
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      :total="page.total"
                      :page-size="page.pageSize"
                      :current-page="page.page"
                      @current-change="getLog"
                      >
                    </el-pagination>
                </div>
            </div>
        </el-row>
        <el-dialog :visible.sync="filterData.Visible" :destroy-on-close="true" :close-on-click-modal="false" width="600px">
            <el-row>
                <el-col >
                    <el-form :model="filterData"  status-icon>
                    <el-col :span="24">
                        <el-form-item :label="$t('msg66')" prop="Date" class="form-item">
                            <el-date-picker
                                v-model="filterData.Date"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="~"
                                :start-placeholder="$t('msg29')"
                                :end-placeholder="$t('msg29')"
                                >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('msg56')" class="form-item">
                          <el-input v-model="filterData.OrderNo" :placeholder="$t('msg56')" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('msg41')" class="form-item">
                          <el-input v-model="filterData.Username" :placeholder="$t('msg41')" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('msg9')" class="form-item">
                            <el-col>
                                <el-select v-model="filterData.BidStatus" :placeholder="$t('msg20')">
                                    <el-option
                                        v-for="item in BidStatus"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-form-item>
                    </el-col>
                  </el-form>
                </el-col>
                <el-col class="dialog-footer">
                    <el-button type="success" icon="el-icon-search" @click="searched">{{$t('msg28')}}</el-button>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6" >
import moment from 'moment'
import { apiBidList,apiBatClose,apiBatSuccess } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
export default {
    mixins: [globalMixin],
    data() {
        return {
            act:"set",
            checkAll: false,
            checkedBides: [],
            BidOptions : [],
            isIndeterminate: true,
            loading:false,
            from:{
                ID:0,
            },
            page:{
                page:0,
                pageSize:0,
                total:0,
                totalPage:0,
            },
            filterData:{
                Visible:false,
                Date:'',
            },
            results:[],
            sumTotal:0,
        }
    },
    methods: {
        batUpdate(act){
            var api = apiBatClose
            let setPage = 1
            switch(act)
            {
                case "close":
                    api = apiBatClose
                    setPage = this.page.page
                break;
                case "success":
                    api = apiBatSuccess
                    setPage = this.page.page
                break;
            }
            api({
                ID: this.checkedBides,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.getLog(setPage)
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        handleCheckAllChange(val) {
            this.checkedBides = val ? this.BidOptions : [];
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.BidOptions.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.BidOptions.length;
        },
        search()
        {
            this.filterData.Visible=true
        },
        searched()
        {
            this.filterData.Visible=false
            this.getLog(1);
        },
        update(item,act)
        {
            this.from.ID=item.ID
            var api = apiBatClose
            let setPage = 1
            switch(act)
            {
                case "close":
                    api = apiBatClose
                    setPage = this.page.page
                break;
                case "success":
                    api = apiBatSuccess
                    setPage = this.page.page
                break;
            }
            api({
                ID: this.from.ID,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.getLog(setPage)
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        getLog(page)
        {
            let shdate = this.getSEDate(this.filterData.Date)
            apiBidList({
                Page: page,
                FromTime: shdate.FromTime,
                ToTime: shdate.ToTime,
                BidStatus:this.filterData.BidStatus,
                OrderNo:this.filterData.OrderNo,
                Username:this.filterData.Username,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    this.BidOptions.splice(0,this.BidOptions.length);
                    if(response.data.status)
                    {
                        this.results=response.data.data.data;
                        this.page=response.data.data.page;
                        this.sumTotal=response.data.data.sum;
						for(var n in this.results)
						{
                            if(this.results[n].Status==1)
                            {
                                this.BidOptions.push(this.results[n].ID)
                            }
						}
                    }
                    console.log(this.BidOptions)
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
    },
    computed: {
		...mapGetters({
            config:'config',
        }),
        'BidStatus'()
        {
            return[
                {
                  value: '',
                  label: 'ALL'
                },
                {
                  value: 0,
                  label: this.$t('BidStatus.0')
                }, {
                  value: 1,
                  label: this.$t('BidStatus.1')
                }, 
                // {
                //   value: 2,
                //   label: this.$t('BidStatus.2')
                // }, {
                //   value: 3,
                //   label: this.$t('BidStatus.3')
                // }
            ]
        },

    },
    components: {
    },
    created() {
        let FromTime = moment(new Date()).add('day',-7).format("YYYY-MM-DD")
        let ToTime = moment(new Date()).add('day',0).format("YYYY-MM-DD")
        this.filterData.Date=[FromTime,ToTime]
        this.getLog(1);
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

</style>