<template>
    <div>
        <el-row >
            <div class="mainSearch" style="text-align: center;">
                <el-card class="box-card Dashboard-in-total" shadow="hover" style="width: 500px;">
                    <div>
                        <el-form :model="from" ref="ruleForm" :rules="rules" status-icon>
                            <el-col :span="24">
                                <el-form-item :label="$t('msg41')" prop="Username" class="form-item">
                                    <el-input v-model="from.Username" :placeholder="$t('msg41')"></el-input>
                                  <!-- <el-input v-model="from.Username" type="textarea" :rows="3" :placeholder="$t('msg84')"></el-input> -->
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item :label="$t('msg85')" prop="Amount" class="form-item">
                                  <el-input v-model="from.Amount" :placeholder="$t('msg85')" ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item :label="$t('msg9')" prop="Type" class="form-item">
                                    <el-radio v-model="from.Type" :label="1">{{$t('Transfer.3')}}</el-radio>
                                    <el-radio v-model="from.Type" :label="2">{{$t('Transfer.6')}}</el-radio>
                                    <el-radio v-model="from.Type" :label="3">{{$t('Transfer.8')}}</el-radio>
                                    <el-radio v-model="from.Type" :label="19">{{$t('Transfer.19')}}</el-radio>
                                </el-form-item>
                            </el-col>
                        </el-form>
                        <el-col class="dialog-footer" :span="24" style="padding-bottom: 20px;">
                            <el-button  icon="el-icon-check" @click="updated1" v-if="user.GroupPermission.n13.Modify">{{$t('msg87')}}</el-button>
                        </el-col>
                    </div>
                </el-card>
            </div>
        </el-row>
    </div>
</template>

<script type="text/ecmascript-6" >
import { apiUserAddCoin } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
export default {
    mixins: [globalMixin],
    data() {
        return {
            from:{
                Username:"",
                Amount:0,
                Type:"",
            },
            rules: {
              Username: [
                { required: true, message: this.$t('msg41'), trigger: 'blur' },
              ],
              Amount: [
                { required: true, message: this.$t('msg85'), trigger: 'blur' },
              ],
            },
        }
    },
    methods: {
        updated1(){
            if(this.from.Type == "")
            {
                this.message(this.$t('msg159'));
                return
            }
            if(this.from.Amount <= 0)
            {
                this.message(this.$t('msg144'));
                return
            }
            if(this.from.Type == "1")
            {
                this.$confirm(this.$t('msg160'), 'Info', {
                          confirmButtonText: this.$t('msg64'),
                          cancelButtonText: this.$t('msg151'),
                          type: 'warning'
                        }).then(() => {
                            this.updated()
                        }).catch(() => {
                          return    
                        }); 
            }else{
                this.updated()
            }
        },
        updated()
        {
            apiUserAddCoin({
                Username: this.from.Username,
                Amount: this.from.Amount,
                Type: this.from.Type,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.message(this.$t('msg86'));
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
    },
    computed: {
		...mapGetters({
            config:'config',
            user:'user',
        }),
    },
    components: {
    },
    created() {
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

</style>