<template>
	<div class="main-foot">
		<div class="main-foot-body">
			<el-row class="foot-text">
				<el-col style="text-align: center;padding-top: 40px;">
<!-- 					<div class="copy-right">
						<span>© 2014 {{config.app_name}}版權所有</span>
					</div> -->
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    computed: {
		...mapGetters({
			config: "config",
        }),
    },
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

</style>
