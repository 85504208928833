import moment from 'moment'
export const globalMixin = {
    methods: {
        ChkStr(str,fnd)
        {
            return str.indexOf(fnd)
        },
		setCookie(lang)
		{
            switch(true)
            {
                case lang=="cn":
					this.$i18n.locale = lang
                    //Locale.use(lang, cn)
                    //Locale.use(lang)
                    break;
                case lang=="zh-TW":
                    //Locale.use(lang,tw)
					this.$i18n.locale = lang
                    break;
                case lang=="en":
                    //Locale.use(lang, en)
					this.$i18n.locale = lang
                    break;
                case lang=="vi-VN":
                    //Locale.use(lang, vi)
					this.$i18n.locale = lang
                    break;
     //            default:
     //                this.$i18n.locale = "en"
     //                break;                
            }
			//this.$i18n.locale = lang
			this.$cookies.set('lang',lang);
		},
        getClass(item){
            switch(item.Class)
            {
                case 1:
                    return this.$t('Class.no1');
                case 2:
                    return this.$t('Class.no2');
                case 3:
                    return this.$t('Class.no3');
                case 4:
                    return this.$t('Class.no4');
                case 0:
                    return this.$t('Class.no0');
            }
        },
        getRechargeStatus(item){
            switch(item.Status)
            {
                case 0:
                    return this.$t('msg61');
                case 1:
                    return this.$t('msg62');
                case 2:
                    return this.$t('msg63');
            }
        },
        getVerifiedStatus(item){
            switch(item.Verified)
            {
                case 1:
                    return this.$t('msg122');
                case 0:
                    return this.$t('msg123');
                case 2:
                    return this.$t('msg162');
            }
        },
        getEnableStatus(item){
            switch(item.Enable)
            {
                case 1:
                    return this.$t('msg1');
                case 0:
                    return this.$t('msg2');
            }
        },
        getGroupStatus(item){
            switch(item.GroupID)
            {
                case 1:
                    return this.$t('GroupType.n1');
                case 2:
                    return this.$t('GroupType.n2');
                case 3:
                    return this.$t('GroupType.n3');
                case 4:
                    return this.$t('GroupType.n4');
                case 5:
                    return this.$t('GroupType.n5');
                case 6:
                    return this.$t('GroupType.n6');
                case 7:
                    return this.$t('GroupType.n7');
                case 8:
                    return this.$t('GroupType.n8');
            }
        },
        getCashStatus(item){
            switch(item.Status)
            {
                case 0:
                    return this.$t('CashStatus.0');
                case 1:
                    return this.$t('CashStatus.1');
                case 2:
                    return this.$t('CashStatus.2');
            }
        },
        getSEDate(date)
        {
            let FromTime=''
            let ToTime=''
            if(date)
            {
                FromTime= moment(date[0]).format("YYYY-MM-DD")
                ToTime= moment(date[1]).format("YYYY-MM-DD")
            }
            return {FromTime:FromTime,ToTime:ToTime}
        },
        go2page(url){
			this.$router.push({path: url, query: {}});
        },
        reload_page()
        {
            this.$router.go(0);
        },
        error_func(errID){
            switch(errID)
            {
                case 1:
                    this.$store.dispatch('clearUserInfo');
                    return;
                case 3:
                    //var reload_issue=setInterval(this.reload_page(),3000);
                    //clearInterval(reload_issue);
                    //setTimeout(this.reload_page(),30000);
                    return;
            }
        },
        error_handler(e)
        {
            if(e.func==1)
            {
                this.toast(e.msg);
                this.$router.push("/login");
                return;
            }
            this.toast(e.msg);
            // if(e.func)
            // {
            //     this.error_func(e.func);
            // }
            return;
        },
        toast(msg){
                this.$message(msg);
        },
        message(msg){
                this.$message(msg);
        },
        back(){
            this.$router.back()
        },
        getMomentYDate(time){
            var dateObj = new Date(time);
            var momentObj = moment(dateObj);
            return moment(momentObj).format("YYYY-MM-DD");
        },
        getMomentDate(time){
            var dateObj = new Date(time);
            var momentObj = moment(dateObj);
            return moment(momentObj).format("MM-DD");
        },
        getMomentTime(time){
            var dateObj = new Date(time);
            var momentObj = moment(dateObj);
            return moment(momentObj).format("HH:mm");
        },
        getMomentFullDate(time)
        {
			if(time=="")
			{
				return "--";
			}
            var dateObj = new Date(time);
            var momentObj = moment(dateObj);
            return moment(momentObj).format("YYYY-MM-DD HH:mm");
        },        
        getTMonSet(){
            let FromTime = moment().month(moment().month()).startOf('month').format('YYYY-MM-DD');  
            let ToTime = moment().month(moment().month()).endOf('month').format('YYYY-MM-DD');
            this.filterData.Date=[FromTime,ToTime]
        },
        getLMonSet(){
            let FromTime = moment().month(moment().month() - 1).startOf('month').format('YYYY-MM-DD');  
            let ToTime = moment().month(moment().month() - 1).endOf('month').format('YYYY-MM-DD');
            this.filterData.Date=[FromTime,ToTime]
        },
        getLWKSet(){
            let weekOfDay = parseInt(moment().format('E'));//計算今天是這周第幾天
            let FromTime = moment().subtract(weekOfDay + 7 * 1 - 1, 'days').format('YYYY-MM-DD');//週一日期
            let ToTime = moment().subtract(weekOfDay + 7 * (1 - 1), 'days').format('YYYY-MM-DD');
            // let ToTime = moment(new Date()).add('day',0).format("YYYY-MM-DD")
            // let ToTime = moment(new Date()).add('day',0).format("YYYY-MM-DD")
            this.filterData.Date=[FromTime,ToTime]
        },
        getTodaySet(){
            let FromTime = moment(new Date()).add('day',0).format("YYYY-MM-DD")
            let ToTime = moment(new Date()).add('day',0).format("YYYY-MM-DD")
            this.filterData.Date=[FromTime,ToTime]
        },
        getYdaySet(){
            let FromTime = moment(new Date()).add('day',-1).format("YYYY-MM-DD")
            let ToTime = moment(new Date()).add('day',-1).format("YYYY-MM-DD")
            this.filterData.Date=[FromTime,ToTime]
        },
        getBidStatus(item){
            switch(item.Status)
            {
                case 0:
                    return this.$t('BidStatus.0');
                case 1:
                    return this.$t('BidStatus.1');
                case 2:
                    return this.$t('BidStatus.2');
                case 3:
                    return this.$t('BidStatus.3');
            }
        },
        getWinerStatus(item){
            switch(item.Winer)
            {
                case 1:
                    return this.$t('msg78');
            }
            return "";
        },
		gethms(time)
		{
			let msec = 0;
			let day = 0;
			let hr = 0;
			let min = 0;
			let sec = 0;
			msec = time;
			day = parseInt(msec / 60 / 60 / 24);
			hr = parseInt(msec  / 60 / 60 % 24);
			min = parseInt(msec / 60 % 60);
			sec = parseInt(msec % 60);
			return {
				day:day > 9 ? day : '0' + day,
				hr:hr > 9 ? hr : '0' + hr,
				min:min > 9 ? min : '0' + min,
				sec:sec > 9 ? sec : '0' + sec
			}
		},
    }
}