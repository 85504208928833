export default {
    msg1: '啟用',
    msg2: '禁用',
    msg3: '系統管理員',
    msg4: '客服人員',
    msg5: '財務人員',
    msg6: '其他',
    msg7: '新增',
    msg8: '帳號',
    msg9: '狀態',
    msg10: '群組',
    msg11: '登入IP',
    msg12: '備註',
    msg13: '最後登入',
    msg14: '最後修改',
    msg15: '操作',
    msg16: '修改',
    msg17: '暂无相关纪录',
    msg18: '新密碼',
    msg19: '請輸入密碼',
    msg20: '请选择狀態',
    msg21: '確認修改',
    msg22: '確認新增',
    msg23: '標題',
    msg24: '內容',
    msg25: '建立時間',
    msg26: '請輸入內容',
    msg27: '寄件人',
    msg28: '搜尋',
    msg29: '開始日期',
    msg30: '結束日期',
    msg31: '請輸入標題',
    msg32: '請選擇狀態',
    msg33: '請填寫完整資料',
    msg34: '發送名單',
    msg35: '多帳號時逗號隔開 範例:aaa,bbb,ccc',
    msg36: '圖片',
    msg37: '請上傳圖片',
    msg38: '名稱',
    msg39: '數值',
    msg40: '修改成功',
    msg41: '帳號',
    msg42: '財產餘額',
    msg43: '登錄IP',
    msg44: '登錄時間',
    msg45: '註冊IP',
    msg46: 'Phone',
    msg47: 'Line',
    msg48: 'Email',
    msg49: '新提款密碼',
    msg50: '建立會員必須輸入密碼',
    msg51: '銀行',
    msg52: '請選擇銀行',
    msg53: '銀行帳號',
    msg54: '帳戶名',
    msg55: '修改成功',
    msg56: '編號',
    msg57: '金額',
    msg58: '充值方式',
    msg59: '通道',
    msg60: '備註',
    msg61: '申请中',
    msg62: '成功到账',
    msg63: '充值失败',
    msg64: '確認',
    msg65: '拒絕',
    msg66: '選擇日期',
    msg67: '銀行資料',
    msg68: '提款成功',
    msg69: '提款失敗',
    msg70: '任務獎勵',
    msg71: '任務名稱',
    msg72: 'Winer標記',
    msg73: '用戶餘額',
    msg74: '到期時間',
    msg78: '中獎',
    msg79: '中獎標記',
    msg80: '類別',
    msg81: '登出成功',
    msg82: '出入款總計',
    msg83: '營運總計',
    msg84: '請輸入帳號 多帳號時逗號隔開 範例:aaa,bbb,ccc',
    msg85: '請輸入金額',
    msg86: '上分成功',
    msg87: '確認',
    msg88: '提款總計',
    msg89: '充值總計',
    msg90: '下單總計',
    msg91: '銀行名稱',
    msg92: '幣種',
    msg93: '幣址',
    msg94: '備註',
    msg95: 'qrcode',
    msg96: '最小充值金額',
    msg97: '最大充值金額',
    msg98: '出售中',
    msg99: '密碼',
    msg100: '記住帳號',
    msg101: '登錄',
    msg102: '管理選單',
    msg103: '登出',
    msg104: '刪除',
    msg105: '下級人數',
    msg106: '代理線',
    msg107: '名稱代號',
    msg108: '帳號',
    msg109: 'Api Key',
    msg110: 'Secret Key',
    msg111: '地區',
    msg112: '匯率',
    msg113: 'API地址',
    msg114: '備註',
    msg115: '最小金額',
    msg116: '最大金額',
    msg117: '開關',
    msg118: '查看',
    msg119: '認證',
    msg120: '邀請碼',
    msg121: '身分證號',
    msg122: '已認證',
    msg123: '未認證',
    msg124: '認證狀態',
    msg125: '重置銀行卡',
    msg126: '筆充值未處理',
    msg127: '筆提款未處理',
    msg128: '顯示時間',
    msg129: '帳目標地',
    msg130: '一級人數',
    msg131: '二級人數',
    msg132: '三級人數',
    msg133: '代理帳號(1級)',
    msg134: '代理帳號(2級)',
    msg135: '代理帳號(3級)',
    msg136: '禁止操作',
    msg137: '當前登錄密碼',
    msg138: '登錄密碼修改時間',
    msg139: '當前提款密碼',
    msg140: '提款密碼修改時間',
    msg141: '說明',
    msg142: '對象',
    msg143: '操作時間',
    msg144: '金額不能小於0',
    msg145: '上級帳號',
    msg146: '日誌類型',
    msg147: '上傳',
    msg148: '重置',
    msg149: '說明',
    msg150: '請輸入說明',
    msg151: '取消',
    msg152: '今日',
    msg153: '上週',
    msg154: '上月',
    msg155: '主錢包',
    msg156: '自由交易錢包',
    msg157: '固定交易錢包',
    msg158: '錢包類型',
    msg159: '請選擇類型',
    msg160: '確認充值?',
    msg161: '連續簽到',
    msg162: '駁回',
    msg163: '快速複製',
    msg164: '批次成功',
    msg165: '批次失敗',
    msg166: '會員等級',
    msg167: '廠商',
    msg168: '牌桌',
    msg169: '局數',
    msg170: '預測',
    msg171: '次數',
    msg172: '昨日',
    msg173: '本月',

    RechargeModel:{
        n1: "公司匯款",
        n2: "三方支付",
        n3: "管理員充值",
    },
        
    GroupType:{
        n1: '最高級系統管理員',
        n2: '系统管理',
        n3: '入款管理',
        n4: '出款管理',
        n5: '客服',
        n6: '管理员',
        n7: '推广员',
        n8: '其他',
    },
        
    MenuType:{
        n1: '信息管理',
        n2: '會員管理',
        n3: '充提管裡',
        n4: '訂單管理',
        n5: '任務管理',
        n6: '交易明細',
        n7: '報表查看',
        n8: '系統管理',
        n9: '會員上分',
    },
    
    SystemMenu:{
        n1: '管理員列表',
        n2: '會員列表',
        n3: '充值紀錄',
        n4: '提款紀錄',
        n5: '訂單列表',
        n6: '任務列表',
        n7: '公告列表',
        n8: '交易明細',
        n9: '站內信',
        n10: '優惠活動',
        n11: '系統參數',
        n12: '統計圖',
        n13: '會員上分',
        n14: '新聞管理',
        n15: '視頻管理',
        n16: '銀行卡管理',
        n17: '會員總計',
        n18: '三方金流管理',
        n19: '代理總計',
        n20: '管理員日誌',
        n21: '固定商品列表',
    },

    Transfer:{
        0:"ALL",
        1:"下單",
        //2:"利息",
        3:"充值",
        4:"提款",
        5:"提款失敗反款",
        6:"活動",
        7:"一級任務獎勵分紅",
        8:"扣款",
        //9:"出售",
        10:"下級充值回饋",
        11:"二級任務獎勵分紅",
        12:"三級任務獎勵分紅",
        13:"自由交易利息",
        14:"固定資產利息",
        15:"自由交易購買",
        16:"固定資產購買",
        17:"自由交易出售",
        18:"固定資產出售",
        19:"虛擬上分",
        20:"登錄獎勵",
        21:"資金轉移",
        22: "簽到獎勵",
        23: "利息發放",
    },
    Param:{
        cashMax: '提款最大金額',
        cashMin: '提款最小金額',
        cashfee: '提款手續費(%)',
        rechargeFee: '充值手續費(%)',
        service: '客服連結',
        ucoinRate: 'U幣提款匯率',
        ucashMin: 'U幣提款最小金額',
        ucashMax: 'U幣提款最大金額',
        APPName: '平台名稱',
    },
    Bank:{
        name: '銀行名',
        accountName: '帳戶名',
        account: '帳號',
    },
    CashStatus:{
        0: '申請中',
        1: '成功',
        2: '已取消',
    },
    BidStatus:{
        0: '未完成',
        1: '中獎',
        // 2: '成功',
        // 3: '失敗',
    },
    Prod:{
        "Name":"任務名稱",
        "Price":"任務獎勵",
        "Url":"任務網址",
        "Note":"備註",
        "Enable":"開關",        
        "Class":"任務等級",
        "Setpic":"更新圖片",
        "Device":"任務分類",
        "IOS":"蘋果",
        "Android":"安卓",
        "Api":"三方遊戲",
        "SetApi":"設定遊戲",
        "ApiUrl":"API地址",
        "Account":"帳號",
        "Key":"密鑰",
    },
    Class:{
        "no1":"新手",
        "no2":"進階",
        "no3":"白銀",
        "no4":"黃金",
        "no0":"無等級",
    },
    Chart:{
        "RegUser":"註冊人數",
        "RechargeUser":"充值人數",
        "CashUser":"提款人數",
        "LoginUser":"登錄人數",
        "BidUser":"下單人數",
        "BidAmount":"下單金額",
        "BonusAmount":"任務獎勵",
        "RebateAmount":"返點金額",
        "RechargeAmount":"充值金額",
        "CashAmount":"提款金額",
        "BrokerAmount":"活動金額",
        'SaleAmount': '出售金額',
        "InviteAmount":"下級充值回饋",
        "FreeBonus":"自由交易利息",
        "FixedBonus":"固定資產利息",
        "FreeBuy":"自由交易購買金額",
        "FixedBuy":"固定資產購買金額",
        "FreeSale":"自由交易出售金額",
        "FixedSale":"固定資產出售金額",
        "FreeBuyUser":"自由交易購買人數",
        "FixedBuyUser":"固定資產購買人數",
        "FreeSaleUser":"自由交易出售人數",
        "FixedSaleUser":"固定資產出售人數",
        "LoginBonus":"登錄獎勵",
        "SignBonus":"簽到獎勵",
    },
    LogType:{
		"admin_log_business1": "充值-確認",
		"admin_log_business2": "充值-拒絕",
		"admin_log_business3": "提款-確認",
		"admin_log_business4": "提款-拒絕",
		"admin_log_business5": "管理員-充值上分",
		"admin_log_business6": "管理員-活動上分",
		"admin_log_business7": "管理員-扣款",
		"admin_log_business8": "管理員-虛擬上分",
		"admin_log_user1":     "修改會員",
		"admin_log_user2":     "驗證會員",
		"admin_log_user3":     "修改會員銀行",
		"admin_log_user4":     "重置會員銀行",
        "admin_log_user5":     "重置會員實名驗證",
        "admin_log_user6":     "修改會員實名驗證",
        "admin_log_user7":     "上傳會員證件",
        "admin_log_user8":     "刪除會員證件",
		"admin_log_adm1":      "管理員-新增管理員",
		"admin_log_adm2":      "管理員-修改管理員",        
    },
    Team:{
		"msg1": "日期",
        "msg2": "註冊人數",
        "msg3": "首充人數",
        "msg4": "首充金額",
        "msg5": "總充值",
        "msg6": "總出款",
        "msg7": "總計",
        "msg8": "總下單金額",
    }
}