export default {
    config: {
        'app_name': '斯里蘭卡礦場',
        'domain_name': 'demo.icrown.app',
		'ApiUrl': '/api',
        'ImgUrl': '/uploads/',
        'menu_type':{ 
            //分類 1:報表查看 , 2:會員管理  , 3:充提管裡 , 4: 訂單管理 , 5: 商品管理 ,  7: 資金紀錄  , 10信息管理 , 11系統管理
            "11"   : 'n8',
            "10"   : 'n1',
			"2"   : 'n2',
			"3"   : 'n3',
            "4"   : 'n4',
            "5"   : 'n5',
            "7"   : 'n6',
            "1"   : 'n7',
            "13"   : 'n9',
        },
        'group':[
            {
              value: 1,
              label: 'n1'
            }, {
              value: 2,
              label: 'n2'
            }, {
              value: 3,
              label: 'n3'
            }, {
              value: 4,
              label: 'n4'
            },{
              value: 5,
              label: 'n5'
            },{
              value: 6,
              label: 'n6'
            },{
              value: 7,
              label: 'n7'
            },{
              value: 8,
              label: 'n8'
            },
        ],
        'LogType':{
            "admin_log_business1": "admin_log_business1",
            "admin_log_business2": "admin_log_business2",
            "admin_log_business3": "admin_log_business3",
            "admin_log_business4": "admin_log_business4",
            "admin_log_business5": "admin_log_business5",
            "admin_log_business6": "admin_log_business6",
            "admin_log_business7": "admin_log_business7",
            "admin_log_business8": "admin_log_business8",
            "admin_log_user1":     "admin_log_user1",
            "admin_log_user2":     "admin_log_user2",
            "admin_log_user3":     "admin_log_user3",
            "admin_log_user4":     "admin_log_user4",
            "admin_log_adm1":      "admin_log_adm1",
            "admin_log_adm2":      "admin_log_adm2",   
        },
        'wallet_type':{ 
            0   : 'msg155',
            1   : 'msg156',
            2   : 'msg157',
        },

    }
}
