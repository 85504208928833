
const validator = {
    username: /[0-9A-Za-z]{2,60}$/,
    password: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{4,12}$/,
    phone: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/, // 大陆
    //phone: /^ 09[0-9]{8}$/,  // 台湾
    chinese: /^[\u4E00-\u9FA5]+$/,
    //email: /([\w\-]+\@[\w\-]+\.[\w\-]+)/,
    number: /^[0-9]+$/,
    pos_int: /^\d+$/,
    reg_float: /^-?\d*(\.\d+)?$/,	// 小数
    reg_float_1_digit: /^[0-9]{1,}(\.)?\d{0,1}$/,	// 小数点一位
    reg_float_2_digit: /^[0-9]{1,}(\.)?\d{0,2}$/,	// 小数点两位
    num_float: function (num) {
        return this.reg_float.test(num);
    },
    num_float_1_digit: function (num) {
        return this.reg_float_1_digit.test(num);
    },
    num_float_2_digit: function (num) {
        return this.reg_float_2_digit.test(num);
    },
    num_pos_int: function (num) {
        return this.pos_int.test(num);
    },
    isUsername: function (str) {
        return this.username.test(str);
    },
    isPassword: function (str) {
        return this.password.test(str);
    },
    isPhone: function (str) {
        return this.phone.test(str);
    },
    isEmail: function (str) {
        return this.email.test(str);
    },
    isChinese: function (str) {
        return this.chinese.test(str);
    },
    isNumber: function (str) {
        return this.number.test(str);
    },
};

export { validator}

