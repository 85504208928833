export default {
    AddCartData(state,Data)
    {
        this.state.CartData = this.state.CartData.concat(Data);
    },
    setUserInfo(state,user){
        this.state.user = user;
    },
	// RestChooseBet()
	// {
 //        this.state.betData.splice(0,this.state.betData.length);
 //        this.state.betFlag.splice(0,this.state.betFlag.length);
	// }
}
