import axios from 'axios';
import Qs from 'qs';
import GlobalApp from './store/GlobalApp.js';

const apiRequest = axios.create({
	baseURL:  GlobalApp.config.ApiUrl,
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
	},
	transformRequest: [function(data) {      //在請求之前對data傳參進行格式轉換
		data = Qs.stringify(data)
		return data
	}],
});

export { apiRequest }

//export const apiGetLoginData = () => apiRequest.get('/static/login.json');
//export const apiGetUserData = () => apiRequest.get('/static/user.json');
//export const apiGetCurrentIssue = data => apiRequest.post('/get_current_issue',data);

export const apiLogin = data => apiRequest.post('/admin/Login',data); //登入
export const apiLogout = data => apiRequest.post('/admin/Logout',data); //登出
export const apiCheckLogin = data => apiRequest.post('/admin/CheckLogin',data); //檢查用戶狀態
export const apiAdminList = data => apiRequest.post('/admin/AdminList',data); //管理員列表
export const apiAdminSet = data => apiRequest.post('/admin/AdminSet',data); //管理員修改
export const apiAdminAdd = data => apiRequest.post('/admin/AdminAdd',data); //管理員修改
export const apiAdminLogList = data => apiRequest.post('/admin/AdminLogList',data); //管理員日誌

export const apiBulletinList = data => apiRequest.post('/activity/BulletinList',data); //公告列表
export const apiBulletinSet = data => apiRequest.post('/activity/BulletinSet',data); //公告修改
export const apiBulletinAdd = data => apiRequest.post('/activity/BulletinAdd',data); //公告新增
export const apiBulletinDel = data => apiRequest.post('/activity/BulletinDel',data); //公告刪除

export const apiMessageList = data => apiRequest.post('/activity/MessageList',data); //站內信列表
export const apiMessageSet = data => apiRequest.post('/activity/MessageSet',data); //站內信修改
export const apiMessageAdd = data => apiRequest.post('/activity/MessageAdd',data); //站內信新增
export const apiMessageInfo = data => apiRequest.post('/activity/MessageInfo',data); //站內信取得名單
export const apiMessageDel = data => apiRequest.post('/activity/MessageDel',data); //刪除站內信
export const apiPromotionList = data => apiRequest.post('/activity/PromotionList',data); //優惠活動列表
export const apiPromotionSet = data => apiRequest.post('/activity/PromotionSet',data); //優惠活動修改
export const apiPromotionAdd = data => apiRequest.post('/activity/PromotionAdd',data); //優惠活動新增
export const apiPromotionDel = data => apiRequest.post('/activity/PromotionDel',data); //優惠活動刪除

export const apiNewsList = data => apiRequest.post('/activity/NewsList',data); //新聞列表
export const apiNewsSet = data => apiRequest.post('/activity/NewsSet',data); //新聞修改
export const apiNewsAdd = data => apiRequest.post('/activity/NewsAdd',data); //新聞新增
export const apiVideoList = data => apiRequest.post('/activity/VideoList',data); //視頻列表
export const apiVideoSet = data => apiRequest.post('/activity/VideoSet',data); //視頻修改
export const apiVideoAdd = data => apiRequest.post('/activity/VideoAdd',data); //視頻新增
export const apiUpload = data => apiRequest.post('/comm/Upload',data); //檔案上傳
export const apiParamList = data => apiRequest.post('/system/ParamList',data); //參數列表
export const apiParamSet = data => apiRequest.post('/system/ParamSet',data); //參數修改

export const apiUserList = data => apiRequest.post('/user/UserList',data); //會員列表
export const apiUserSet = data => apiRequest.post('/user/UserSet',data); //會員修改
export const apiUserAdd = data => apiRequest.post('/user/UserAdd',data); //會員新增
export const apiUserBank = data => apiRequest.post('/user/UserBank',data); //會員銀行修改
export const apiUserAddCoin = data => apiRequest.post('/user/UserAddCoin',data); //會員上分
export const apiUserBankSet = data => apiRequest.post('/user/UserBankSet',data); //會員銀行修改
export const apiUserBankDel = data => apiRequest.post('/user/UserBankDel',data); //會員銀行刪除
export const apiVerifySet = data => apiRequest.post('/user/VerifySet',data); //修改會員驗證狀態
export const apiVerifyRest = data => apiRequest.post('/user/VerifyRest',data); //重置會員驗證狀態
export const apiVerifyDpic = data => apiRequest.post('/user/VerifyDpic',data); //刪除會員上傳的照片


export const apiAgentLine = data => apiRequest.post('/user/AgentLine',data); //查代理線

export const apiRechargeList = data => apiRequest.post('/business/Recharge/RechargeList',data); //充值紀錄
export const apiRechargeSet = data => apiRequest.post('/business/Recharge/RechargeSet',data); //充值紀錄修改
export const apiRechPicSet = data => apiRequest.post('/business/Recharge/RechPicSet',data); //上傳充值圖片
export const apiPaymentChk = data => apiRequest.post('/business/PaymentChk/PaymentChk',data); //檢查未處理充提
export const apiRechAmountSet = data => apiRequest.post('/business/Recharge/RechAmountSet',data); //修改充值金額

export const apiParamsBankList = data => apiRequest.post('/business/Recharge/ParamsBankList',data); //公司匯款列表
export const apiParamsBankSet = data => apiRequest.post('/business/Recharge/ParamsBankSet',data); //公司匯款修改
export const apiParamsBankAdd = data => apiRequest.post('/business/Recharge/ParamsBankAdd',data); //公司匯款新增
export const apiParamsBankDel = data => apiRequest.post('/business/Recharge/ParamsBankDel',data); //公司匯款刪除


export const apiParamsThirdList = data => apiRequest.post('/business/Recharge/ParamsThirdList',data); //三方列表
export const apiParamsThirdSet = data => apiRequest.post('/business/Recharge/ParamsThirdSet',data); //三方修改
export const apiParamsThirdAdd = data => apiRequest.post('/business/Recharge/ParamsThirdAdd',data); //三方新增

export const apiCashAmountSet = data => apiRequest.post('/business/Cash/CashAmountSet',data); //修改提款金額
export const apiCashList = data => apiRequest.post('/business/Cash/CashList',data); //提款紀錄
export const apiCashSet = data => apiRequest.post('/business/Cash/CashSet',data); //提款紀錄修改
export const apiBidList = data => apiRequest.post('/business/Bid/BidList',data); //訂單紀錄
//export const apiBidClose = data => apiRequest.post('/business/Bid/BidClose',data); //關閉任務
export const apiBatSuccess = data => apiRequest.post('/business/Bid/BatSuccess',data); //關閉任務
export const apiBatClose = data => apiRequest.post('/business/Bid/BatClose',data); //關閉任務

//export const apiBidSet = data => apiRequest.post('/business/Bid/BidSet',data); //訂單狀態修改

export const apiTransferList = data => apiRequest.post('/business/Transfer/TransferList',data); //資金紀錄
export const apiProdList = data => apiRequest.post('/system/ProdListAz199',data); //商品列表
export const apiProdSet = data => apiRequest.post('/system/ProdSetAz199',data); //自由商品修改
//export const apiProdSetFix = data => apiRequest.post('/system/ProdSetFixAz199',data); //固定商品修改
export const apiProdSetEnable = data => apiRequest.post('/system/ProdSetEnableAz199',data); //商品修改(開關)
//export const apiProdSetStype = data => apiRequest.post('/system/ProdSetStypeAz199',data); //商品修改(交易類型)
export const apiProdAdd = data => apiRequest.post('/system/ProdAddAz199',data); //商品修改(交易類型)
export const apiDashboard = data => apiRequest.post('/report/Dashboard',data); //統計圖
export const apiReportList = data => apiRequest.post('/report/ReportList',data); //會員總計
export const apiAgentReport = data => apiRequest.post('/report/AgentReport',data); //代理總計
export const apiTeamReport = data => apiRequest.post('/report/TeamReport',data); //團隊總計


/*
export const apiCheckLogin = data => apiRequest.post('/user/CheckLogin',data); //檢查用戶狀態
export const apiCheckLetter = data => apiRequest.post('/user/CheckLetter',data); //檢查站內信
export const apiBidLog = data => apiRequest.post('/user/BidLog',data); //預約紀錄
export const apiTransLog = data => apiRequest.post('/user/TransLog',data); //資金紀錄
export const apiProdLog = data => apiRequest.post('/user/ProdLog',data); //預約紀錄
export const apiSafe = data => apiRequest.post('/user/Safe',data); // 获取安全状态
export const apiSafeUp = data => apiRequest.post('/user/SafeUp',data); // 更新安全状态
export const apiMessage = data => apiRequest.post('/user/Message',data); // 獲取站內信
export const apiMessageRead = data => apiRequest.post('/user/MessageRead',data); // 把站內信改成已讀

export const apiReg = data => apiRequest.post('/index/Reg',data); //註冊
export const apiLogin = data => apiRequest.post('/index/Login',data); //登入
export const apiLogout = data => apiRequest.post('/index/Logout',data); //登出
export const apiBank = data => apiRequest.post('/index/Bank',data); //銀行列表
export const apiPromotion = data => apiRequest.post('/index/Promotion',data); //優惠活動
export const apiBulletin = data => apiRequest.post('/index/Bulletin',data); // 獲取公告

export const apiRechargeInfo = data => apiRequest.post('/user/RechargeInfo',data); // 获取充值消息
export const apiRechargeBank = data => apiRequest.post('/user/RechargeBank',data); // 提交充值消息
export const apiRechargeThird = data => apiRequest.post('/user/RechargeThird',data); // 提交充值消息
export const apiCash = data => apiRequest.post('/user/Cash',data); // 提交提款消息
export const apiRechargeLog = data => apiRequest.post('/user/RechargeLog',data); // 獲取充值日誌
export const apiCashLog = data => apiRequest.post('/user/CashLog',data); // 獲取提款日誌

export const apiTeam = data => apiRequest.post('/agent/Team',data); // 獲取代理團隊
export const apiInvite = data => apiRequest.post('/agent/Invite',data); // 獲取邀請碼
export const apiInCome = data => apiRequest.post('/agent/InCome',data); // 獲取收入列表
export const apiMemberDetial = data => apiRequest.post('/agent/MemberDetial',data); // 獲取用戶信息
export const apiAgentQR = data => apiRequest.post('/agent/AgentQR',data); // 獲取代理邀請碼
export const apiAgentRebate = data => apiRequest.post('/agent/AgentRebate',data); // 獲取代理邀請碼

export const apiProd = data => apiRequest.post('/game/Prod',data); //產品列表
export const apiBid = data => apiRequest.post('/game/Bid',data); //下標
*/
//export const apiGetCfg = data => apiRequest.post('/get_cfg2',data);
//export const apiBet2 = data => apiRequest.post('/bet2',data); // 信用玩法
//export const apiGetCoin = data => apiRequest.post('/get_coin',data); // 獲取餘額
//export const apiGetBetLog = data => apiRequest.post('/bet_log',data); // 獲取注單

//export const apiGetCfg = data => apiRequest.post('/index/get_cfg',data);