<template>
    <div>
        <el-row style="overflow-y: hidden;overflow-x: scroll;">
            <el-col>
                <el-button type="primary" @click="search" >{{$t('msg28')}}</el-button>
                <el-button type="success" icon="el-icon-search" @click="getTodaySet();getLog(1);" style="margin-left: 15px;">{{$t('msg152')}}</el-button>
                <el-button type="success" icon="el-icon-search" @click="getYdaySet();getLog(1);" style="margin-left: 15px;">{{$t('msg172')}}</el-button>
                <el-button type="success" icon="el-icon-search" @click="getLWKSet();getLog(1);" style="margin-left: 15px;">{{$t('msg153')}}</el-button>
                <el-button type="success" icon="el-icon-search" @click="getTMonSet();getLog(1);" style="margin-left: 15px;">{{$t('msg173')}}</el-button>                
                <el-button type="success" icon="el-icon-search" @click="getLMonSet();getLog(1);" style="margin-left: 15px;">{{$t('msg154')}}</el-button>                
            </el-col>
            <div class="mainSearch" >
                <table cellspacing="0" cellpadding="0" border="0" class="mainTable">
                    <tr>
                        <td class="td-head ">#</td>
                        <td class="td-head ">{{$t('msg9')}}</td>
                        <td class="td-head ">{{$t('msg41')}}</td>
                        <!-- <td class="td-head " style="width: 80px;">{{$t('msg112')}}</td> -->
                        <td class="td-head " style="width: 120px;">{{$t('msg57')}}</td>
                        <td class="td-head " style="width: 120px;">應入款金額({{rechargeFee}}%)</td>
                        <!-- <td class="td-head " style="width: 150px;"> NTD </td> -->
                        <!-- <td class="td-head " style="width: 150px;">{{$t('msg58')}}</td> -->
                        <td class="td-head " >{{$t('msg92')}}</td>
                        <td class="td-head " style="width: 200px;">{{$t('msg93')}}</td>
                        <td class="td-head " >{{$t('msg149')}}</td>
                        <!-- <td class="td-head " style="width: 50px;">{{$t('msg36')}}</td> -->
                        <td class="td-head td-date">{{$t('msg25')}}</td>
                        <td class="td-head td-date">{{$t('msg14')}}</td>
                        <td class="td-head" style="width: 150px;">{{$t('msg15')}}</td>
                    </tr>
                    <tr v-for="(item, index) in results" :key="index" :class="{'tr-color':index%2==0}">
                        <td class="td-content">
                        {{ item.RechargeID }}                     
                        </td>
                        <td class="td-content">
                            <el-tag v-if="item.Status==0">{{ getRechargeStatus(item) }}</el-tag>
                            <el-tag type="warning" v-if="item.Status==1">{{ getRechargeStatus(item) }}</el-tag>
                            <el-tag type="info" v-if="item.Status==2">{{ getRechargeStatus(item) }}</el-tag>                        
                        </td>
<!--                        <td class="td-content">
                            <el-tag v-if="item.Status==0">{{ getRechargeStatus(item) }}</el-tag>
                            <el-tag type="warning" v-if="item.Status==1">{{ getRechargeStatus(item) }}</el-tag>
                            <el-tag type="info" v-if="item.Status==2">{{ getRechargeStatus(item) }}</el-tag>
                        </td> -->
                        <td class="td-content">{{ item.Username }}</td>
                        <!-- <td class="td-content">1 USDT = {{ucoinRate}} NTD</td> -->
                        <td class="td-content" style="display: flex;justify-content: space-between;">
                            <div style="line-height: 32px;">{{ item.Amount | numFilter }} </div>
                            <div><el-button  type="primary" size="mini"  @click="UpAmountLay(item,index)" >修改</el-button></div>
                        </td>
                        <td class="td-content" style="text-align: right;">{{ item.Amount - (item.Amount*rechargeFee/100)  | numFilter }}</td>
                        <!-- <td class="td-content">{{ item.RechargeModelName }}</td> -->
                        <td class="td-content">{{ item.RechargeComName }}</td>
                        <td class="td-content" style="word-wrap:break-word">{{ item.Memo }}</td>
                        <td class="td-content" style="word-wrap:break-word">{{ item.Info }}</td>
<!--                        <td class="td-content">
                            <span v-if="item.Pic"><a :href="config.ImgUrl+'recharge/'+item.Pic" target="_blank">{{$t('msg118')}}</a></span>
                            <span v-if="!item.Pic && ChkStr(item.RechargeModelName,'Three-party payment') >= 0" @click="setpic(item)" class="link-click">{{$t('msg147')}}</span>
                        </td> -->
                        <td class="td-content">{{ getMomentFullDate(item.CreatedAt) }}</td>
                        <td class="td-content">{{ getMomentFullDate(item.UpdatedAt) }}</td>
                        <td class="td-content">
                            <!-- <el-button type="primary" @click="update(item)" v-if="item.Status==0 && user.GroupPermission.n3.Modify" size="mini">{{$t('msg16')}}</el-button> -->
                                <el-col :span="12">
                                    <el-button style="width: 80%;" type="primary" size="mini" :loading="loading" @click="update(item,1)" v-if="item.Status==0 && user.GroupPermission.n3.Modify">{{$t('msg64')}}</el-button>
                                </el-col>
                                <el-col :span="12">
                                    <el-button style="width: 80%;" type="primary" size="mini" :loading="loading" @click="update(item,2)" v-if="item.Status==0 && user.GroupPermission.n3.Modify">{{$t('msg65')}}</el-button>
                                </el-col>                            
                        </td>
                    </tr>
                </table>
                <div class="no-record" v-if="!results">
                    <img class="no-record-img" src="@/assets/images/norecord.png">
                    <div class="no-record-txt">{{$t('msg17')}}</div>
                </div>
                <el-row>
                    <el-col :span="24">
                        <i class="fas fa-clock time-icon"></i> {{getMomentYDate(filterData.Date[0])}} ~ {{getMomentYDate(filterData.Date[1])}} | {{$t('msg89')}} : {{sumTotal | numFilter}}
                    </el-col>
                </el-row >
                <div class="mainPage">
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      :total="page.total"
                      :page-size="page.pageSize"
                      :current-page="page.page"
                      @current-change="getLog"
                      >
                    </el-pagination>
                </div>
            </div>
        </el-row>
        <el-dialog :visible.sync="filterData.Visible" :destroy-on-close="true" :close-on-click-modal="false" width="600px">
            <el-row>
                <el-col >
                    <el-form :model="filterData"  status-icon>
                    <el-col :span="24">
                        <el-form-item :label="$t('msg66')" prop="Date" class="form-item">
                            <el-date-picker
                                v-model="filterData.Date"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="~"
                                :start-placeholder="$t('msg29')"
                                :end-placeholder="$t('msg29')"
                                >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('msg56')" class="form-item">
                          <el-input v-model="filterData.RechargeID" :placeholder="$t('msg56')" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('msg41')" class="form-item">
                          <el-input v-model="filterData.Username" :placeholder="$t('msg41')" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('msg58')" class="form-item">
                            <el-col>
                                <el-select v-model="filterData.RechargeModel" :placeholder="$t('msg20')">
                                    <el-option
                                        v-for="item in RechargeModel"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('msg9')" class="form-item">
                            <el-col>
                                <el-select v-model="filterData.RechargeStatus" :placeholder="$t('msg20')">
                                    <el-option
                                        v-for="item in RechargeStatus"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-form-item>
                    </el-col>
                  </el-form>
                </el-col>
                <el-col class="dialog-footer">
                    <el-button type="success" icon="el-icon-search" @click="searched">{{$t('msg28')}}</el-button>
                </el-col>
            </el-row>
        </el-dialog>

        <el-dialog :visible.sync="dialog.Visible" :destroy-on-close="true" :close-on-click-modal="false" width="360px">
            <el-row>
                <el-col >
                    <el-row>
                        <el-col>
                          <el-form  ref="ruleForm"  status-icon>
                            <el-col :span="12">
                                  <el-input v-model="tmpA.Amount" placeholder="金額" ></el-input>
                            </el-col>
                            <el-col :span="12">
                                <el-button style="margin-left: 10px;" size="small" type="success" @click="UpAmount()">修改金額</el-button>
                            </el-col>
                          </el-form>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6" >
import moment from 'moment'
import { apiRechargeList,apiRechargeSet,apiRechAmountSet } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
export default {
    mixins: [globalMixin],
    data() {
        return {
            ucoinRate:0,
            rmbcoinRate:0,
            rechargeFee:0,
            act:"set",
            loading:false,
            dialog:{
                Visible:false,
            },
            tmpA:{
                index:0,
                Amount:0,
                ID:0,
            },
            from:{
                ID:0,
                Username:"",
                RechargeID:0,
                RechargeModelName:"",
                RechargeComName:"",
                Amount:0,
                Memo:"",
                Info:"",
            },
            page:{
                page:0,
                pageSize:0,
                total:0,
                totalPage:0,
            },
            filterData:{
                Visible:false,
                Date:'',
            },
            rules: {
              Title: [
                { required: true, message: this.$t('msg31'), trigger: 'blur' },
              ],
              Content: [
                { required: true, message: this.$t('msg26'), trigger: 'blur' },
              ],
              Enable: [
                { required: true, message: this.$t('msg32'), trigger: 'blur' },
              ],
            },
            fileList:[],
            results:[],
            sumTotal:0,
            Pic:'',
            IDPic:'',
        }
    },
    methods: {
        UpAmount(){
            let setPage = this.page.page
            apiRechAmountSet({
                ID: this.tmpA.ID,
                Amount: this.tmpA.Amount,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.dialog.Visible = false
                        this.getLog(setPage)
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        UpAmountLay(item,index){
           this.dialog.Visible = true
           this.tmpA.index = index
           this.tmpA.Amount = item.Amount
           this.tmpA.ID = item.ID
        },
        search()
        {
            this.filterData.Visible=true
        },
        searched()
        {
            this.filterData.Visible=false
            this.getLog(1);
        },
        update(item,status)
        {
            this.act="set"
            this.from.ID=item.ID
            this.from.Username=item.Username
            this.from.RechargeID=item.RechargeID
            this.from.RechargeModelName=item.RechargeModelName
            this.from.RechargeComName=item.RechargeComName
            this.from.Amount=item.Amount
            //this.dialog.Visible=true
            if(status==2){
                this.$prompt(this.$t('msg150'), 'Info', {
                          confirmButtonText: this.$t('msg64'),
                          cancelButtonText: this.$t('msg151'),
                        }).then(({ value }) => {
                          this.from.Info=value
                          this.updated(status)
                        }).catch(() => {
                          return    
                        }); 
            }else{
                this.updated(status)
            }
        },
        updated(status)
        {
            // let chk = false
            // this.$refs["ruleForm"].validate((valid) => {
            //   if (!valid) {
            //     chk = true;
            //   }
            // },chk);
            // if(chk)
            // {
            //     this.message(this.$t('msg33'))
            //     return;
            // }
            this.dialog.Visible=false
            var api = apiRechargeSet
            let setPage = 1
            switch(this.act)
            {
                case "set":
                    api = apiRechargeSet
                    setPage = this.page.page
                break;
            }
            api({
                ID: this.from.ID,
                Status: status,
                Info: this.from.Info,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.getLog(setPage)
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        getLog(page)
        {
            let shdate = this.getSEDate(this.filterData.Date)
            apiRechargeList({
                Page: page,
                FromTime: shdate.FromTime,
                ToTime: shdate.ToTime,
                RechargeStatus:this.filterData.RechargeStatus,
                RechargeModel:this.filterData.RechargeModel,
                RechargeID:this.filterData.RechargeID,
                Username:this.filterData.Username,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.results=response.data.data.data;
                        this.page=response.data.data.page;
                        this.sumTotal=response.data.data.sum;
                        this.rechargeFee = response.data.data.rechargeFee;
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
    },
    computed: {
		...mapGetters({
            config:'config',
            user:'user',
        }),
        'RechargeStatus'()
        {
            return[
                {
                  value: '',
                  label: 'ALL'
                },
                {
                  value: 0,
                  label: this.$t('msg61')
                }, {
                  value: 1,
                  label: this.$t('msg62')
                }, {
                  value: 2,
                  label: this.$t('msg63')
                }
            ]
        },
        'RechargeModel'()
        {
            return[
                {
                  value: '',
                  label: 'ALL'
                },
                {
                  value: 0,
                  label: this.$t('RechargeModel.n1') /*公司匯款*/
                }, {
                  value: 1,
                  label: this.$t('RechargeModel.n2') /*三方支付*/
                }, {
                  value: 2,
                  label: this.$t('RechargeModel.n3') /*管理員充值*/
                }
            ]
        }

    },
    components: {
    },
    created() {
        let FromTime = moment(new Date()).add('day',-14).format("YYYY-MM-DD")
        let ToTime = moment(new Date()).add('day',0).format("YYYY-MM-DD")
        this.filterData.Date=[FromTime,ToTime]
        this.getLog(1);
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

</style>