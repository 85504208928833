import Vue from 'vue'
import App from './App.vue'
import enlocale from 'element-ui/lib/locale/lang/en'
import vilocale from 'element-ui/lib/locale/lang/vi'
import twlocale from 'element-ui/lib/locale/lang/zh-TW'
import cnlocale from 'element-ui/lib/locale/lang/zh-CN'
import cn from './plugins/cn.js'
import tw from './plugins/zh-TW.js'
import vi from './plugins/vi-VN.js'
import en from './plugins/en.js'
import './plugins/element.js'

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
  //locale: 'vi-VN', // 设置地区
  locale: 'zh-TW', // 设置地区
  messages:{
    'zh-TW':Object.assign(tw,twlocale),
    'cn':Object.assign(cn,cnlocale),
    'en':Object.assign(en,enlocale),
    'vi-VN':Object.assign(vi,vilocale),
  }, // 设置地区信息
})
import Vuex from 'vuex'
Vue.use(Vuex);
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
import JsonViewer from 'vue-json-viewer'
Vue.use(JsonViewer)
import store from './store'
import router from './router'
Vue.config.productionTip = false

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
import quillEditor from 'vue-quill-editor'
Vue.use(quillEditor)

Vue.filter("numFilter", function(value) {
    let tempVal = parseFloat(value).toFixed(3);
    let realVal = tempVal.substring(0, tempVal.length - 1);
    return realVal.replace(/^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/, function (all, pre, groupOf3Digital) {
        return pre + groupOf3Digital.replace(/\d{3}/g, ',$&');
      });
});

new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
