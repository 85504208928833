<template>
    <div>
        <el-row >
            <el-col>
                <el-button type="primary" @click="updated" v-if="user.GroupPermission.n6.Modify">{{$t('msg16')}}</el-button>
                <el-button type="primary" @click="add" v-if="user.GroupPermission.n6.Modify">{{$t('msg7')}}</el-button>
            </el-col>
            <div class="mainSearch" >
                <table cellspacing="0" cellpadding="0" border="0" class="mainTable" >
                    <tr>
                        <td class="td-head td-min" style="width: 50px;">#</td>
                        <td class="td-head" style="width: 90px;">{{$t('Prod.Name')}}</td>
                        <td class="td-head">{{$t('Prod.Name')}}</td>
                        <td class="td-head" style="width: 80px;">{{$t('Prod.Price')}}</td>
                        <td class="td-head">{{$t('Prod.Url')}}</td>
                        <!-- <td class="td-head td-min">{{$t('Prod.Class')}}</td> -->
                        <td class="td-head td-min">{{$t('Prod.Device')}}</td>
                        <td class="td-head" style="width: 80px;">{{$t('Prod.Api')}}</td>
                        <td class="td-head" style="width: 80px;">{{$t('Prod.Setpic')}}</td>
                        <td class="td-head " style="width: 100px;">{{$t('Prod.Enable')}}</td>
                        <td class="td-head">{{$t('Prod.Note')}}</td>
                    </tr>
                    <tr v-for="(item, index) in results" :key="index" :class="{'tr-color':index%2==0}">
                        <td class="td-content">{{ item.ID }}</td>
                        <td class="td-content" >
                                <el-button type="primary" size="mini" @click="fastadd(item)" v-if="user.GroupPermission.n6.Modify">{{$t('msg163')}}</el-button>
                                <el-image
                                    v-if="results[index].Pic!=''"
                                  :src="config.ImgUrl+'prod/'+results[index].Pic"
                                   style="height: 80px;margin-top: 5px;"></el-image>
                        </td>
                        <td class="td-content">
                            <el-input v-model="results[index].Name"  ></el-input>
                        </td>
                        <td class="td-content td-min" >
                            <el-input v-model="results[index].Price" ></el-input>
                        </td>
                        <td class="td-content">
                            <el-input v-model="results[index].Url" size="small"  ></el-input>
                        </td>
                        <td class="td-content">
                            <!-- <el-input v-model="results[index].Class" size="small"  ></el-input> -->
                            <el-select v-model="results[index].Class" :placeholder="$t('msg20')" @change="updateEnable(item)">
                                <el-option
                                    v-for="item in Class"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                            <el-select v-model="results[index].Device" :placeholder="$t('msg20')" @change="updateEnable(item)" style="margin-top: 5px;">
                                <el-option
                                    v-for="item in Device"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </td>
                       <td class="td-content">
                            <el-button type="primary" @click="setapi(item,index)" size="mini" v-if="user.GroupPermission.n6.Modify">{{$t('Prod.SetApi')}}</el-button>
                        </td>
                        <td class="td-content">
                            <el-button type="primary" @click="setpic(item,index)" size="mini" v-if="user.GroupPermission.n6.Modify">{{$t('Prod.Setpic')}}</el-button>
                        </td>
                        <td class="td-content">
                            <el-switch
                              v-model="results[index].Enable"
                              :active-text="$t('msg1')"
                              :inactive-text="$t('msg2')"
                              :active-value="1"
                              :inactive-value="0"
                              @change="updateEnable(item)"
                              >
                            </el-switch>
                        </td>
                        <td class="td-content">
                            <el-input v-model="results[index].Note" type="textarea" :rows="2"  ></el-input>
                        </td>
                    </tr>
                </table>
                <div class="mainPage">
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      :total="page.total"
                      :page-size="page.pageSize"
                      :current-page="page.page"
                      @current-change="getLog"
                      >
                    </el-pagination>
                </div>                
                <div class="no-record" v-if="!results">
                    <img class="no-record-img" src="@/assets/images/norecord.png">
                    <div class="no-record-txt">{{$t('msg17')}}</div>
                </div>
            </div>
            <el-col>
                <el-button type="primary" @click="updated" >{{$t('msg16')}}</el-button>
            </el-col>
        </el-row>
        <el-dialog :visible.sync="dialog.VisibleAdd" :destroy-on-close="true" :close-on-click-modal="false" width="620px" >
            <el-row>
                <el-col >
                    <el-row>
                        <el-col>
                          <el-form :model="from" ref="ruleForm"  status-icon  label-width="120px">
                            <el-form-item :label="$t('Prod.Name')" prop="Title">
                              <el-input v-model="from.Name" :placeholder="$t('Prod.Name')" ></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('Prod.Price')" prop="Title">
                              <el-input v-model="from.Price" :placeholder="$t('Prod.Price')" ></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('Prod.Url')" prop="Title">
                              <el-input v-model="from.Url" :placeholder="$t('Prod.Url')" ></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('Prod.Class')" prop="Title">
                                <el-radio v-model="from.Class" :label="1">{{$t('Class.no1')}}</el-radio>
                                <el-radio v-model="from.Class" :label="2">{{$t('Class.no2')}}</el-radio>
                                <el-radio v-model="from.Class" :label="3">{{$t('Class.no3')}}</el-radio>
                              <!-- <el-input v-model="from.WaitDay" :placeholder="$t('Prod.WaitDay')" ></el-input> -->
                            </el-form-item>
                            <el-form-item :label="$t('Prod.Device')" prop="Title">
                                <el-radio v-model="from.Device" :label="1">{{$t('Prod.IOS')}}</el-radio>
                                <el-radio v-model="from.Device" :label="2">{{$t('Prod.Android')}}</el-radio>
                              <!-- <el-input v-model="from.WaitDay" :placeholder="$t('Prod.WaitDay')" ></el-input> -->
                            </el-form-item>
                            
                            <el-form-item :label="$t('msg36')" prop="Title">
                                <el-upload
                                class="upload-demo"
                                ref="uploadAdd"
                                action="/api/comm/Upload?type=prod"
                                :on-success="handleSuccessAdd"
                                :file-list="fileList"
                                list-type="picture"
                                :auto-upload="false"
                                >
                                <el-button slot="trigger" size="small" type="primary">Select Picture</el-button>
                                <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload('uploadAdd')">upload</el-button>
                                <!-- <div slot="tip" class="el-upload__tip" style="font-size:14px">Best size : 120 * 120</div> -->
                                <div slot="tip" class="el-upload__tip" style="font-size:14px">only jpg,png. limit 2M</div>
                                </el-upload>
                            </el-form-item>

                            <el-form-item :label="$t('Prod.Note')" prop="Title">
                              <el-input v-model="from.Note" :placeholder="$t('Prod.Note')" ></el-input>
                            </el-form-item>

<!--                            <el-form-item :label="$t('Prod.Stype')" prop="Enable" >
                                <el-radio v-model="from.Stype" :label="1">{{$t('Prod.Stype2')}}</el-radio>
                                <el-radio v-model="from.Stype" :label="0">{{$t('Prod.Stype1')}}</el-radio>
                            </el-form-item> -->

                            <el-form-item :label="$t('Prod.Enable')" prop="Enable" >
                                <el-radio v-model="from.Enable" :label="1">{{$t('msg1')}}</el-radio>
                                <el-radio v-model="from.Enable" :label="0">{{$t('msg2')}}</el-radio>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col>
                            <div class="dialog-footer">
                                <el-button style="width: 80%;" type="primary"  @click="added" >{{$t('msg22')}}</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-dialog>
        <el-dialog :visible.sync="dialog.Visible" :destroy-on-close="true" :close-on-click-modal="false" width="360px">
            <el-row>
                <el-col >
                    <el-row>
                        <el-col>
                          <el-form  ref="ruleForm"  status-icon>
                            <el-form-item :label="$t('msg36')" prop="Img">
                                <div>
                                <el-image
                                    v-if="tmpPic.pic!=''"
                                  :src="config.ImgUrl+'prod/'+tmpPic.pic"
                                  ></el-image>
                                </div>
                                <el-upload
                                class="upload-demo"
                                ref="upload"
                                action="/api/comm/Upload?type=prod"
                                :on-success="handleSuccess"
                                :file-list="fileList"
                                list-type="picture"
                                :auto-upload="false"
                                >
                                <el-button slot="trigger" size="small" type="primary">Select Picture</el-button>
                                <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload('upload')">upload</el-button>
                                <!-- <div slot="tip" class="el-upload__tip" style="font-size:14px">Best size : 120 * 120</div> -->
                                <div slot="tip" class="el-upload__tip" style="font-size:14px">only jpg,png. limit 2M</div>
                                </el-upload>
                            </el-form-item>                            
                          </el-form>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-dialog>
        <el-dialog :visible.sync="dialog.VisibleGame" :destroy-on-close="true" :close-on-click-modal="false" width="360px">
            <el-row>
                <el-col >
                    <el-row>
                        <el-col>
                          <el-form  ref="ruleForm"  status-icon>
                            <el-form-item :label="$t('Prod.Api')" prop="Enable" >
                                <el-radio v-model="tmpPic.Isgame" :label="1">{{$t('msg1')}}</el-radio>
                                <el-radio v-model="tmpPic.Isgame" :label="0">{{$t('msg2')}}</el-radio>
                            </el-form-item>
                            <el-form-item :label="$t('Prod.Account')" prop="Title">
                              <el-input v-model="tmpPic.Account" :placeholder="$t('Prod.Account')" ></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('Prod.ApiUrl')" prop="Title">
                              <el-input v-model="tmpPic.Api" :placeholder="$t('Prod.ApiUrl')" ></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('Prod.Key')" prop="Title">
                              <el-input v-model="tmpPic.Key" :placeholder="$t('Prod.Key')" ></el-input>
                            </el-form-item>
                            <el-button style="margin-left: 10px;" size="small" type="success" @click="submitApi">{{$t('msg21')}}</el-button>
                            <!-- <div slot="tip" class="el-upload__tip" style="font-size:14px">Best size : 120 * 120</div> -->
                          </el-form>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6" >
import { apiProdList,apiProdSet,apiProdSetEnable,apiProdAdd } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
export default {
    mixins: [globalMixin],
    data() {
        return {
            fileList:[],
            results:[],
            dialog:{
                Visible:false,
                VisibleAdd:false,
                VisibleGame:false,
            },
            tmpPic:{
                index:0,
                pic:'',
                Isgame:0,
                Account:'',
                Api:'',
                Key:'',
            },
            page:{
                page:0,
                pageSize:0,
                total:0,
                totalPage:0,
            },
            from:{
                Bonus:0,
                Enable:0,
                Name:'',
                Note:'',
                Pic:'',
                Price:0,
                Stype:0,
                WaitDay:0,
                Isgame:0,
                Account:'',
                Api:'',
                Key:'',
            }
        }
    },
    methods: {
        fastadd(item){
            this.from.Enable=0
            this.from.Name=item.Name
            this.from.Note=item.Name
            this.from.Pic=""
            this.from.Price=item.Price
            this.from.Url=item.Url
            this.from.Class=item.Class
            this.from.Device=item.Device
            this.dialog.VisibleAdd=true
        },
        add(){
            this.from.Enable=0
            this.from.Name=""
            this.from.Note=""
            this.from.Pic=""
            this.from.Price=0
            this.from.Url=""
            this.from.Class=1
            this.from.Device=1
            this.dialog.VisibleAdd=true
        },
        setapi(item,index)
        {
            this.tmpPic.index = index
            this.tmpPic.Isgame = item.Isgame
            this.tmpPic.Account = item.Account
            this.tmpPic.Api = item.Api
            this.tmpPic.Key = item.Key
            this.dialog.VisibleGame = true
        },
        submitApi()
        {
            this.results[this.tmpPic.index].Isgame = this.tmpPic.Isgame
            this.results[this.tmpPic.index].Account = this.tmpPic.Account
            this.results[this.tmpPic.index].Api = this.tmpPic.Api
            this.results[this.tmpPic.index].Key = this.tmpPic.Key
            this.updateEnable(this.results[this.tmpPic.index])
            this.dialog.VisibleGame = false
        },
        setpic(item,index)
        {
           this.tmpPic.index = index
           this.tmpPic.pic = item.Pic
           this.dialog.Visible = true
        },
		submitUpload(index) 
        {
			this.$refs[index].submit();
		},
        handleSuccessAdd(response)
        {
            if(response.status)
            {
                this.from.Pic = response.data
            }
        },
        handleSuccess(response)
        {
            if(response.status)
            {
                this.results[this.tmpPic.index].Pic = response.data
                this.updateEnable(this.results[this.tmpPic.index])
            }
        },
        // updateStype(item)
        // {
        //     var api = apiProdSetStype
        //     api(item)
        //         .then(response => {
        //             if(!response.data.status)
        //             {
        //                 this.error_handler(response.data);
        //             }
        //             if(response.data.status)
        //             {
        //                 this.message(this.$t('msg40'))
        //                 //this.getLog()
        //             }
        //             return;
        //         }).catch(error => {
        //             console.log(error);
        //             return;
        //         });
        // },
        updateEnable(item)
        {
            if(!this.user.GroupPermission.n6.Modify)
            {
                this.message("No Permission")
                return
            }
            var api = apiProdSetEnable
            api(item)
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.message(this.$t('msg40'))
                        //this.getLog()
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        added()
        {
            var api = apiProdAdd
            api(this.from)
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.message(this.$t('msg40'))
                        this.getLog(1)
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        updated()
        {
            var api = apiProdSet
            api(this.results)
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.message(this.$t('msg40'))
                        this.getLog(this.page.page)
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        getLog(page)
        {
            this.results = []
            apiProdList({
                Page: page,
                // Stype: 0,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        // let data = {}
                        // for(var n in response.data.data)
                        // {
                        //     // data = {
                        //     //     'Title' : this.$t('Param.'+n),
                        //     //     'Key' : n,
                        //     //     'Value' : response.data.data[n],
                        //     // }
                            
                        // }
                        this.results=response.data.data.data;
                        this.page=response.data.data.page;
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
    },
    computed: {
		...mapGetters({
            config:'config',
            user:'user',
        }),
        'Device'()
        {
            return[
                {
                  value: 1,
                  label: this.$t('Prod.Android') /*三方支付*/
                }, 
                {
                  value: 2,
                  label: this.$t('Prod.IOS') /*管理員充值*/
                }
            ]
        },
        'Class'()
        {
            return[
                {
                  value: 1,
                  label: this.$t('Class.no1') /*三方支付*/
                }, 
                {
                  value: 2,
                  label: this.$t('Class.no2') /*管理員充值*/
                },
                {
                  value: 3,
                  label: this.$t('Class.no3') /*管理員充值*/
                },
                {
                  value: 4,
                  label: this.$t('Class.no4') /*管理員充值*/
                }
            ]
        } 
    },
    components: {
    },
    created() {
        this.getLog(1);
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

</style>