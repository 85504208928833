<template>
    <div>
        <el-row >
            <el-col>
                <el-button type="primary" @click="add" v-if="user.GroupPermission.n18.Modify">{{$t('msg7')}}</el-button>
                <!-- <el-button type="primary" @click="search" >{{$t('msg28')}}</el-button> -->
            </el-col>
            <div class="mainSearch" >
                <table cellspacing="0" cellpadding="0" border="0" class="mainTable">
                    <tr>
                        <td class="td-head td-min">#</td>
                        <td class="td-head ">{{$t('msg107')}}</td>
                        <td class="td-head ">{{$t('msg108')}}</td>
<!--                        <td class="td-head ">{{$t('msg109')}}</td>
                        <td class="td-head ">{{$t('msg110')}}</td> -->
                        <td class="td-head ">{{$t('msg111')}}</td>
                        <td class="td-head ">{{$t('msg112')}}</td>
                        <!-- <td class="td-head ">{{$t('msg113')}}</td> -->
                        <!-- <td class="td-head ">{{$t('msg114')}}</td> -->
                        <td class="td-head ">{{$t('msg115')}}</td>
                        <td class="td-head ">{{$t('msg116')}}</td>
                        <td class="td-head ">{{$t('msg117')}}</td>
                        <td class="td-head ">{{$t('msg15')}}</td>
                    </tr>
                    <tr v-for="(item, index) in results" :key="index" :class="{'tr-color':index%2==0}">
                        <td class="td-content">{{ item.ID }}</td>
                        <td class="td-content">{{ item.Name }}</td>
                        <td class="td-content" >{{ item.Merid }}</td>
<!--                        <td class="td-content" >{{ item.ApiKey }}</td>
                        <td class="td-content" >{{ item.SecretKey }}</td> -->
                        <td class="td-content" >{{ item.Area }}</td>
                        <td class="td-content" >{{ item.Rate }}</td>
                        <!-- <td class="td-content" >{{ item.Url }}</td> -->
                        <!-- <td class="td-content" >{{ item.Memo }}</td> -->
                        <td class="td-content" >{{ item.MinAmount }}</td>
                        <td class="td-content" >{{ item.MaxAmount }}</td>
                        <td class="td-content">
                            <el-tag v-if="item.Enable==1">{{ getEnableStatus(item) }}</el-tag>
                            <el-tag type="danger" v-if="item.Enable==0">{{ getEnableStatus(item) }}</el-tag>
                        </td>                        
                        <td class="td-content"><el-button type="primary" @click="update(item)" size="mini" v-if="user.GroupPermission.n18.Modify">{{$t('msg16')}}</el-button></td>
                    </tr>
                </table>
                <div class="mainPage">
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      :total="page.total"
                      :page-size="page.pageSize"
                      :current-page="page.page"
                      @current-change="getLog"
                      >
                    </el-pagination>
                </div>
                <div class="no-record" v-if="!results">
                    <img class="no-record-img" src="@/assets/images/norecord.png">
                    <div class="no-record-txt">{{$t('msg17')}}</div>
                </div>
            </div>
        </el-row>
        <el-dialog :visible.sync="dialog.Visible" :destroy-on-close="true" :close-on-click-modal="false" width="800px" >
            <el-row>
                <el-col >
                    <el-row>
                        <el-col>
                          <el-form :model="from" ref="ruleForm"  status-icon :inline="true" label-width="120px">
                            <el-form-item :label="$t('msg107')" >
                              <el-input v-model="from.Name" :placeholder="$t('msg107')" ></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('msg108')" >
                              <el-input v-model="from.Merid" :placeholder="$t('msg108')" ></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('msg109')" >
                              <el-input v-model="from.ApiKey" :placeholder="$t('msg109')" ></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('msg110')" >
                              <el-input v-model="from.SecretKey" :placeholder="$t('msg110')" ></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('msg111')" >
                              <el-input v-model="from.Area" :placeholder="$t('msg111')" ></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('msg112')" >
                              <el-input v-model="from.Rate" :placeholder="$t('msg112')" ></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('msg113')" >
                              <el-input v-model="from.Url" :placeholder="$t('msg113')" ></el-input>
                            </el-form-item>


                            <el-form-item :label="$t('msg115')" >
                              <el-input v-model="from.MinAmount" :placeholder="$t('msg115')" ></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('msg116')" >
                              <el-input v-model="from.MaxAmount" :placeholder="$t('msg116')" ></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('msg114')" >
                              <el-input v-model="from.Memo"  type="textarea" :rows="3" :placeholder="$t('msg114')" ></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('msg9')" >
                                <el-radio v-model="from.Enable" :label="1">{{$t('msg1')}}</el-radio>
                                <el-radio v-model="from.Enable" :label="0">{{$t('msg2')}}</el-radio>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col>
                            <div class="dialog-footer">
                                <el-button style="width: 80%;" type="primary" :loading="loading" @click="updated" v-if="act=='set'">{{$t('msg21')}}</el-button>
                                <el-button style="width: 80%;" type="primary" :loading="loading" @click="updated" v-if="act=='add'">{{$t('msg22')}}</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-dialog>
<!--        <el-dialog :visible.sync="filterData.Visible" :destroy-on-close="true" :close-on-click-modal="false" width="600px">
            <el-row>
                <el-col >
                  <el-form :model="filterData"  status-icon>
                    <el-form-item :label="$t('msg32')" prop="Date">
                        <el-date-picker
                            v-model="filterData.Date"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="~"
                            :start-placeholder="$t('msg29')"
                            :end-placeholder="$t('msg29')"
                            >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item :label="$t('msg23')" prop="Content">
                      <el-input v-model="filterData.Title" :placeholder="$t('msg31')" ></el-input>
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col class="dialog-footer">
                    <el-button type="success" icon="el-icon-search" @click="searched">{{$t('msg28')}}</el-button>
                </el-col>
            </el-row>
        </el-dialog> -->
    </div>
</template>

<script type="text/ecmascript-6" >
import { apiParamsThirdList,apiParamsThirdSet,apiParamsThirdAdd } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
export default {
    mixins: [globalMixin],
    data() {
        return {
            act:"set",
            loading:false,
            dialog:{
                Visible:false,
            },
            from:{
                ID:0,
                Enable:0,
                Name:"",
                Merid:"",
                ApiKey:"",
                SecretKey:"",
                Area:"",
                Rate:"1",
                Url:"",
                Memo:"",
                MinAmount:"",
                MaxAmount:"",
            },
            page:{
                page:0,
                pageSize:0,
                total:0,
                totalPage:0,
            },
            results:[],
        }
    },
    methods: {
        // search()
        // {
        //     this.filterData.Visible=true
        // },
        // searched()
        // {
        //     this.filterData.Visible=false
        //     this.getLog(1);
        // },
        add()
        {
            this.act="add"
            this.from.ID=0
            this.from.Name=""
            this.from.Merid=""
            this.from.ApiKey=""
            this.from.SecretKey=""
            this.from.Area=""
            this.from.Rate="1"
            this.from.Url=""
            this.from.Memo=""
            this.from.MinAmount=""
            this.from.MaxAmount=""
            this.from.Enable=0
            this.dialog.Visible=true       
        },
        update(item)
        {
            this.act="set"
            this.from.ID=item.ID
            this.from.Name=item.Name
            this.from.Merid=item.Merid
            this.from.ApiKey=item.ApiKey
            this.from.SecretKey=item.SecretKey
            this.from.Area=item.Area
            this.from.Rate=item.Rate
            this.from.Url=item.Url
            this.from.Memo=item.Memo
            this.from.MinAmount=item.MinAmount
            this.from.MaxAmount=item.MaxAmount
            this.from.Enable=item.Enable
            this.dialog.Visible=true
        },
        updated()
        {
            // let chk = false
            // this.$refs["ruleForm"].validate((valid) => {
            //   if (!valid) {
            //     chk = true;
            //   }
            // },chk);
            // if(chk)
            // {
            //     this.message(this.$t('msg33'))
            //     return;
            // }
            this.dialog.Visible=false
            var api = apiParamsThirdSet
            let setPage = 1
            switch(this.act)
            {
                case "set":
                    api = apiParamsThirdSet
                    setPage = this.page.page
                break;
                case "add":
                    api = apiParamsThirdAdd
                break;
            }
            api({
                ID: this.from.ID,
                Name: this.from.Name,
                Merid: this.from.Merid,
                ApiKey: this.from.ApiKey,
                SecretKey: this.from.SecretKey,
                Area: this.from.Area,
                Rate: this.from.Rate,
                Url: this.from.Url,
                Memo: this.from.Memo,
                MinAmount: this.from.MinAmount,
                MaxAmount: this.from.MaxAmount,
                Enable: this.from.Enable,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.getLog(setPage)
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        getLog(page)
        {
            //let shdate = this.getSEDate(this.filterData.Date)
            apiParamsThirdList({
                Page: page,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.results=response.data.data.data;
                        this.page=response.data.data.page;
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
    },
    computed: {
		...mapGetters({
            config:'config',
            user:'user',
        }),
    },
    components: {
    },
    created() {
        this.getLog(1);
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

</style>