// import axios from 'axios'
// import { apiGetGroup,apiGetUserData,apiGetCfg,apiGetCoin,apiGetType,apiGetBp} from "@/api.js";
// import { error_func } from '@/util'
export default {
    setUserInfo({commit}, user) {
        commit('setUserInfo',user);
        //this.state.user = user;
    },
    clearUserInfo({ commit }) {
        commit('setUserInfo',{});
    },
    // getCoin({ commit ,dispatch})
    // {
    //     clearInterval(this.state.coin_time_line);
    //     apiGetCoin({
    //         })
    //         .then(response => {
    //             if(response.data.error)
    //             {
    //                 //var f=error_func(response.data.func);
    //                 //f.func();
    //                 return;
    //             }
    //             //commit('SetCoinScore',response.data.data);
    //             dispatch('setUserInfo',response.data.data);
    //             this.state.coin_time_line= setInterval(() => {
    //                 dispatch('getCoin');
    //             }, 15000);
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         });  
    // },
}
