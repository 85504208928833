<template>
	<div >
		<el-row style="padding: 10px 0px;">

		</el-row>
	</div>
</template>

<script>
export default {
	data() {
		return {

		}
	},
	methods: {

	},
	components: {
	},
    computed: {
    },
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

</style>
