<template>
    <div>
        <el-row >
            <el-col>
                <el-button type="primary" @click="add" v-if="user.GroupPermission.n7.Modify">{{$t('msg7')}}</el-button>
                <el-button type="primary" @click="search" >{{$t('msg28')}}</el-button>
            </el-col>
            <div class="mainSearch" >
                <table cellspacing="0" cellpadding="0" border="0" class="mainTable">
                    <tr>
                        <td class="td-head td-min">#</td>
                        <td class="td-head td-min">{{$t('msg9')}}</td>
                        <td class="td-head ">{{$t('msg23')}}</td>
                        <td class="td-head td-max" >{{$t('msg24')}}</td>
                        <!-- <td class="td-head td-date">{{$t('msg128')}}</td> -->
                        <td class="td-head td-date">{{$t('msg25')}}</td>
                        <td class="td-head td-date">{{$t('msg14')}}</td>
                        <td class="td-head">{{$t('msg15')}}</td>
                    </tr>
                    <tr v-for="(item, index) in results" :key="index" :class="{'tr-color':index%2==0}">
                        <td class="td-content">{{ item.ID }}</td>
                        <td class="td-content">
                            <el-tag v-if="item.Enable==1">{{ getEnableStatus(item) }}</el-tag>
                            <el-tag type="danger" v-if="item.Enable==0">{{ getEnableStatus(item) }}</el-tag>
                        </td>
                        <td class="td-content">{{ item.Title }}</td>
                        <td class="td-content content-subtitle" >{{ item.Content }}</td>
                        <!-- <td class="td-content">{{ item.ShowDate }}</td> -->
                        <td class="td-content">{{ getMomentFullDate(item.CreatedAt) }}</td>
                        <td class="td-content">{{ getMomentFullDate(item.UpdatedAt) }}</td>
                        <td class="td-content">
                            <el-button type="primary" @click="update(item)" v-if="user.GroupPermission.n7.Modify" size="mini">{{$t('msg16')}}</el-button>
                            <el-button type="primary" @click="del(item)" v-if="user.GroupPermission.n7.Modify" size="mini">{{$t('msg104')}}</el-button>
                        </td>
                    </tr>
                </table>
                <div class="mainPage">
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      :total="page.total"
                      :page-size="page.pageSize"
                      :current-page="page.page"
                      @current-change="getLog"
                      >
                    </el-pagination>
                </div>
                <div class="no-record" v-if="!results">
                    <img class="no-record-img" src="@/assets/images/norecord.png">
                    <div class="no-record-txt">{{$t('msg17')}}</div>
                </div>
            </div>
        </el-row>
        <el-dialog :visible.sync="dialog.Visible" :destroy-on-close="true" :close-on-click-modal="false">
            <el-row>
                <el-col >
                    <el-row>
                        <el-col>
                          <el-form :model="from" ref="ruleForm" :rules="rules" status-icon>
                            <el-form-item :label="$t('msg23')" prop="Title">
                              <el-input v-model="from.Title" :placeholder="$t('msg31')" ></el-input>
                            </el-form-item>
<!--                            <el-form-item :label="$t('msg128')" prop="Title">
                              <el-input v-model="from.ShowDate" :placeholder="'dd/mm/yyyy'" ></el-input>
                            </el-form-item>                                                           -->
                            <el-form-item :label="$t('msg24')" prop="Content">
                              <el-input v-model="from.Content"  type="textarea" :rows="8" :placeholder="$t('msg26')" ></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('msg9')" prop="Enable" >
                                <el-radio v-model="from.Enable" :label="1">{{$t('msg1')}}</el-radio>
                                <el-radio v-model="from.Enable" :label="0">{{$t('msg2')}}</el-radio>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col>
                            <div class="dialog-footer">
                                <el-button style="width: 80%;" type="primary" :loading="loading" @click="updated" v-if="act=='set'">{{$t('msg21')}}</el-button>
                                <el-button style="width: 80%;" type="primary" :loading="loading" @click="updated" v-if="act=='add'">{{$t('msg22')}}</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-dialog>
        <el-dialog :visible.sync="filterData.Visible" :destroy-on-close="true" :close-on-click-modal="false" width="600px">
            <el-row>
                <el-col >
                  <el-form :model="filterData"  status-icon>
                    <el-form-item :label="$t('msg32')" prop="Date">
                        <el-date-picker
                            v-model="filterData.Date"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="~"
                            :start-placeholder="$t('msg29')"
                            :end-placeholder="$t('msg29')"
                            >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item :label="$t('msg23')" prop="Content">
                      <el-input v-model="filterData.Title" :placeholder="$t('msg31')" ></el-input>
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col class="dialog-footer">
                    <el-button type="success" icon="el-icon-search" @click="searched">{{$t('msg28')}}</el-button>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6" >
import { apiBulletinList,apiBulletinSet,apiBulletinAdd,apiBulletinDel } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
export default {
    mixins: [globalMixin],
    data() {
        return {
            act:"set",
            loading:false,
            dialog:{
                Visible:false,
            },
            from:{
                ID:0,
                Enable:0,
                Title:"",
                Content:"",
                ShowDate:"",
            },
            page:{
                page:0,
                pageSize:0,
                total:0,
                totalPage:0,
            },
            filterData:{
                Visible:false,
                Date:'',
            },
            rules: {
              Title: [
                { required: true, message: this.$t('msg31'), trigger: 'blur' },
              ],
              Content: [
                { required: true, message: this.$t('msg26'), trigger: 'blur' },
              ],
              Enable: [
                { required: true, message: this.$t('msg32'), trigger: 'blur' },
              ],
            },
            results:[],
        }
    },
    methods: {
        search()
        {
            this.filterData.Visible=true
        },
        searched()
        {
            this.filterData.Visible=false
            this.getLog(1);
        },
        del(item)
        {
            this.act="del"
            this.from.ID=item.ID
            this.updated()
            //apiBulletinDel
        },
        add()
        {
            this.act="add"
            this.from.ID=0
            this.from.Title=""
            this.from.ShowDate=""
            this.from.Content=""
            this.from.Enable=1
            this.dialog.Visible=true
        },
        update(item)
        {
            this.act="set"
            this.from.ID=item.ID
            this.from.Title=item.Title
            this.from.ShowDate=item.ShowDate
            this.from.Enable=item.Enable
            this.from.Content=item.Content
            this.dialog.Visible=true
        },
        updated()
        {
            let chk = false
            if(this.$refs["ruleForm"])
            {
                this.$refs["ruleForm"].validate((valid) => {
                  if (!valid) {
                    chk = true;
                  }
                },chk);
            }
            if(chk)
            {
                this.message(this.$t('msg33'))
                return;
            }
            this.dialog.Visible=false
            var api = apiBulletinSet
            let setPage = 1
            switch(this.act)
            {
                case "set":
                    api = apiBulletinSet
                    setPage = this.page.page
                break;
                case "add":
                    api = apiBulletinAdd
                break;
                case "del":
                    api = apiBulletinDel
                    setPage = this.page.page
                break;
            }
            api({
                ID: this.from.ID,
                Title: this.from.Title,
                Enable: this.from.Enable,
                Content: this.from.Content,
                ShowDate: this.from.ShowDate,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.getLog(setPage)
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        getLog(page)
        {
            let shdate = this.getSEDate(this.filterData.Date)
            apiBulletinList({
                Page: page,
                FromTime: shdate.FromTime,
                ToTime: shdate.ToTime,
                Title:this.filterData.Title,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.results=response.data.data.data;
                        this.page=response.data.data.page;
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
    },
    computed: {
		...mapGetters({
            config:'config',
            user:'user',
        }),
    },
    components: {
    },
    created() {
        this.getLog(1);
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

</style>