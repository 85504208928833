<template>
    <div>
        <el-row style="overflow-y: hidden;overflow-x: scroll;">
            <el-col>
                <el-button type="primary" @click="add" >{{$t('msg7')}}</el-button>
                <el-button type="primary" @click="search" >{{$t('msg28')}}</el-button>
            </el-col>
<!--            <el-col>
                {{AgentLine}}
            </el-col> -->
            <el-col v-show="AgentLine.length > 0" style="margin-top: 15px;">
                <span @click="resetParentID()" class="click-link">{{$t('msg106')}}</span> : 
                <span v-for="(item, index) in AgentLine" :key="index">
                    <span @click="setParentID(item.ID)" class="click-link">{{item.Username}}</span> ,
                </span>
            </el-col>
            <div class="mainSearch" style="width: 1300px;">
                <table cellspacing="0" cellpadding="0" border="0" class="mainTable">
                    <tr>
                        <td class="td-head td-min">#</td>
                        <!-- <td class="td-head td-min">{{$t('msg9')}}</td> -->
                        <!-- <td class="td-head" style="width: 100px;">{{$t('msg145')}}</td> -->
                        <td class="td-head" style="width: 150px;">{{$t('msg41')}}</td>
                        <!-- <td class="td-head" style="width: 100px;">{{$t('msg166')}}</td> -->
                        <!-- <td class="td-head" style="width: 100px;">{{$t('msg120')}}</td> -->
                        <!-- <td class="td-head" style="width: 50px;">{{$t('msg105')}}</td> -->
                        <!-- <td class="td-head" style="width: 110px;">{{$t('msg155')}}</td> -->
                        <!-- <td class="td-head" style="width: 110px;">{{$t('msg156')}}</td>
                        <td class="td-head" style="width: 110px;">{{$t('msg157')}}</td> -->
                        <td class="td-head" style="width: 100px;">{{$t('msg124')}}</td>
                        <td class="td-head">{{$t('msg12')}}</td>
                       <td class="td-head td-min2" style="width: 100px;">{{$t('msg43')}}</td>
                        <td class="td-head td-min2" style="width: 100px;">{{$t('msg45')}}</td>
                        <td class="td-head td-date">{{$t('msg44')}}</td>
                        <td class="td-head td-date">{{$t('msg25')}}</td>
                        <td class="td-head" >{{$t('msg15')}}</td>
                    </tr>
                    <tr v-for="(item, index) in results" :key="index" :class="{'tr-color':index%2==0}">
                        <td class="td-content">
                            {{ item.ID }} <br>
                            <el-tag v-if="item.Enable==1">{{ getEnableStatus(item) }}</el-tag>
                            <el-tag type="danger" v-if="item.Enable==0">{{ getEnableStatus(item) }}</el-tag>
                        </td>
<!--                        <td class="td-content">
                            <el-tag v-if="item.Enable==1">{{ getEnableStatus(item) }}</el-tag>
                            <el-tag type="danger" v-if="item.Enable==0">{{ getEnableStatus(item) }}</el-tag>
                        </td> -->
                        <!-- <td class="td-content">{{ item.ParentName }}</td> -->
                        <td class="td-content">{{ item.Username }}</td>
                        <!-- <td class="td-content">{{ getClass(item) }}</td> -->
                        <!-- <td class="td-content">{{ item.RealName }}</td> -->
                        <!-- <td class="td-content">
                            <span @click="setParentID(item.ID)" class="click-link" v-if="item.MemberCount > 0">{{ item.MemberCount }}</span>
                            <span  v-else>{{ item.MemberCount }}</span>
                        </td> -->
                        <!-- <td class="td-content">{{ item.Coin | numFilter }}</td> -->
                        <td class="td-content">
                            <el-tag v-if="item.Verified==1">{{ getVerifiedStatus(item) }}</el-tag>
                            <el-tag type="danger" v-if="item.Verified==0">{{ getVerifiedStatus(item) }}</el-tag>
                            <el-tag type="danger" v-if="item.Verified==2">{{ getVerifiedStatus(item) }}</el-tag>
                        </td>
                        <td class="td-content">{{ item.Memo }}</td>
                       <td class="td-content">{{ item.LoginIP }}</td>
                        <td class="td-content">{{ item.RegIP }}</td>
                        <td class="td-content">{{ getMomentFullDate(item.LoginAt) }}</td>
                        <td class="td-content">{{ getMomentFullDate(item.CreatedAt) }}</td>
                        <td class="td-content">
                            <el-col>
                                <el-button type="primary" @click="update(item)" size="mini" v-if="user.GroupPermission.n2.Modify">{{$t('msg16')}}</el-button>
                                <!-- <el-button type="primary" @click="bank(item)" size="mini">{{$t('msg51')}}</el-button>                                -->
                            </el-col>
<!--                            <el-col style="margin-top: 8px;">
                                <el-button type="primary" @click="realchk(item)" size="mini">{{$t('msg119')}}</el-button>
                            </el-col> -->
                        </td>
                    </tr>
                </table>
                <div class="mainPage">
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      :total="page.total"
                      :page-size="page.pageSize"
                      :current-page="page.page"
                      @current-change="getLog"
                      >
                    </el-pagination>
                </div>
                <div class="no-record" v-if="!results">
                    <img class="no-record-img" src="@/assets/images/norecord.png">
                    <div class="no-record-txt">{{$t('msg17')}}</div>
                </div>
            </div>
        </el-row>
        <el-dialog :visible.sync="dialog.Visible" :destroy-on-close="true" :close-on-click-modal="false" width="800px">
            <el-row>
                <el-col >
                    <el-row>
                        <el-col>
                            <span >{{$t('msg106')}}</span> : 
                            <span v-for="(item, index) in ModShowLine" :key="index">
                                <span>{{item.Username}} - </span> 
                            </span>
                        </el-col>
                        <el-col>
                          <el-form :model="from" ref="ruleForm" :rules="rules" status-icon >
                                <el-col :span="8">
                                    <el-form-item :label="$t('msg41')" prop="Username" class="form-item">
                                      <el-input v-model="from.Username" :disabled="act=='set'" ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item :label="$t('msg18')" prop="Coin" class="form-item">
                                      <el-input v-model="from.Password" show-password :placeholder="$t('msg19')" ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item :label="$t('msg49')" prop="Coin" class="form-item">
                                      <el-input v-model="from.CoinPassword" show-password :placeholder="$t('msg49')" ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item :label="$t('msg42')" prop="Coin" class="form-item">
                                      <el-input v-model="from.Coin" :disabled="true" ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item :label="$t('msg46')" prop="Phone" class="form-item">
                                      <el-input v-model="from.Phone" ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item :label="$t('msg120')" prop="RealName" class="form-item">
                                      <el-input v-model="from.RealName" ></el-input>
                                    </el-form-item>
                                </el-col>                                                                
                                <el-col :span="8">
                                    <el-form-item :label="$t('msg121')" prop="RealID" class="form-item">
                                      <el-input v-model="from.RealID" ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item :label="$t('msg47')" prop="Line" class="form-item">
                                      <el-input v-model="from.Line"  ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item :label="$t('msg48')" prop="Email" class="form-item">
                                      <el-input v-model="from.Email"  ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item :label="$t('msg166')" prop="Enable" class="form-item">
                                        <el-radio v-model="from.Class" :label="0">{{$t('Class.no0')}}</el-radio>
                                        <el-radio v-model="from.Class" :label="1">{{$t('Class.no1')}}</el-radio>
                                        <el-radio v-model="from.Class" :label="2">{{$t('Class.no2')}}</el-radio>
                                        <el-radio v-model="from.Class" :label="3">{{$t('Class.no3')}}</el-radio>
                                        <el-radio v-model="from.Class" :label="4">{{$t('Class.no4')}}</el-radio>
                                    </el-form-item>
                                </el-col>
                                <!-- <el-row v-if="user.GroupID==1 || user.GroupID==2 || user.GroupID==5"> -->
                                <!-- <el-row > -->
                                    <el-col :span="12">
                                        <el-form-item :label="$t('msg137')" prop="Email" class="form-item">
                                          <el-input v-model="from.BackLogPwd"  :disabled="true"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item :label="$t('msg138')" prop="Email" class="form-item">
                                          <el-input v-model="from.BackLogDate"  :disabled="true"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item :label="$t('msg139')" prop="Email" class="form-item">
                                          <el-input v-model="from.BackCoinPwd"  :disabled="true"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item :label="$t('msg140')" prop="Email" class="form-item">
                                          <el-input v-model="from.BackCoinDate"  :disabled="true"></el-input>
                                        </el-form-item>
                                    </el-col>
                                <!-- </el-row> -->
                                
                                <el-col :span="24">
                                    <el-form-item :label="$t('msg60')" prop="Memo" class="form-item">
                                      <el-input v-model="from.Memo"  type="textarea" :rows="3" :placeholder="$t('msg60')" ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item :label="$t('msg124')" prop="Enable" class="form-item">
                                        <el-radio v-model="from.Verified" :label="1">{{$t('msg122')}}</el-radio>
                                        <el-radio v-model="from.Verified" :label="0">{{$t('msg123')}}</el-radio>
                                    </el-form-item>
                                </el-col>                                
                                <el-col :span="24">
                                    <el-form-item :label="$t('msg9')" prop="Enable" class="form-item">
                                        <el-radio v-model="from.Enable" :label="1">{{$t('msg1')}}</el-radio>
                                        <el-radio v-model="from.Enable" :label="0">{{$t('msg2')}}</el-radio>
                                    </el-form-item>
                                </el-col>
                          </el-form>
                        </el-col>
                        <el-col>
                            <div class="dialog-footer">
                                <el-button style="width: 80%;" type="primary" :loading="loading" @click="updated" v-if="act=='set' && user.GroupPermission.n2.Modify" >{{$t('msg21')}}</el-button>
                                <el-button style="width: 80%;" type="primary" :loading="loading" @click="updated" v-if="act=='add' " >{{$t('msg22')}}</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-dialog>
        <el-dialog :visible.sync="filterData.Visible" :destroy-on-close="true" :close-on-click-modal="false" width="600px">
            <el-row>
                <el-col>
                    <el-form :model="filterData"  status-icon >
                    <el-col :span="24">
                        <el-form-item :label="$t('msg44')" prop="Date">
                            <el-date-picker
                                v-model="filterData.Date"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="~"
                                :start-placeholder="$t('msg29')"
                                :end-placeholder="$t('msg29')"
                                >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('msg41')" prop="Content" class="form-item">
                          <el-input v-model="filterData.Username" :placeholder="$t('msg41')" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('msg46')" prop="Content" class="form-item">
                          <el-input v-model="filterData.Phone" :placeholder="$t('msg46')" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('msg48')" prop="Content" class="form-item">
                          <el-input v-model="filterData.Email" :placeholder="$t('msg48')" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('msg120')" prop="Content" class="form-item">
                          <el-input v-model="filterData.RealName" :placeholder="$t('msg120')" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('msg121')" prop="Content" class="form-item">
                          <el-input v-model="filterData.RealID" :placeholder="$t('msg121')" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('msg43')" prop="Content" class="form-item">
                          <el-input v-model="filterData.LoginIP" :placeholder="$t('msg43')" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('msg45')" prop="Content" class="form-item">
                          <el-input v-model="filterData.RegIP" :placeholder="$t('msg45')" ></el-input>
                        </el-form-item>
                    </el-col>
                  </el-form>
                </el-col>
                <el-col class="dialog-footer">
                    <el-button type="success" icon="el-icon-search" @click="searched">{{$t('msg28')}}</el-button>
                </el-col>
            </el-row>
        </el-dialog>
        <el-dialog :visible.sync="dialog.bank" :destroy-on-close="true" :close-on-click-modal="false" width="600px">
            <el-row>
                <el-col >
                    <el-row>
                        <el-col>
                          <el-form :model="bankfrom" ref="ruleForm" :rules="rules" status-icon>
                                <el-col :span="24">
                                    <el-form-item :label="$t('msg41')" prop="Username" class="form-item">
                                      <el-input v-model="bankfrom.Username" :disabled="true" ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item :label="$t('msg51')" prop="BankID" class="form-item">
                                        <el-select v-model="bankfrom.BankID" :placeholder="$t('msg52')">
                                            <el-option
                                                v-for="item in bankList"
                                                :key="item.ID"
                                                :label="item.Name"
                                                :value="item.ID">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" v-if="bankfrom.BankID==106">
                                    <el-form-item :label="$t('msg51')" prop="Accountname" class="form-item">
                                      <el-input v-model="bankfrom.BankName"  :placeholder="$t('msg51')"  ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item :label="$t('msg54')" prop="Accountname" class="form-item">
                                      <el-input v-model="bankfrom.Accountname"  :placeholder="$t('msg54')"  ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item :label="$t('msg53')" prop="Account" class="form-item">
                                      <el-input v-model="bankfrom.Account"  :placeholder="$t('msg53')" ></el-input>
                                    </el-form-item>
                                </el-col>
                          </el-form>
                        </el-col>
                        <el-col>
                            <el-col class="dialog-footer" :span="12">
                                <el-button style="width: 80%;" type="primary" :loading="loading" @click="banked" v-if="user.GroupPermission.n2.Modify">{{$t('msg21')}}</el-button>
                            </el-col>
                            <el-col class="dialog-footer" :span="12">
                                <el-button style="width: 80%;" type="primary" :loading="loading" @click="bankDel" v-if="user.GroupPermission.n2.Modify">{{$t('msg125')}}</el-button>
                            </el-col>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-dialog>

        <el-dialog :visible.sync="dialog.RealChk" :destroy-on-close="true" :close-on-click-modal="false" width="600px">
            <el-row>
                <el-col >
                    <el-row>
                        <el-col>
                          <el-form :model="RealChk" ref="ruleForm" :rules="rules" status-icon>
                                <el-col :span="24">
                                    <el-form-item :label="$t('msg120')" prop="Username" class="form-item">
                                      <el-input v-model="RealChk.RealName" :disabled="true" ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item :label="$t('msg121')" prop="Username" class="form-item">
                                      <el-input v-model="RealChk.RealID" :disabled="true" ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" >
                                    <el-row v-if="RealChk.Pic[0]">
                                        <el-col :span="12" v-for="(item, index) in RealChk.Pic" :key="index" style="text-align: center;margin-top: 10px;">
                                            <a :href="config.ImgUrl+'userpic/'+item" target="_blank" >
                                                <img                                      
                                                  :src="config.ImgUrl+'userpic/'+item" style="width: 98%"
                                                  >
                                            </a>
                                            <div v-if="user.GroupPermission.n2.Modify">
                                                <el-button slot="trigger" size="small" type="primary" @click="delPic(RealChk,index)">{{$t('msg104')}}</el-button>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </el-col>
                                <el-col :span="24" style="margin-top: 15px;" v-if="user.GroupPermission.n2.Modify">
                                    <el-form-item :label="$t('msg36')" prop="Title">
                                        <el-upload
                                        class="upload-demo"
                                        ref="uploadAdd"
                                        :action="'/api/comm/UploadAdm?type=realinfo&ID='+RealChk.ID"
                                        :on-success="handleSuccessAdd"
                                        :file-list="fileList"
                                        list-type="picture"
                                        :auto-upload="false"
                                        >
                                        <el-button slot="trigger" size="small" type="primary">Select Picture</el-button>
                                        <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload('uploadAdd')">upload</el-button>
                                        <!-- <div slot="tip" class="el-upload__tip" style="font-size:14px">Best size : 120 * 120</div> -->
                                        <div slot="tip" class="el-upload__tip" style="font-size:14px">limit 10M</div>
                                        </el-upload>
                                    </el-form-item>
                                </el-col>
                               <el-col :span="24">
                                    <el-form-item :label="$t('msg162')+$t('msg23')" prop="Title" class="form-item">
                                      <el-input v-model="RealChk.Title"  ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item :label="$t('msg162')+$t('msg24')" prop="Content" class="form-item">
                                      <el-input v-model="RealChk.Content" type="textarea" :rows="3" ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item :label="$t('msg124')" prop="Verified" class="form-item">
                                        <el-radio v-model="RealChk.Verified" :label="1">{{$t('msg122')}}</el-radio>
                                        <el-radio v-model="RealChk.Verified" :label="0">{{$t('msg123')}}</el-radio>
                                        <el-radio v-model="RealChk.Verified" :label="2">{{$t('msg162')}}</el-radio>
                                    </el-form-item>
                                </el-col>                                
                          </el-form>
                        </el-col>
                        <el-col>
                            <div class="dialog-footer">
                                <el-button style="width: 40%;" type="primary" :loading="loading" @click="VerifSet(RealChk)" v-if="user.GroupPermission.n2.Modify">{{$t('msg21')}}</el-button>
                                <el-button style="width: 40%;" type="primary" :loading="loading" @click="VerifRest(RealChk)" v-if="user.GroupPermission.n2.Modify">{{$t('msg148')}}</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6" >
import { apiUserList,apiUserSet,apiUserAdd,apiUserBank,apiUserBankSet,apiAgentLine,apiVerifySet,apiUserBankDel,apiVerifyRest,apiVerifyDpic } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
export default {
    mixins: [globalMixin],
    data() {
        return {
            ModShowLine:'',
            fileList:[],
            ParentID:0,
            AgentLine:[],
            act:"set",
            loading:false,
            dialog:{
                Visible:false,
                bank:false,
                RealChk:false,
            },
            from:{
                ID:0,
                Enable:0,
                Username:"",
                Coin:"",
                Phone:"",
                Line:"",
                Email:"",
                Memo:"",
                Password:"",
                CoinPassword:"",
                RealName:"",
                RealID:"",
                Verified:0,
                Title:"",
                Content:"",
                BackCoinDate:"",
                BackCoinPwd:"",
                BackLogDate:"",
                BackLogPwd:"",
                Class:0,
            },
            bankfrom:{
                BankID:0,
                Account:"",
                Username:"",
                Accountname:"",
                BankName:"",
            },
            RealChk:{
                ID:0,
                Username:'',
                RealName:"",
                RealID:"",
                Title:"",
                Content:"",
                Verified:0,
                Pic:{},
            },
            page:{
                page:0,
                pageSize:0,
                total:0,
                totalPage:0,
            },
            filterData:{
                Visible:false,
                Date:'',
            },
            rules: {
              Username: [
                { required: true, message: this.$t('msg31'), trigger: 'blur' },
              ],
              Enable: [
                { required: true, message: this.$t('msg32'), trigger: 'blur' },
              ],
              Password: [
                { required: true, message: this.$t('msg37'), trigger: 'blur' },
              ],
            },
            results:[],
            bankList:[],
        }
    },
    methods: {
        delPic(item,index){
            apiVerifyDpic({
                ID:item.ID,
                Username:item.Username,
                pic:item.Pic[index],
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.RealChk.Pic.splice(index,1);
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        handleSuccessAdd(response)
        {
            if(response.status)
            {
                this.RealChk.Pic = this.RealChk.Pic.concat(response.data); 
            }
        },
		submitUpload(index) 
        {
			this.$refs[index].submit();
		},
        ModLine(id)
        {
            apiAgentLine({
                ID:id,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.ModShowLine=response.data.data;
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        bankDel()
        {
            apiUserBankDel({
                Username: this.bankfrom.Username,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.dialog.bank=false
                        this.message(this.$t('msg55'))
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        VerifRest(item){
            apiVerifyRest({
                ID: item.ID,
                Username : item.Username,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.dialog.RealChk = false
                        this.getLog(this.page.page)
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        VerifSet(item)
        {
            // if(item.Verified=="2" && (item.Title=="" || item.Content=="")){
            //     this.message(this.$t('msg31')+" , "+this.$t('msg26'))
            //     return;
            // }
            apiVerifySet({
                ID: item.ID,
                Username : item.Username,
                Verified: item.Verified,
                Title: item.Title,
                Content: item.Content,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.dialog.RealChk = false
                        this.getLog(this.page.page)
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        realchk(item)
        {
            this.dialog.RealChk = true
            this.RealChk.Username = item.Username
            this.RealChk.ID = item.ID
            this.RealChk.RealName = item.RealName
            this.RealChk.RealID = item.RealID
            this.RealChk.Verified = item.Verified
            this.RealChk.Title = ""
            this.RealChk.Content = ""
            this.RealChk.Pic = item.Pic
        },
        bank(item)
        {
            this.dialog.bank=true
            apiUserBank({
                Username: item.Username,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        let data = response.data.data
                        this.bankfrom.BankID=data.data.BankID
                        this.bankfrom.Username=item.Username
                        this.bankfrom.Account=data.data.Account
                        this.bankfrom.Accountname=data.data.Accountname
                        this.bankfrom.BankName=data.data.BankName
                        this.bankList=data.bank
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        banked()
        {
            apiUserBankSet({
                Username: this.bankfrom.Username,
                BankID: this.bankfrom.BankID,
                Account: this.bankfrom.Account,
                Accountname: this.bankfrom.Accountname,
                BankName: this.bankfrom.BankName,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.dialog.bank=false
                        this.message(this.$t('msg55'))
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        search()
        {
            this.filterData.Visible=true
        },
        resetAgent()
        {
            this.ParentID = 0
            this.AgentLine.splice(0,this.AgentLine.length);            
        },
        resetParentID()
        {
            this.resetAgent()
            this.filterData.Username = ""
            this.filterData.LoginIP = ""
            this.filterData.Date = ""
            this.getLog(1);
        },
        searched()
        {
            this.filterData.Visible=false
            this.resetAgent()
            this.getLog(1);
        },
        setParentID(id)
        {
            // if(item.MemberCount == 0)
            // {
            //     return
            // }
            this.resetAgent()
            this.filterData.Username = ""
            this.filterData.LoginIP = ""
            this.filterData.Date = ""
            this.ParentID = id
            this.getLog(1);
            this.getAgentLine(id)
        },
        add()
        {
            this.act="add"
            this.from.ID=0
            this.from.Enable=1
            this.from.Username=""
            this.from.Coin=0
            this.from.Phone=""
            this.from.Line=""
            this.from.Email=""
            this.from.Memo=""
            this.from.Class=0
            this.dialog.Visible=true
        },
        update(item)
        {
            this.act="set"
            this.from.ID=item.ID
            this.from.Enable=item.Enable
            this.from.Username=item.Username
            this.from.Coin=item.Coin
            this.from.Phone=item.Phone
            this.from.Line=item.Line
            this.from.Email=item.Email
            this.from.Memo=item.Memo
            this.from.RealName = item.RealName
            this.from.RealID = item.RealID
            this.from.Verified = item.Verified   
            this.from.BackCoinDate = item.BackCoinDate   
            this.from.BackCoinPwd = item.BackCoinPwd   
            this.from.BackLogDate = item.BackLogDate   
            this.from.BackLogPwd = item.BackLogPwd   
            this.from.Class = item.Class   
            this.dialog.Visible=true
            this.ModLine(item.ID)
        },
        updated()
        {
            let chk = false
            this.$refs["ruleForm"].validate((valid) => {
              if (!valid) {
                chk = true;
              }
            },chk);
            if(chk)
            {
                this.message(this.$t('msg33'))
                return;
            }
            var api = apiUserSet
            let setPage = 1
            switch(this.act)
            {
                case "set":
                    api = apiUserSet
                    setPage = this.page.page
                break;
                case "add":
                    if(this.from.Password=="")
                    {
                        this.message(this.$t('msg50'))
                        return;
                    }
                    api = apiUserAdd
                break;
            }
            this.dialog.Visible=false
            api({
                ID: this.from.ID,
                Username: this.from.Username,
                Password: this.from.Password,
                CoinPassword: this.from.CoinPassword,
                Memo: this.from.Memo,
                Phone: this.from.Phone,
                Line: this.from.Line,
                Email: this.from.Email,
                Enable: this.from.Enable,
                RealName: this.from.RealName,
                Class: this.from.Class,
                RealID: this.from.RealID,
                Verified: this.from.Verified,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.getLog(setPage)
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        getAgentLine(id)
        {
            apiAgentLine({
                ID:id,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.AgentLine=response.data.data;
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        getLog(page)
        {
            let shdate = this.getSEDate(this.filterData.Date)
            apiUserList({
                page: page,
                ParentID : this.ParentID==0? "":this.ParentID,
                FromTime: shdate.FromTime,
                ToTime: shdate.ToTime,
                Username:this.filterData.Username,
                LoginIP:this.filterData.LoginIP,
                RegIP:this.filterData.RegIP,
                Phone:this.filterData.Phone,
                Email:this.filterData.Email,
                RealName:this.filterData.RealName,
                RealID:this.filterData.RealID,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.results=response.data.data.data;
                        this.page=response.data.data.page;
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
    },
    computed: {
		...mapGetters({
            config:'config',
            user:'user',
        }),
    },
    components: {
    },
    created() {
        this.getLog(1);
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

</style>