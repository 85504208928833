export default {
    msg1: 'hoạt động  ',
    msg2: 'bị khóa',
    msg3: 'hệ thống quản ký viên ',
    msg4: 'nhân viên chăm sóc khách hàng',
    msg5: 'nhân viên tài vụ',
    msg6: 'mục khác',
    msg7: 'thêm vào ',
    msg8: 'tài khoản',
    msg9: 'trạng thái',
    msg10: 'tổ nhóm',
    msg11: 'đăng nhập IP',
    msg12: 'ghi chú ',
    msg13: 'đăng nhập lần cuối ',
    msg14: 'chỉnh sữa lần cuối ',
    msg15: 'thao tác',
    msg16: 'chỉnh sữa ',
    msg17: 'tạm thời chưa có ghi nhận liên quan',
    msg18: 'mật khẩu mới',
    msg19: 'xin điền mật khẩu ',
    msg20: 'xin chọn mục trạng thái',
    msg21: ' xác nhận  chỉnh sữa',
    msg22: 'xác nhận thêm vào',
    msg23: 'tiêu đề',
    msg24: 'nội dung',
    msg25: 'thiết lập thời gian ',
    msg26: 'xin điền nội dung',
    msg27: 'người gửi',
    msg28: 'tìm kiếm',
    msg29: 'ngày bắt đầu',
    msg30: 'ngày kết thúc',
    msg31: 'xin điền tiêu đề',
    msg32: 'xin nhấn chọn  trạng thái ',
    msg33: 'xin điền đày đủ thông tin ',
    msg34: 'dánh sách gửi đi',
    msg35: 'khi cùng nhiều tài khoản dùng dấu phẩy cách ra    ví dụ :aaa,bbb,ccc',
    msg36: 'hình ảnh',
    msg37: 'hãy tải hình lên',
    msg38: ' Họ tên',
    msg39: 'trị số',
    msg40: ' chỉnh sữa thành công',
    msg41: 'tài khoản',
    msg42: 'số dư tài sản',
    msg43: 'đăng nhập IP',
    msg44: 'thời gian đăng nhập',
    msg45: 'đăng ký IP',
    msg46: 'di động',
    msg47: 'truy cập',
    msg48: 'hộp thư',
    msg49: 'mật khẩu khoản mới',
    msg50: 'đăng ký thành viên bắt buộc điền mật khẩu ',
    msg51: 'ngân hàng',
    msg52: 'hãy chọn ngân hàng',
    msg53: 'tài khoản ngân hàng',
    msg54: 'tên tài khoản ',
    msg55: 'chỉnh sữa thành công',
    msg56: 'số code',
    msg57: 'số tiền',
    msg58: 'phương thức nạp tiền',
    msg59: 'thông qua hình thức ',
    msg60: 'ghi chú',
    msg61: 'đang đặt lệnh',
    msg62: 'thành công về tài khoản',
    msg63: 'nạp tiền không thành công',
    msg64: 'xác nhận đã về tài khoản',
    msg65: 'hủy nạp tiền',
    msg66: 'hãy chọn ngày tháng',
    msg67: 'thông tin ngân hàng ',
    msg68: 'rút tiền thành công',
    msg69: 'rút tiền không thành công',
    msg70: 'Phần thưởng nhiệm vụ',
    msg71: 'Tên công việc',
    msg72: 'ký tự  Wine',
    msg73: 'số dư tài khoản',
    msg74: 'Thời gian hết hạn',
    msg78: 'trúng thưởng',
    msg79: 'ghi nhận trúng thưởng',
    msg80: ' thể  loại',
    msg81: 'đăng xuất thành công',
    msg82: 'tổng số xuât nhập khoản',
    msg83: 'tổng số vận hành',
    msg84: 'xin điền tài khoản vào ,khi cùng nhiều tài khoản dùng dấu phẩy cách ra    ví dụ:aaa,bbb,ccc',
    msg85: 'hãy nhập vào số tiền',
    msg86: 'thành công lên điểm',
    msg87: 'xác nhận',
    msg88: 'tổng số  rút',
    msg89: 'tổng số  nạp',
    msg90: 'tổng tiền đầu tư ',
    msg91: 'tên ngân hàng',
    msg92: 'tên tài khoản',
    msg93: 'tài khoản ',
    msg94: 'ghi chú',
    msg95: 'mã QR code',
    msg96: 'só tiền nap tối thiểu',
    msg97: 'số tiền nạp tối đa',
    msg98: 'đang bán ra',
    msg99: 'mật khẩu ',
    msg100: 'hãy ghi nhớ tài khoản ',
    msg101: 'đăng nhập ',
    msg102: 'quản lý menu',
    msg103: 'đăng xuất , thoát',
    msg104: 'xóa bỏ',
    msg105: 'Số lượng cấp dưới',
    msg106: 'Dòng đại lý',
    msg107: 'Mã tên',
    msg108: 'Tài khoản',
    msg109: 'Api Key',
    msg110: 'Chìa khóa bí mật',
    msg111: 'khu vực',
    msg112: 'Tỷ giá hối đoái',
    msg113: 'Địa chỉ API',
    msg114: 'Nhận xét',
    msg115: 'Số tiền tối thiểu',
    msg116: 'Số tiền tối đa',
    msg117: 'Chuyển đổi',
    msg118: 'Xem',
    msg119: 'Xác Nhận',
    msg120: 'Họ Và Tên',
    msg121: 'Số CMND/CCCD',
    msg122: 'Đã Xác Nhận',
    msg123: 'Chưa Xác Nhận',
    msg124: 'Xác Nhận Trạng Thái',
    msg125: 'Cài Đặt Lại Thẻ Ngân Hàng',
    msg126: 'Khoản Tiền Gửi Chưa Xử Lý',
    msg127: 'Khoản Tiền Rút Chưa Xử Lý',
    msg128: 'Thời gian hiển thị',
    msg129: 'Đích đến của tài khoản',
    msg130: 'Số người cấp một',
     msg131: 'Số phụ',
     msg132: 'Số ba cấp',
     msg133: 'Tài khoản proxy (cấp 1)',
     msg134: 'Tài khoản proxy (cấp 2)',
     msg135: 'Tài khoản proxy (cấp 3)',
     msg136: 'Hoạt động bị cấm',
     msg137: 'Mật khẩu đăng nhập hiện tại',
     msg138: 'Thời gian sửa đổi mật khẩu đăng nhập',
     msg139: 'Mật khẩu quỹ hiện tại',
     msg140: 'Thời gian sửa đổi mật khẩu tài trợ',
     msg141: 'Mô tả',
     msg142: 'object',
     msg143: 'Thời gian hoạt động',
     msg144: 'Số tiền không được nhỏ hơn 0',
     msg145: 'Tài khoản cấp cao',
     msg146: 'Loại nhật ký',
     msg147: 'Tải lên',
     msg148: 'Đặt lại',
     msg149: 'Mô tả',
     msg150: 'Vui lòng nhập mô tả',
     msg151: 'Hủy bỏ',
     msg152: 'Hôm nay',
     msg153: 'Tuần trước',
     msg154: 'Tháng trước',
    msg155: 'Ví chính',
    msg156: 'Ví giao dịch tự do',
    msg157: 'Ví giao dịch cố định',
    msg158: 'Loại ví',
    msg159: 'Vui lòng chọn một loại',
    msg160: 'Xác nhận nạp tiền?',
    msg161: 'Đăng ký liên tục',
    msg162: 'bị từ chối',
    msg163: 'Sao chép nhanh',
    msg164: 'Hàng loạt thành công',
    msg165: 'Hàng loạt không thành công',
    msg166: 'Cấp độ thành viên',
     
    RechargeModel:{
        n1: "công ty chuyển khoản",
        n2: "thanh toán bên thứ ba",
        n3: "quản lý viên nạp tiền",
    },
    
    GroupType:{
         n1: 'Quản trị viên hệ thống hàng đầu',
         n2: 'Quản lý Hệ thống',
         n3: 'Quản lý thanh toán',
         n4: 'quản lý rút tiền',
         n5: 'Dịch vụ khách hàng',
         n6: 'Quản trị viên',
         n7: 'Người quảng cáo',
         n8: 'khác',
    },
        
    MenuType:{
        n1: 'quản lý thông tin ',
        n2: 'quản lý hội viên',
        n3: 'quản lý nạp rút',
        n4: 'Quản lý đơn hàng',
        n5: 'quản lý tác vụ',
        n6: 'khoản tiền ghi nhận',
        n7: 'tra tìm báo biểu',
        n8: 'hệ thống quản lý',
        n9: 'Thành viên nhận được điểm',
    },
    
    SystemMenu:{
        n1: 'Danh sách quản trị viên',
         n2: 'danh sách thành viên',
         n3: 'Bản ghi nạp tiền',
         n4: 'Bản ghi rút tiền',
         n5: 'Danh sách đơn hàng',
         n6: 'danh sách công việc',
         n7: 'Danh sách thông báo',
         n8: 'Chi tiết giao dịch',
         n9: 'Thư ga',
         n10: 'Khuyến mãi',
         n11: 'Tham số hệ thống',
         n12: 'Biểu đồ thống kê',
         n13: 'Thành viên nhận được điểm',
         n14: 'Quản lý tin tức',
         n15: 'Quản lý video',
         n16: 'Quản lý thẻ ngân hàng',
         n17: 'Tổng số thành viên',
         n18: 'Quản lý dòng tiền ba bên',
         n19: 'Tổng đại lý',
         n20: 'Nhật ký quản trị viên',
         n21: 'Danh sách sản phẩm cố định',
    },

    Transfer:{
        0:"tất cả",
        1:"đặt hàng",
        2:"tiền lãi",
        3:"nạp tiền",
        4:"rút tiền",
        5:"hoàn lại tiền rút  không thành công",
        6:"khuyến mãi",
        7: "Cổ tức thưởng nhiệm vụ cấp đầu tiên",
        8:"trừ tiền",
        9:"trừ tiền",
        10: "Phản hồi nạp tiền cấp dưới",
        11: "Cổ tức thưởng cho nhiệm vụ phụ",
        12: "Cổ tức thưởng nhiệm vụ ba cấp",  
        13: "Lãi suất thương mại tự do",
        14: "Lãi suất tài sản cố định",
        15: "Mua bán thương mại tự do",
         16: "Mua Tài sản Cố định",
         17: "Mua bán tự do",
         18: "Bán tài sản cố định",
         19: "Điểm ảo",
         20: "Tiền thưởng đăng nhập",
         21: "Chuyển tiền",
         22: "Phần thưởng khi đăng nhập",
         23: "Trả lãi",
    },
    Param:{
        cashMax: 'số tiền rút tối đa',
        cashMin: 'số tiền rút tối đa',
        cashfee: ' phí thủ tục rút tiền(%)',
        rebate1: 'phần thưởng đại lý(%)',
        rebate2: 'phần thưởng đại lý giới thiệu đại lý(%)',
        rebate3: 'phần thưởng đại lý của đại lý giới thiệu đại lý(%)',
        service: ' link chăm sóc khách hàng ',
        AndroidUrl: 'Liên kết tải xuống ứng dụng Android',
         IOSUrl: 'Liên kết tải xuống ứng dụng iOS',
         InviteUrl: 'Địa chỉ mời',
         InviteRe: 'Phản hồi nạp tiền cấp dưới (%)', 
         ucoinRate: 'Tỷ lệ rút tiền ucoin',
         ucashMin: 'Số tiền tối thiểu rút U-coin',
         ucashMax: 'Số tiền tối đa rút U-coin',
         LoginBonus: 'Tiền thưởng đăng nhập',
         SignCount: 'Đăng nhập liên tục (ngày)',
         SignPay: 'Phần thưởng đăng nhập',
         SignPlus: 'Thu nhập miễn phí đăng nhập liên tục (lần)',
         Class1: 'Số lượng nhiệm vụ mới bắt đầu (ngày)',
         Class2: 'Số lượng nhiệm vụ nâng cao (ngày)',
         Class3: 'Số nhiệm vụ bạc (ngày)',
         Class4: 'Số lượng nhiệm vụ vàng (ngày)',
         APPName: 'Tên nền tảng',
    },
    Bank:{
        name: 'tên ngân hàng',
        accountName: 'tên tài khoản',
        account: 'tài khoản',
    },
    CashStatus:{
        0: 'chờ xét duyệt',
        1: 'thành công',
        2: 'đã hủy đơn',
    },
    BidStatus:{
        0: 'mua',
        1: 'Nó đã được bán',
        2: 'thất bại',
    },
    Prod:{
        "Name":"Tên công việc",
        "Price":"Phần thưởng nhiệm vụ",
        "Url":"URL tác vụ",
        "Note":"Lưu ý",
        "Enable":"Chuyển đổi",        
        "Class":"Mức công việc",
        "Setpic":"Cập nhật hình ảnh",
        "Device":"Phân loại tác vụ",
        "IOS":"Apple",
        "Android":"Android",
    },
    Class:{
         "no1": "Thành viên mới",
         "no2": "Nâng cao",
         "no3": "Bạc",
         "no4": "Vàng",
         "no0": "Không có cấp độ",
     }, 
    Chart:{
        "RegUser":"lượng người đăng ký",
        "RechargeUser":"lượng người nạp tiền",
        "CashUser": "Số lần rút tiền",
        "LoginUser":"lượng người đăng nhập",
        "BidUser":" lượng người đặt hàng",
        "BidAmount":"số tiền đặt hàng",
        "BonusAmount":"số tiền lãi",
        "RebateAmount":"số tiền hoàn trả",
        "RechargeAmount":"số tiền nạp",
        "CashAmount":"số tiền rút",
        "BrokerAmount":"số tiền khuyến mãi ",
        'SaleAmount': 'Lượng bán',
         "FreeBuy": "Mua Thương mại Tự do",
         "FixedBuy": "Mua Tài sản Cố định",
         "FreeSale": "Giảm giá Thương mại Tự do",
         "FixedSale": "Bán tài sản cố định",
         "FreeBuyUser": "Số lượng người mua thương mại tự do",
         "FixedBuyUser": "Số lượng người mua tài sản cố định",
         "FreeSaleUser": "Số lượng người bán thương mại tự do",
         "FixedSaleUser": "Số lượng tài sản cố định đã bán",
         "LoginBonus": "Tiền thưởng khi đăng nhập",
         "SignBonus": "Tiền thưởng khi đăng nhập",
    },
    LogType: {
        "admin_log_business1": "Nạp tiền-Xác nhận",
        "admin_log_business2": "Nạp tiền-Từ chối",
        "admin_log_business3": "Rút tiền-Xác nhận",
        "admin_log_business4": "Rút tiền - Bị từ chối",
        "admin_log_business5": "Quản trị - Nạp tiền",
        "admin_log_business6": "Quản trị viên - Điểm hoạt động",
        "admin_log_business7": "Admin-Debit",
        "admin_log_business8": "Quản trị viên-Nâng cấp ảo",
        "admin_log_user1": "Sửa đổi thành viên",
        "admin_log_user2": "Thành viên đã xác minh",
        "admin_log_user3": "Sửa đổi ngân hàng thành viên",
        "admin_log_user4": "Đặt lại Ngân hàng Thành viên",
        "admin_log_user5": "Đặt lại xác minh tên thật của thành viên",
        "admin_log_user6": "Sửa đổi xác minh tên thật của thành viên",
        "admin_log_user7": "Tải lên chứng chỉ thành viên",
        "admin_log_user8": "Xóa chứng chỉ thành viên",        
        "admin_log_adm1": "Quản trị - Thêm Quản trị viên",
        "admin_log_adm2": "Quản trị viên sửa đổi quản trị viên",
     },
    Teami:{
         "msg1": "Ngày",
         "msg2": "Số lượng người đăng ký",
         "msg3": "Số lần sạc đầu tiên",
         "msg4": "Số tiền gửi đầu tiên",
         "msg5": "Tổng số lần nạp tiền",
         "msg6": "Tổng Khoản giải ngân",
         "msg7": "Tổng số",
     }     
}