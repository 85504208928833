export default {
    msg1: '激活',
    msg2: '禁用',
    msg3: '系统管理员',
    msg4: '客服人员',
    msg5: '财务人员',
    msg6: '其他',
    msg7: '添加',
    msg8: '帐号',
    msg9: '状态',
    msg10: '群组',
    msg11: '登录IP',
    msg12: '备注',
    msg13: '最后登录',
    msg14: '最后修改',
    msg15: '操作',
    msg16: '修改',
    msg17: '暂无相关纪录',
    msg18: '新密码',
    msg19: '请输入密码',
    msg20: '请选择状态',
    msg21: '确认修改',
    msg22: '确认添加',
    msg23: '标题',
    msg24: '内容',
    msg25: '创建时间',
    msg26: '请输入内容',
    msg27: '寄件人',
    msg28: '搜寻',
    msg29: '开始日期',
    msg30: '结束日期',
    msg31: '请输入标题',
    msg32: '请选择状态',
    msg33: '请填写完整数据',
    msg34: '发送名单',
    msg35: '多帐号时逗号隔开 范例:aaa,bbb,ccc',
    msg36: '图片',
    msg37: '请上传图片',
    msg38: '名称',
    msg39: '数值',
    msg40: '修改成功',
    msg41: '帐号',
    msg42: '财产余额',
    msg43: '登录IP',
    msg44: '登录时间',
    msg45: '注册IP',
    msg46: 'Phone',
    msg47: 'Line',
    msg48: 'Email',
    msg49: '新提款密码',
    msg50: '创建会员必须输入密码',
    msg51: '银行',
    msg52: '请选择银行',
    msg53: '银行帐号',
    msg54: '帐户名',
    msg55: '修改成功',
    msg56: '编号',
    msg57: '金额',
    msg58: '充值方式',
    msg59: '信道',
    msg60: '备注',
    msg61: '申请中',
    msg62: '成功到账',
    msg63: '充值失败',
    msg64: '确认',
    msg65: '拒绝',
    msg66: '选择日期',
    msg67: '银行数据',
    msg68: '提款成功',
    msg69: '提款失败',
    msg70: '任务奖励',
    msg71: '任务名称',
    msg72: 'Winer标记',
    msg73: '用户余额',
    msg74: '到期时间',
    msg78: '中奖',
    msg79: '中奖标记',
    msg80: '类别',
    msg81: '登出成功',
    msg82: '出入款总计',
    msg83: '营运总计',
    msg84: '请输入帐号 多帐号时逗号隔开 范例:aaa,bbb,ccc',
    msg85: '请输入金额',
    msg86: '上分成功',
    msg87: '确认',
    msg88: '提款总计',
    msg89: '充值总计',
    msg90: '下单总计',
    msg91: '银行名称',
    msg92: '帐户名',
    msg93: '帐号',
    msg94: '备注',
    msg95: 'qrcode',
    msg96: '最小充值金额',
    msg97: '最大充值金额',
    msg98: '出售中',
    msg99: '密码',
    msg100: '记住帐号',
    msg101: '登录',
    msg102: '管理选单',
    msg103: '登出',
    msg104: '删除',
    msg105: '下级人数',
    msg106: '代理线',
    msg107: '名称代号',
    msg108: '帐号',
    msg109: 'Api Key',
    msg110: 'Secret Key',
    msg111: '地区',
    msg112: '汇率',
    msg113: 'API地址',
    msg114: '备注',
    msg115: '最小金额',
    msg116: '最大金额',
    msg117: '开关',
    msg118: '查看',
    msg119: '认证',
    msg120: '真实姓名',
    msg121: '身分证号',
    msg122: '已认证',
    msg123: '未认证',
    msg124: '认证状态',
    msg125: '重置银行卡',
    msg126: '笔充值未处理',
    msg127: '笔提款未处理',
    msg128: '显示时间',
    msg129: '帐目标地',
    msg130: '一级人数',
    msg131: '二级人数',
    msg132: '三级人数',
    msg133: '代理帐号(1级)',
    msg134: '代理帐号(2级)',
    msg135: '代理帐号(3级)',
    msg136: '禁止操作',
    msg137: '当前登录密码',
    msg138: '登录密码修改时间',
    msg139: '当前提款密码',
    msg140: '提款密码修改时间',
    msg141: '说明',
    msg142: '对象',
    msg143: '操作时间',
    msg144: '金额不能小于0',
    msg145: '上级帐号',
    msg146: '日志类型',
    msg147: '上传',
    msg148: '重置',
    msg149: '说明',
    msg150: '请输入说明',
    msg151: '取消',
    msg152: '今日',
    msg153: '上周',
    msg154: '上月',
    msg155: '主钱包',
    msg156: '自由交易钱包',
    msg157: '固定交易钱包',
    msg158: '钱包类型',
    msg159: '请选择类型',
    msg160: '确认充值?',
    msg161: '连续签到',
    msg162: '驳回',
    msg163: '快速复制',
    msg164: '批次成功',
    msg165: '批次失败',
    msg166: '会员等级',

    RechargeModel:{
        n1: "公司汇款",
        n2: "三方支付",
        n3: "管理员充值",
    },
        
    GroupType:{
        n1: '最高级系统管理员',
        n2: '系统管理',
        n3: '入款管理',
        n4: '出款管理',
        n5: '客服',
        n6: '管理员',
        n7: '推广员',
        n8: '其他',
    },
        
    MenuType:{
        n1: '信息管理',
        n2: '会员管理',
        n3: '充提管里',
        n4: '接单管理',
        n5: '任务管理',
        n6: '交易明细',
        n7: '报表查看',
        n8: '系统管理',
        n9: '会员上分',
    },
    
    SystemMenu:{
        n1: '管理员列表',
        n2: '会员列表',
        n3: '充值纪录',
        n4: '提款纪录',
        n5: '接单列表',
        n6: '任务列表',
        n7: '公告列表',
        n8: '交易明细',
        n9: '站内信',
        n10: '优惠活动',
        n11: '系统参数',
        n12: '统计图',
        n13: '会员上分',
        n14: '新闻管理',
        n15: '视频管理',
        n16: '银行卡管理',
        n17: '会员总计',
        n18: '三方金流管理',
        n19: '代理总计',
        n20: '管理员日志',
        n21: '固定商品列表',
    },

    Transfer:{
        0:"ALL",
        //1:"下单",
        //2:"利息",
        3:"充值",
        4:"提款",
        5:"提款失败反款",
        6:"活动",
        7:"一级任务奖励分红",
        8:"扣款",
        //9:"出售",
        10:"下级充值回馈",
        11:"二级任务奖励分红",
        12:"三级任务奖励分红",
        13:"自由交易利息",
        14:"固定资产利息",
        15:"自由交易购买",
        16:"固定资产购买",
        17:"自由交易出售",
        18:"固定资产出售",
        19:"虚拟上分",
        20:"登录奖励",
        21:"资金转移",
        22: "签到奖励",
        23: "利息发放",
    },
    Param:{
        cashMax: '提款最大金额',
        cashMin: '提款最小金额',
        cashfee: '提款手续费(%)',
        rebate1: '上级返水(%)',
        rebate2: '上上级返水(%)',
        rebate3: '上上上级返水(%)',
        service: '客服链接',
        AndroidUrl: 'Android APP 下载链接',
        IOSUrl: 'IOS APP 下载链接',
        InviteUrl: '邀请地址',
        InviteRe: '下级充值回馈(%)',
        ucoinRate: 'U币提款汇率',
        ucashMin: 'U币提款最小金额',
        ucashMax: 'U币提款最大金额',
        LoginBonus: '登录奖励',
        SignCount: '连续签到(天)',
        SignPay: '签到奖励',
        SignPlus: '连续签到自由收益(倍)',
        Class1: '新手任务数(天)',
        Class2: '进阶任务数(天)',
        Class3: '白银任务数(天)',
        Class4: '黄金任务数(天)',
        APPName: '平台名称',
    },
    Bank:{
        name: '银行名',
        accountName: '帐户名',
        account: '帐号',
    },
    CashStatus:{
        0: '申请中',
        1: '成功',
        2: '已取消',
    },
    BidStatus:{
        0: '未完成',
        1: '审核中',
        2: '成功',
        3: '失败',
    },
    Prod:{
        "Name":"任务名称",
        "Price":"任务奖励",
        "Url":"任务网址",
        "Note":"备注",
        "Enable":"开关",        
        "Class":"任务等级",
        "Setpic":"更新图片",
        "Device":"任务分类",
        "IOS":"苹果",
        "Android":"安卓",
    },
    Class:{
        "no1":"新手",
        "no2":"进阶",
        "no3":"白银",
        "no4":"黄金",
        "no0":"无等级",
    },
    Chart:{
        "RegUser":"注册人数",
        "RechargeUser":"充值人数",
        "CashUser":"提款人数",
        "LoginUser":"登录人数",
        "BidUser":"下单人数",
        "BidAmount":"下单金额",
        "BonusAmount":"任务奖励",
        "RebateAmount":"返点金额",
        "RechargeAmount":"充值金额",
        "CashAmount":"提款金额",
        "BrokerAmount":"活动金额",
        'SaleAmount': '出售金额',
        "InviteAmount":"下级充值回馈",
        "FreeBonus":"自由交易利息",
        "FixedBonus":"固定资产利息",
        "FreeBuy":"自由交易购买金额",
        "FixedBuy":"固定资产购买金额",
        "FreeSale":"自由交易出售金额",
        "FixedSale":"固定资产出售金额",
        "FreeBuyUser":"自由交易购买人数",
        "FixedBuyUser":"固定资产购买人数",
        "FreeSaleUser":"自由交易出售人数",
        "FixedSaleUser":"固定资产出售人数",
        "LoginBonus":"登录奖励",
        "SignBonus":"签到奖励",
    },
    LogType:{
		"admin_log_business1": "充值-确认",
		"admin_log_business2": "充值-拒绝",
		"admin_log_business3": "提款-确认",
		"admin_log_business4": "提款-拒绝",
		"admin_log_business5": "管理员-充值上分",
		"admin_log_business6": "管理员-活动上分",
		"admin_log_business7": "管理员-扣款",
		"admin_log_business8": "管理员-虚拟上分",
		"admin_log_user1":     "修改会员",
		"admin_log_user2":     "验证会员",
		"admin_log_user3":     "修改会员银行",
		"admin_log_user4":     "重置会员银行",
        "admin_log_user5":     "重置会员实名验证",
        "admin_log_user6":     "修改会员实名验证",
        "admin_log_user7":     "上传会员证件",
        "admin_log_user8":     "删除会员证件",
		"admin_log_adm1":      "管理员-添加管理员",
		"admin_log_adm2":      "管理员-修改管理员",        
    },
    Team:{
		"msg1": "日期",
        "msg2": "注册人数",
        "msg3": "首充人数",
        "msg4": "首充金额",
        "msg5": "总充值",
        "msg6": "总出款",
        "msg7": "总计",
    }
}