<template>
    <div>
        <el-row >
            <el-col v-if="user.GroupPermission.n1.Modify">
                <el-button type="primary" @click="search" >{{$t('msg28')}}</el-button>
            </el-col>
            <div class="mainSearch" >
                <table cellspacing="0" cellpadding="0" border="0" class="mainTable">
                    <tr>
                        <td class="td-head">{{$t('msg8')}}</td>
                        <td class="td-head">IP</td>
                        <td class="td-head">{{$t('msg141')}}</td>
                        <td class="td-head">{{$t('msg142')}}</td>
                        <td class="td-head">ROUTER</td>
                        <td class="td-head">CODE</td>
                        <td class="td-head">{{$t('msg143')}}</td>
                        
                    </tr>
                    <tr v-for="(item, index) in results" :key="index" :class="{'tr-color':index%2==0}">
                        <td class="td-content">{{ item.Username }}</td>
                        <td class="td-content">{{ item.ActionIP }}</td>
                        <td class="td-content">{{ $t('LogType.'+item.Act) }}</td>
                        <td class="td-content">{{ item.Ext1 }}</td>
                        <td class="td-content">{{ item.Router }}</td>
                        <td class="td-content">
                            <json-viewer :value="item.Actcode"></json-viewer>
                        </td>
                        <td class="td-content">{{ getMomentFullDate(item.CreatedAt) }}</td>
                    </tr>
                </table>
                <div class="mainPage">
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      :total="page.total"
                      :page-size="page.pageSize"
                      :current-page="page.page"
                      @current-change="getLog"
                      >
                    </el-pagination>
                </div>                
<!--                <div class="no-record" v-if="!results.length">
                    <img class="no-record-img" src="@/assets/images/norecord.png">
                    <div class="no-record-txt">{{$t('msg17')}}</div>
                </div> -->
            </div>
        </el-row>
        <el-dialog :visible.sync="filterData.Visible" :destroy-on-close="true" :close-on-click-modal="false" width="600px">
            <el-row>
                <el-col >
                  <el-form :model="filterData"  status-icon>
                    <el-form-item :label="$t('msg44')" prop="Date">
                        <el-date-picker
                            v-model="filterData.Date"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="~"
                            :start-placeholder="$t('msg29')"
                            :end-placeholder="$t('msg29')"
                            >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item :label="$t('msg41')" prop="Content">
                      <el-input v-model="filterData.Username" :placeholder="$t('msg41')" ></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('msg146')" prop="Content">
                        <el-select v-model="filterData.Act" :placeholder="$t('msg146')">
                            <el-option
                                v-for="item in TransLogAct"
                                :key="item.value"
                                :label="item.text"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col class="dialog-footer">
                    <el-button type="success" icon="el-icon-search" @click="searched">{{$t('msg28')}}</el-button>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6" >
import { apiAdminLogList } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
export default {
    mixins: [globalMixin],
    data() {
        return {
            filterData:{
                Visible:false,
                Date:'',
                Username:'',
                Act:'',
            },
            page:{
                page:0,
                pageSize:0,
                total:0,
                totalPage:0,
            },
            results:[],
        }
    },
    methods: {
        search()
        {
            this.filterData.Visible=true
        },
        searched()
        {
            this.filterData.Visible=false
            this.getLog(1);
        },
        getLog(page)
        {
            let shdate = this.getSEDate(this.filterData.Date)
            apiAdminLogList({
                page: page,
                FromTime: shdate.FromTime,
                ToTime: shdate.ToTime,
                Username:this.filterData.Username,
                Act:this.filterData.Act,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.results=response.data.data.data;
                        this.page=response.data.data.page;
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
    },
    computed: {
		...mapGetters({
            config:'config',
            user:'user',
        }),
        TransLogAct(){
            var data = [];
            data.push({
                value:'',
                text:'All'
            });
            for(var n in this.config.LogType){
                data.push({
                    value:n,
                    text:this.$t('LogType.'+n)
                });
            }
            // var data = [];
            // for(var n in this.config.cash_type)
            // {
            //     data.push({
            //         value:n,
            //         text:this.$t(this.config.cash_type[n])
            //     });
            // }
            //console.log(data)
            return data;            
        }
        
    },
    components: {
        
    },
    created() {
        this.getLog(1);
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

</style>