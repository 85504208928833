<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
  methods: {
  }
}
</script>

<style>
</style>

