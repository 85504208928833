export default {
     msg1: 'enable',
     msg2: 'disable',
     msg3: 'System Administrator',
     msg4: 'Customer service staff',
     msg5: 'Finance staff',
     msg6: 'Other',
     msg7: 'Added',
     msg8: 'Account',
     msg9: 'Status',
     msg10: 'group',
     msg11: 'Login IP',
     msg12: 'Remarks',
     msg13: 'Last login',
     msg14: 'Last Modified',
     msg15: 'Operation',
     msg16: 'Modify',
     msg17: 'No related records yet',
     msg18: 'New password',
     msg19: 'Please enter your password',
     msg20: 'Please select a state',
     msg21: 'Confirm modification',
     msg22: 'Confirm to add',
     msg23: 'Title',
     msg24: 'Content',
     msg25: 'Setup time',
     msg26: 'Please enter the content',
     msg27: 'Sender',
     msg28: 'Search',
     msg29: 'Start date',
     msg30: 'end date',
     msg31: 'Please enter a title',
     msg32: 'Please select a state',
     msg33: 'Please fill in the complete information',
     msg34: 'Send list',
     msg35: 'Separate multiple accounts with commas Example: aaa,bbb,ccc',
     msg36: 'image',
     msg37: 'Please upload a picture',
     msg38: 'name',
     msg39: 'value',
     msg40: 'Modification successful',
     msg41: 'Account',
     msg42: 'Property balance',
     msg43: 'Login IP',
     msg44: 'Login time',
     msg45: 'Register IP',
     msg46: 'Phone',
     msg47: 'Line',
     msg48: 'Email',
     msg49: 'New fund password',
     msg50: 'You must enter a password to create a member',
     msg51: 'Bank',
     msg52: 'Please select a bank',
     msg53: 'Bank Account Number',
     msg54: 'account name',
     msg55: 'Modification succeeded',
     msg56: 'Number',
     msg57: 'Amount',
     msg58: 'Deposit method',
     msg59: 'Channel',
     msg60: 'Remarks',
     msg61: 'Applying',
     msg62: 'Successfully received',
     msg63: 'Deposit failed',
     msg64: 'Confirm receipt',
     msg65: 'Cancel deposit',
     msg66: 'Select date',
     msg67: 'Bank Information',
     msg68: 'Withdrawal successful',
     msg69: 'Withdrawal failed',
    msg70: 'Task reward',
     msg71: 'Task name',
     msg72: 'Winer flag',
     msg73: 'User balance',
     msg74: 'Expiration time',
     msg78: 'Winning',
     msg79: 'Winning token',
     msg80: 'category',
     msg81: 'Logout successful',
     msg82: 'Total deposit and withdrawal',
     msg83: 'Operating Total',
     msg84: 'Please enter the account number. When multiple accounts are separated by commas Example: aaa,bbb,ccc',
     msg85: 'Please enter the amount',
     msg86: 'The score is successful',
     msg87: 'Confirm',
     msg88: 'Total withdrawal',
     msg89: 'Total deposit',
     msg90: 'Total order placed',
     msg91: 'Bank Name',
     msg92: 'account name',
     msg93: 'Account',
     msg94: 'Remarks',
     msg95: 'qrcode',
     msg96: 'Minimum recharge amount',
     msg97: 'Maximum recharge amount',
     msg98: 'for sale',
     msg99: 'password',
     msg100: 'Remember account number',
     msg101: 'Login',
     msg102: 'Management menu',
     msg103: 'Logout',
     msg104: 'Delete',
     msg105: 'Number of subordinates',
     msg106: 'Agent line',
     msg107: 'Name code',
     msg108: 'Account',
     msg109: 'Api Key',
     msg110: 'Secret Key',
     msg111: 'region',
     msg112: 'Exchange Rate',
     msg113: 'API address',
     msg114: 'Remarks',
     msg115: 'Minimum amount',
     msg116: 'Maximum amount',
     msg117: 'Switch',
     msg118: 'Check',
     msg119: 'Authentication',
     msg120: 'real name',
     msg121: 'ID number',
     msg122: 'Authenticated',
     msg123: 'Not authenticated',
     msg124: 'Authentication status',
     msg125: 'Reset bank card',
     msg126: 'The deposit has not been processed',
     msg127: 'Pen withdrawal not processed',
     msg128: 'Display time',
     msg129: 'Account destination',
     msg130: 'Number of first-level people',
     msg131: 'Secondary number',
     msg132: 'Three-level number',
     msg133: 'Proxy account (level 1)',
     msg134: 'Proxy account (level 2)',
     msg135: 'Proxy account (level 3)',
     msg136: 'Operation prohibited',
     msg137: 'Current login password',
     msg138: 'Login password modification time',
     msg139: 'Current fund password',
     msg140: 'Funding password modification time',
     msg141: 'Description',
     msg142: 'object',
     msg143: 'Operation time',
     msg144: 'The amount cannot be less than 0',
     msg145: 'Superior account',
     msg146: 'Log type',
     msg147: 'Upload',
     msg148: 'Reset',
     msg149: 'Description',
     msg150: 'Please enter a description',
     msg151: 'Cancel',
     msg152: 'Today',
     msg153: 'Last week',
     msg154: 'Last month',
    msg155: 'Main wallet',
    msg156: 'Free Trade Wallet',
    msg157: 'Fixed transaction wallet',
    msg158: 'Wallet type',
    msg159: 'Please select a type',
    msg160: 'Confirm recharge?',
    msg161: 'Continuous check-in',
    msg162: 'rejected',
    msg163: 'Quick Copy',
    msg164: 'Batch succeeded',
    msg165: 'Batch failed',
    msg166: 'Membership level',    
    
    RechargeModel:{
         n1: "Company remittance",
         n2: "Three-party payment",
         n3: "Admin deposit",
    },
        
    GroupType:{
         n1: 'Top system administrator',
         n2: 'System Management',
         n3: 'Payment management',
         n4: 'withdrawal management',
         n5: 'Customer Service',
         n6: 'Administrator',
         n7: 'Promoter',
         n8: 'other',
     },
        
     MenuType:{
         n1: 'Information management',
         n2: 'Member management',
         n3: 'In the charging tube',
         n4: 'Order management',
         n5: 'task management',
         n6: 'Fund records',
         n7: 'Report View',
         n8: 'System management',
         n9: 'Members get points',
     },
    
     SystemMenu:{
        n1: 'Administrator list',
         n2: 'member list',
         n3: 'Recharge record',
         n4: 'Withdrawal record',
         n5: 'Order list',
         n6: 'task list',
         n7: 'Announcement list',
         n8: 'Transaction details',
         n9: 'Station letter',
         n10: 'Promotions',
         n11: 'System parameters',
         n12: 'Statistical chart',
         n13: 'Members get points',
         n14: 'News Management',
         n15: 'Video management',
         n16: 'Bank card management',
         n17: 'Total members',
         n18: 'Three-party cash flow management',
         n19: 'Agent Total',
         n20: 'Administrator log',
         n21: 'Fixed product list',
     },

     Transfer:{
         0:"ALL",
         1: "Order",
         2: "Interest",
         3: "Deposit",
         4:"Withdrawal",
         5: "Failed to withdraw money",
         6:"Activity",
         7: "First-level task reward dividends",
         8: "Deduction",
         9: "Sale",
         10: "Subordinate recharge feedback",
         11: "Secondary task reward dividends",
         12: "Three-level task reward dividends",
         13:"Free Trade Interest",
         14: "Fixed Assets Interest",
         15:"Free trade purchase",
         16: "Fixed Asset Purchase",
         17:"Free trade for sale",
         18: "Fixed Assets Sale",
         19: "Virtual Score",
         20:"Login Bonus",
         21:"Fund transfer",
         22: "Sign-in Rewards",
         23: "Interest Payment",
     },
     Param:{
         cashMax: 'withdrawal maximum amount',
         cashMin: 'withdrawal minimum amount',
         cashfee: 'Withdrawal fee (%)',
         rebate1: 'Superior rebate (%)',
         rebate2: 'Superior rebate (%)',
         rebate3: 'Superior rebate (%)',
         service: 'Customer service link',
         AndroidUrl: 'Android APP download link',
         IOSUrl: 'IOS APP download link',
         InviteUrl: 'Invite address',
         InviteRe: 'Subordinate recharge feedback (%)',
         ucoinRate: 'Ucoin withdrawal rate',
         ucashMin: 'U-coin withdrawal minimum amount',
         ucashMax: 'U-coin withdrawal maximum amount',
         LoginBonus: 'Login Bonus',
         SignCount: 'Continuous sign-in (days)',
         SignPay: 'Sign-in bonus',
         SignPlus: 'Continuous sign-in free income (times)',
         Class1: 'Number of novice tasks (days)',
         Class2: 'Number of advanced tasks (days)',
         Class3: 'Number of silver tasks (days)',
         Class4: 'Number of golden tasks (days)',
         APPName: 'Platform name',
     },
     Bank:{
         name: 'Bank name',
         accountName: 'account name',
         account: 'account',
     },
     CashStatus:{
         0: 'Applying',
         1: 'success',
         2: 'Cancelled',
    },
   BidStatus:{
         0: 'Incomplete',
         1: 'Under review',
         2: 'success',
         3: 'failure',
     },
     Prod:{
         "Name":"Task Name",
         "Price":"Task reward",
         "Url":"Task URL",
         "Note":"Note",
         "Enable":"Switch",
         "Class":"Task level",
         "Setpic":"Update picture",
         "Device":"Task classification",
         "IOS":"Apple",
         "Android":"Android",
     },
    Class:{
         "no1":"Newbie",
         "no2":"Advanced",
         "no3":"Silver",
         "no4":"Gold",
         "no0":"No level",
     },     
     Chart:{
         "RegUser":"Number of Registrations",
         "RechargeUser":"Number of depositors",
         "CashUser":"Number of withdrawals",
         "LoginUser":"Number of logins",
         "BidUser":"Number of people placing orders",
         "BidAmount":"Order Amount",
         "RebateAmount":"Rebate Amount",
         "RechargeAmount":"Deposit Amount",
         "CashAmount":"Withdrawal Amount",
         "BrokerAmount":"Activity Amount",
         'SaleAmount': 'Sale Amount',
          "FreeBuy":"Free Trade Buy",
         "FixedBuy":"Fixed Asset Purchase",
         "FreeSale":"Free Trade Sale",
         "FixedSale":"Fixed Assets Sale",
         "FreeBuyUser":"Number of free trade buyers",
         "FixedBuyUser":"Number of Fixed Asset Buyers",
         "FreeSaleUser":"Number of free trade sellers",
         "FixedSaleUser":"Number of fixed assets sold",
         "LoginBonus":"Login Bonus",
         "SignBonus":"Sign-in Bonus",
    },
    LogType:{
        "admin_log_business1": "Recharge-Confirm",
        "admin_log_business2": "Recharge-Reject",
        "admin_log_business3": "Withdrawal-Confirm",
        "admin_log_business4": "Withdrawal - Rejected",
        "admin_log_business5": "Admin - Deposit Up",
        "admin_log_business6": "Administrator - Activity Score",
        "admin_log_business7": "Admin-Debit",
        "admin_log_business8": "Administrator-Virtual Upgrade",
        "admin_log_user1": "Modify Member",
        "admin_log_user2": "Verified Member",
        "admin_log_user3": "Modify member bank",
        "admin_log_user4": "Reset Member Bank",
        "admin_log_user5": "Reset member real-name verification",
        "admin_log_user6": "Modify member real-name verification",
        "admin_log_user7": "Upload membership certificate",
        "admin_log_user8": "Delete membership certificate",
        "admin_log_adm1": "Admin - Add Administrator",
        "admin_log_adm2": "Admin-modify administrator",
     },
    Team:{
        "msg1": "Date",
         "msg2": "Number of registrants",
         "msg3": "Number of First Chargers",
         "msg4": "First deposit amount",
         "msg5": "Total recharge",
         "msg6": "Total Disbursement",
         "msg7": "Total",
     }     
}