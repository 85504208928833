<template>
    <div>
        <el-row >
            <el-col>
                <el-button type="primary" @click="search" >{{$t('msg28')}}</el-button>
                <el-button type="success" icon="el-icon-search" @click="getTodaySet();getLog(1);" style="margin-left: 15px;">{{$t('msg152')}}</el-button>
                <el-button type="success" icon="el-icon-search" @click="getLWKSet();getLog(1);" style="margin-left: 15px;">{{$t('msg153')}}</el-button>
                <el-button type="success" icon="el-icon-search" @click="getLMonSet();getLog(1);" style="margin-left: 15px;">{{$t('msg154')}}</el-button>                
            </el-col>
            <div class="mainSearch" >
                <table cellspacing="0" cellpadding="0" border="0" class="mainTable">
                    <tr>
                        <td class="td-head ">{{$t('msg56')}}</td>
                        <td class="td-head ">{{$t('msg41')}}</td>
                        <td class="td-head ">{{$t('msg80')}}</td>
                        <td class="td-head ">{{$t('msg129')}}</td>
                        <!-- <td class="td-head " >{{$t('msg57')}}</td> -->
                        <td class="td-head " > NTD </td>
                        <td class="td-head " > USDT </td>
                        <td class="td-head " >{{$t('msg73')}}</td>
                        <td class="td-head " >{{$t('msg158')}}</td>
                        <td class="td-head td-date">{{$t('msg25')}}</td>
                    </tr>
                    <tr v-for="(item, index) in results" :key="index" :class="{'tr-color':index%2==0}">
                        <td class="td-content">{{ item.ID }}</td>
                        <td class="td-content">{{ item.Username }}</td>
                        <td class="td-content">{{ getTransferType(item) }}</td>
                        <td class="td-content">{{ item.SourceName }}</td>
                        <td class="td-content">{{ item.Coin | numFilter }}</td>
                        <td class="td-content">
                            <span v-if="item.Type==4">
                                 <span >
                                     1 USDT = {{OUTucoinRate}} NTD <br>
                                     {{ item.Coin / OUTucoinRate | numFilter }}
                                 </span>
                            </span>
                            <span v-if="item.Type==3">
                                 <span >
                                     1 USDT = {{INucoinRate}} NTD <br>
                                     {{ item.Coin / INucoinRate | numFilter }}
                                 </span>

                            </span>
                        </td>
                        <td class="td-content">{{ item.UserCoin | numFilter }}</td>
                        <td class="td-content">{{ $t(config.wallet_type[item.TypeCoin])  }}</td>
                        <td class="td-content">{{ getMomentFullDate(item.CreatedAt) }}</td>
                    </tr>
                </table>
                <div class="mainPage">
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      :total="page.total"
                      :page-size="page.pageSize"
                      :current-page="page.page"
                      @current-change="getLog"
                      >
                    </el-pagination>
                </div>
                <div class="no-record" v-if="!results">
                    <img class="no-record-img" src="@/assets/images/norecord.png">
                    <div class="no-record-txt">{{$t('msg17')}}</div>
                </div>
            </div>
        </el-row>
        <el-dialog :visible.sync="filterData.Visible" :destroy-on-close="true" :close-on-click-modal="false" width="600px">
            <el-row>
                <el-col >
                    <el-form :model="filterData"  status-icon>
                    <el-col :span="24">
                        <el-form-item :label="$t('msg25')" prop="Date" class="form-item">
                            <el-date-picker
                                v-model="filterData.Date"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="~"
                                :start-placeholder="$t('msg29')"
                                :end-placeholder="$t('msg29')"
                                >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('msg41')" class="form-item">
                          <el-input v-model="filterData.Username" :placeholder="$t('msg41')" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('msg133')" class="form-item">
                          <el-input v-model="filterData.Agentname1" :placeholder="$t('msg133')" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('msg134')" class="form-item">
                          <el-input v-model="filterData.Agentname2" :placeholder="$t('msg134')" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('msg135')" class="form-item">
                          <el-input v-model="filterData.Agentname3" :placeholder="$t('msg135')" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('msg32')" class="form-item">
                            <el-col>
                                <el-select v-model="filterData.TransferType" :placeholder="$t('msg20')">
                                    <el-option
                                        v-for="(item,key) in TransferType"
                                        :key="key"
                                        :label="item"
                                        :value="key">
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-form-item>
                    </el-col>
                  </el-form>
                </el-col>
                <el-col class="dialog-footer">
                    <el-button type="success" icon="el-icon-search" @click="searched">{{$t('msg28')}}</el-button>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6" >
import moment from 'moment'
import { apiTransferList } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { mapGetters } from "vuex";
export default {
    mixins: [globalMixin],
    data() {
        return {
            OUTucoinRate:0,
            INucoinRate:0,
            loading:false,
            page:{
                page:0,
                pageSize:0,
                total:0,
                totalPage:0,
            },
            filterData:{
                Visible:false,
                Date:'',
            },
            results:[],
        }
    },
    methods: {
        search()
        {
            this.filterData.Visible=true
        },
        searched()
        {
            this.filterData.Visible=false
            this.getLog(1);
        },
        getLog(page)
        {
            let shdate = this.getSEDate(this.filterData.Date)
            apiTransferList({
                Page: page,
                FromTime: shdate.FromTime,
                ToTime: shdate.ToTime,
                TransferType:this.filterData.TransferType,
                Username:this.filterData.Username,
                Agentname1:this.filterData.Agentname1,
                Agentname2:this.filterData.Agentname2,
                Agentname3:this.filterData.Agentname3,
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.error_handler(response.data);
                    }
                    if(response.data.status)
                    {
                        this.results=response.data.data.data;
                        this.page=response.data.data.page;
                        this.OUTucoinRate = response.data.data.OUTucoinRate;
                        this.INucoinRate = response.data.data.INucoinRate;
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        getTransferType(item)
        {
            return this.TransferType[item.Type];
        },
    },
    computed: {
		...mapGetters({
            config:'config',
            user:'user',
        }),
        'TransferType'()
        {
            // return[
            //     {
            //       value: '',
            //       label: 'ALL'
            //     },
            //     {
            //       value: 0,
            //       label: this.$t('msg61')
            //     }, {
            //       value: 1,
            //       label: this.$t('msg62')
            //     }, {
            //       value: 2,
            //       label: this.$t('msg63')
            //     }
            // ]
            return {
                0:this.$t("Transfer.0"),
                1:this.$t("Transfer.1"),
                //2:this.$t("Transfer.2"),
                //3:this.$t("Transfer.3"),
                //4:this.$t("Transfer.4"),
                //5:this.$t("Transfer.5"),
                6:this.$t("Transfer.6"),
                //7:this.$t("Transfer.7"),
                8:this.$t("Transfer.8"),
                //9:this.$t("Transfer.9"),
                //10:this.$t("Transfer.10"),
                //11:this.$t("Transfer.11"),
                //12:this.$t("Transfer.12"),
                //13:this.$t("Transfer.13"),
                //14:this.$t("Transfer.14"),
                //15:this.$t("Transfer.15"),
                //16:this.$t("Transfer.16"),
                //17:this.$t("Transfer.17"),
                //18:this.$t("Transfer.18"),
                //19:this.$t("Transfer.19"),
                20:this.$t("Transfer.20"),
                //21:this.$t("Transfer.21"),
                22:this.$t("Transfer.22"),
                //23:this.$t("Transfer.23"),
            }
        },

    },
    components: {
    },
    created() {
        let FromTime = moment(new Date()).add('day',-14).format("YYYY-MM-DD")
        let ToTime = moment(new Date()).add('day',0).format("YYYY-MM-DD")
        this.filterData.Date=[FromTime,ToTime]
        this.getLog(1);
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

</style>